import { Stack } from "@mui/material";
import React from "react";
import { useDistanceToBottom } from "../../hooks/useDistanceToBottom";
import MyDivider from "../General/MyDivider";
import Scrollable from "../General/Scrollable";
import CartFooter from "./CartFooter";
import CartHeader from "./CartHeader";

export const CartBody = ({
  entities,
  closeDialog,
  renderContent,
  isDrawer = true,
  calculateTaxNumberOfferForSupplier,
}) => {
  const scrollableHeight = useDistanceToBottom();
  return (
    <Stack direction="column" height="100%" padding={1} overflow="hidden">
      <CartHeader entities={entities} closeDialog={closeDialog} />
      <MyDivider />
      <Scrollable
        {...(!isDrawer && { maxHeight: scrollableHeight - 158 - 24 - 16 - 115 })}
        height={isDrawer ? "calc(100vh - 200px)" : "100%"}
      >
        {renderContent()}
      </Scrollable>
      {entities?.length > 0 && (
        <CartFooter
          entities={entities}
          calculateTaxNumberOfferForSupplier={
            calculateTaxNumberOfferForSupplier
          }
        />
      )}
    </Stack>
  );
};
