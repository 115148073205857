export const stringToLength = (str, length) => {
    const diff = length - str.length;
    if (diff>0) {
        const padding = " ".repeat(diff);
        return str+padding;
    }
    else {
        return str;
    }
}

export const truncateString = (str, len) => {
    if (!str || str.length<=len)
      return str;
    return str.slice(0, len).concat("...");
  }