import { Dialog } from "@mui/material";
import React from "react";
import ProductForm from "./ProductForm2";

function ProductFormDialog({
  hideMe,
  isEdit,
  product,
  supplier_id_for_by_admin_create,
  disabled = false,
}) {
  return (
    <Dialog open onClose={hideMe} fullWidth sx={{ padding: 1 }}>
      {/* <ProductForm hideMe={hideMe} isEdit={isEdit} product={product} /> */}
      <ProductForm
        hideMe={hideMe}
        isEdit={isEdit}
        product={product}
        supplier_id_for_by_admin_create={supplier_id_for_by_admin_create}
        disabled={disabled}
      />
    </Dialog>
  );
}

export default ProductFormDialog;
