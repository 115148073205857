import { setSnackMessage } from "../redux/slices/snackMessageSlice";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { add_new_product } from "../redux/slices/cartSlice";

export const useLocalQuantity = ({
    initQuantity = 0,
    globalQuantity,
    tuple,
    product,
}) => {
    const dispatch = useDispatch();

    const [localQuantity, setLocalQuantity] = useState(initQuantity);
    const [localCost, setLocalCost] = useState(0);
    const [costCase, setCostCase] = useState("normal");
    const [tooltipOpened, setTooltipOpened] = useState(false);

    useEffect(() => {
        setLocalQuantity(initQuantity);
    }, [initQuantity])

    useEffect(() => {
        if (!product) return;
        let cost_ = localQuantity * product?.price || 0;
        if (product.offer && !product.offer.is_expired) {
            console.log({ offer: product.offer });
            const { price: offer_price } = product.offer;
            cost_ = localQuantity * offer_price;
            setCostCase("offer");
        } else if (product.hyper_price) {
            if (localQuantity >= product.hyper_min_quantity) {
                console.log("into hyper quantity case...");
                cost_ = localQuantity * product.hyper_price;
                setCostCase("hyper");
            }
        } else {
            setCostCase("normal");
        }
        setLocalCost(cost_);
        setTooltipOpened(!!localQuantity);
    }, [localQuantity, product]);

    const update = (event) => {
        event.stopPropagation();
        if (localQuantity === 0) {
            dispatch(
                setSnackMessage({
                    text: "Εισάγετε μία έγκυρη ποσότητα",
                })
            );
            return;
        }
        if (tuple) {
            const payload = getPayloadExisting();
            console.log({ payload });
            if (payload.quantity < product.min_quantity) {
                dispatch(
                    setSnackMessage({
                        text: `Η ελάχιστη ποσότητα για το προϊόν είναι ${product.min_quantity} μονάδες`,
                    })
                );
                return;
            }
            dispatch(add_new_product(payload));
            dispatch(
                setSnackMessage({
                    text: "Το καλάθι ενημερώθηκε",
                })
            );
        } else {
            const payload = {
                product,
                quantity: localQuantity,
                cost: localCost,
                costCase,
            };
            console.log({ payload });
            if (payload.quantity < product.min_quantity) {
                dispatch(
                    setSnackMessage({
                        text: `Η ελάχιστη ποσότητα για το προϊόν είναι ${product.min_quantity} μονάδες`,
                    })
                );
                return;
            }
            dispatch(add_new_product(payload));
            dispatch(
                setSnackMessage({
                    text: "Το προϊόν προστέθηκε στο καλάθι",
                })
            );
        }
        setTooltipOpened(false);
        setLocalQuantity(0);
    };

    const add = (event) => {
        event.stopPropagation();
        console.log("adding locally");
        console.log({ product, localQuantity });
        const max = !localQuantity
            ? Math.max(product.min_quantity, product.package_quantity)
            : product.package_quantity;
        console.log({ localQuantity, min_quantity: product.min_quantity, package_quantity:product.package_quantity, max })
        const new_quantity = localQuantity + max;
        if (new_quantity > product.max_quantity) {
            dispatch(
                setSnackMessage({
                    text: `Η μέγιστη ποσότητα για το προϊόν είναι ${product.max_quantity}`,
                })
            );
            return;
        }
        if (!localQuantity) {
            setLocalQuantity(max);
        } else {
            setLocalQuantity(localQuantity + product.package_quantity);
        }
        // setTooltipOpened(true);
    };

    const subtract = (event) => {
        event.stopPropagation();
        console.log("subtracting locally");
        const new_quantity = localQuantity - product.package_quantity;
        if (new_quantity && new_quantity < product.min_quantity) {
            dispatch(
                setSnackMessage({
                    text: `Η ελάχιστη ποσότητα για το προϊόν είναι ${product.min_quantity} μονάδες`,
                })
            );
            return;
        }

        if (localQuantity >= product.package_quantity) {
            setLocalQuantity(localQuantity - product.package_quantity);
        }
    };

    const getPayloadExisting = () => {
        console.log("Getting payload for existing product");
        const new_quantity = localQuantity + globalQuantity;
        if (new_quantity > product.max_quantity) {
            dispatch(
                setSnackMessage({
                    text: `Η μέγιστη παραγγελία για το προϊόν είναι ${product.max_quantity} μονάδες`,
                })
            );
            return;
        }
        let cost_ = new_quantity * product?.price || 0;
        let cost_case = "normal";
        if (product.offer) {
            const offer = product.offer;
            const { price: offer_price } = offer;
            if (!offer.is_expired) {
                cost_ = new_quantity * offer_price;
                cost_case = "offer";
            }
        } else if (product.hyper_price) {
            if (new_quantity >= product.hyper_min_quantity) {
                cost_ = new_quantity * product.hyper_price;
                cost_case = "hyper";
            }
        }
        return {
            product,
            quantity: new_quantity,
            cost: cost_,
            costCase: cost_case,
        };
    };


    return {
        localQuantity,
        localCost,
        tooltipOpened,
        add,
        update,
        subtract,
    }

}
