import { Divider } from "@mui/material";
import React from "react";

function MyDivider({ marginTop, marginBottom }) {
  return (
    <Divider
      sx={{
        width: "100%",
        marginTop: marginTop || 1,
        marginBottom: marginBottom === undefined ? 2 : 0
      }}
    />
  );
}

export default MyDivider;
