import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { zIndexes } from "../../zIndexes";

export const GeneralDialog = ({
  title,
  content,
  actions,
  fullWidth,
  maxWidth = "lg",
  onClose,
  showDivider = true,
  showCloseButton = true,
}) => {
  return (
    <Dialog
      open
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onClose}
      sx={{ zIndex: zIndexes.generalDialog }}
    >
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          {showCloseButton &&
            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <IconButton onClick={onClose}>
                  <Close sx={{ fontSize: "30px" }} />
                </IconButton>
              </Grid>
            </Grid>
          }
        </Grid>
      </DialogTitle>
      {showDivider && <Divider />}
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
