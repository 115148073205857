import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api/auth/authService";

const initialState = {
  fav_suppliers: [],
  fav_products: [],
  status: "idle",
};

export const getFavourites = createAsyncThunk("users/favs", async () => {
  const { user } = await authService.logged();
  if (user) {
    const { favs: fav_suppliers, fav_products } = user;
    return { fav_suppliers, fav_products };
  }
  return { fav_suppliers: [], fav_products: [] };
});

const favouritesSlice = createSlice({
  name: "favourites",
  initialState,
  reducers: {
    set_fav_suppliers: (state, action) => {
      const { ids } = action.payload;
      state.fav_suppliers = ids;
    },
    set_fav_products: (state, action) => {
      const { ids } = action.payload;
      state.fav_products = ids;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFavourites.pending, (state) => {
        state.status = "loading";
    })
    builder.addCase(getFavourites.fulfilled, (state, action) => {
      const { fav_suppliers, fav_products } = action.payload;
      state.fav_suppliers = fav_suppliers || [];
      state.fav_products = fav_products || [];
      state.status = "idle";
    });
  },
});

export const { set_fav_products, set_fav_suppliers } = favouritesSlice.actions;

export const selectFavourites = (state) => state.favourites;
export default favouritesSlice.reducer;
