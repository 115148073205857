import { Search } from '@mui/icons-material'
import { Grid, IconButton, InputBase } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import React from 'react'


export const SimpleSearchBar = ({ search, initialValue }) => {
    return (
        <Formik
            initialValues={{ key: initialValue }}
            enableReinitialize
            onSubmit={search}
        >
            {({ errors, touched, values }) => (
                <Form>
                    <Grid container maxWidth={400}>
                        <Grid item sm={11} xs={10}>
                            <Field
                                fullWidth
                                as={InputBase}
                                placeholder="Search order..."
                                name="key"
                                type="text"
                                // variant="outlined"
                                style={{ height: "100%", paddingLeft: 3 }}
                            />
                        </Grid>
                        <Grid item sm={1} xs={2}>
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                <Search />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}