import React, { useState } from "react";
import { AppBar, Slide, useScrollTrigger } from "@mui/material";
import SearchBar from "./SearchBar";
import { zIndexes } from "../../../zIndexes";
import { useEffect } from "react";

export const SecondarySearchBar = () => {
  const trigger = useScrollTrigger();

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    if (!!trigger && !hasScrolled) {
      setHasScrolled(true);
    }
  }, [trigger])

  console.log({ hasScrolled, trigger })
  return (
    <Slide appear={false} direction="down" in={hasScrolled && !trigger}>
      <AppBar
        sx={{ zIndex: zIndexes.secondarySearchBar, top: "60px", padding: "8px 0px" }}
        color={"default"}

        elevation={1}
      >
        <SearchBar />
      </AppBar>
    </Slide>
  );
};
