import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Badge,
  IconButton,
  Grid,
  useTheme,
  Fab,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "./SearchBar";
import { Button } from "@mui/material";
import UserMenu from "./UserMenu";
import { selectAuth } from "../../../redux/slices/authSlice";
import { selectCart, show } from "../../../redux/slices/cartSlice";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { GeneralDialog } from "../../General/GeneralDialog";
import LoginForm from "../../LoginRegister/Login";
import WidthLimiter from "../../../pages/WidthLimiter";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { zIndexes } from "../../../zIndexes";
import { makeStyles } from "@mui/styles";
import logoWithText from "../../../images/MarketOn_Logo_final.png";

const useStyles = makeStyles((theme) => ({
  link: {
    textTransform: "none !important",
    color: "inherit !important",
  },
}));

export default function TopNavbar({ showCart, secondary = false }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isSupplier, logged, user } = useSelector(selectAuth);
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const { tuples } = useSelector(selectCart);
  const badgeContent = Object.keys(tuples || {}).length;
  const [showingLoginForm, setShowingLoginForm] = useState(false);
  const theme = useTheme();

  const renderHomeLink = () => {
    return (
      <IconButton
        component={NavLink}
        to="/"
        style={{
          textDecoration: "none",
          minWidth: secondary ? "180px" : "130px",
          margin: "auto",
          justifyContent: "flex-start",
          borderRadius: 0,
        }}
      >
        <img src={logoWithText} style={{ width: "150px" }} />
      </IconButton>
    );
  };

  const Link = ({ children }) => {
    return (
      <Box
        sx={{ display: { xs: "flex", md: "flex", color: "black", padding: 4 } }}
      >
        {children}
      </Box>
    );
  };

  const CartLink = () => {
    if (isMobile) {
      return (
        <Link>
          <Badge
            badgeContent={badgeContent}
            color="error"
            sx={{
              "& .MuiBadge-badge": {
                right: "4px",
                top: "7px",
              },
            }}
          >
            <IconButton
              size="large"
              sx={{ paddingRight: 1, marginTop: -0.5 }}
              onClick={() => dispatch(show())}
            >
              <ShoppingCartIcon
                fontSize="large"
                htmlColor={"black" /*secondary ? "black" : "white"*/}
              />
            </IconButton>
          </Badge>
        </Link>
      );
    }
    if (showCart) {
      return (
        <Fab
          sx={{
            position: "fixed",
            right: 0,
            top: "90vh",
            zIndex: 100,
            textTransform: "none",
          }}
          size="large"
          onClick={() => dispatch(show())}
          color="primary"
          variant="extended"
        >
          <Badge badgeContent={badgeContent} color="error" sx={{ padding: 1 }}>
            <ShoppingCartIcon sx={{ marginRight: 1 }} />
            Καλάθι
          </Badge>
        </Fab>
      );
    }
  };

  const OrdersLink = () => (
    <Link>
      <Button
        /*endIcon={<LocalShipping />}*/ size="large"
        className={classes.link}
        sx={{ fontSize: "16px" }}
        onClick={() => navigate("/account?page=orders")}
        // variant={!secondary ? "contained" : ""}
      >
        Παραγγελίες
        {/* <LocalShipping /> */}
      </Button>
    </Link>
  );

  const FavsLink = () => (
    <Link>
      <Button
        size="large"
        className={classes.link}
        sx={{ fontSize: "16px" }}
        onClick={() => navigate("/favourites")}
        // variant={!secondary ? "contained" : ""}
      >
        Αγαπημένα
        {/* <Favorite /> */}
      </Button>
    </Link>
  );

  const ProductsLink = () => (
    <Link>
      <Button
        size="large"
        className={classes.link}
        sx={{ fontSize: "16px" }}
        // variant={!secondary ? "contained" : ""}
        onClick={() => navigate("/admin?page=products")}
      >
        Προϊόντα
        {/* <Favorite /> */}
      </Button>
    </Link>
  );

  const AdminLink = () => (
    <Link>
      <Button
        /*endIcon={<LocalShipping />}*/ size="large"
        className={classes.link}
        sx={{ fontSize: "16px" }}
        // variant={!secondary ? "contained" : ""}
        onClick={() => (window.location.href = "/admin")}
      >
        Διαχείριση
        {/* <LocalShipping /> */}
      </Button>
    </Link>
  );

  const AuthLinks = () => {
    if (!logged && !secondary) {
      return (
        <>
          <Link>
            <Button
              size="large"
              onClick={() => setShowingLoginForm(true)}
              className={classes.link}
              // variant="contained"
            >
              Σύνδεση
            </Button>
          </Link>
          <Link>
            <Button
              size="large"
              onClick={() => navigate("/customers/register")}
              className={classes.link}
              // variant="contained"
            >
              Εγγραφή
            </Button>
          </Link>
          <Link>
            <Button
              size="large"
              onClick={() => navigate("/help?page=contact")}
              className={classes.link}
              // variant="contained"
            >
              Βοήθεια
            </Button>
          </Link>
        </>
      );
    }
  };

  const SupplierLinks = () => {
    if (isSupplier && !secondary) {
      return (
        <>
          {AdminLink()}
          {ProductsLink()}
        </>
      );
    }
  };

  const CustomerLinks = () => {
    if (logged && !isSupplier && !user?.is_superuser && !secondary) {
      return (
        <>
          {FavsLink()}
          {OrdersLink()}
        </>
      );
    }
  };

  const SuperuserLinks = () => {
    if (user?.is_superuser) {
      return (
        <Link>
          <Button
            size="large"
            className={classes.link}
            sx={{ fontSize: "16px" }}
            onClick={() => navigate("/super")}
            // variant={!secondary ? "contained" : ""}
          >
            Superuser
            {/* <Favorite /> */}
          </Button>
        </Link>
      );
    }
  };

  const renderLoginForm = () => {
    if (showingLoginForm && !secondary) {
      return (
        <GeneralDialog
          title="Καλώς ήρθατε στο MarketOn"
          onClose={() => setShowingLoginForm(false)}
          content={<LoginForm showMessages />}
        />
      );
    }
    return null;
  };

  const renderLinks = () => {
    if (isMobile) {
      return (
        <>
          <UserMenu
            htmlColor={"black" /*secondary ? "black" : "white"*/}
            showFavourites
            fontSize="large"
          />
          {CartLink()}
        </>
      );
    }
    return (
      <>
        {secondary && (
          <Grid container>
            <Grid item md={9} marginLeft={"-5px"}>
              <SearchBar />
            </Grid>
          </Grid>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {AuthLinks()}
        {CustomerLinks()}
        {SupplierLinks()}
        {SuperuserLinks()}
        {CartLink()}
        {logged && !secondary && (
          <Box
            sx={{
              display: {
                xs: "flex",
                md: "flex",
                /*backgroundColor: theme.palette.primary.main, borderRadius: 7,*/ marginTop: 2,
              },
            }}
          >
            <UserMenu htmlColor={"black" /*secondary ? "black" : "white"*/} />
          </Box>
        )}
      </>
    );
  };

  return (
    <AppBar
      sx={{
        zIndex: secondary ? zIndexes.secondaryNavbar : zIndexes.primaryNavbar,
        ...(!isMobile && secondary && { paddingRight: 0 }),
      }}
      position={!secondary ? "relative" : "sticky"}
      color="default"
      elevation={secondary ? 1 : 0}
    >
      <WidthLimiter>
        <Toolbar
          sx={{
            alignItems: "flex-start",
            padding: "8px 0px !important",
            ...(!isMobile && secondary && { paddingRight: "0px !important" }),
            height: "45px !important",
            minHeight: "20px !important",
          }}
        >
          {(isMobile || !secondary) && (
            <>
              {renderHomeLink()}
              {renderLinks()}
            </>
          )}
          {!isMobile && secondary && (
            <>
              <Grid container spacing={2}>
                <Grid item xl={2} lg={2.5} md={3} minWidth={250}>
                  {renderHomeLink()}
                </Grid>
                <Grid item xs>
                  <Grid container flexWrap={"nowrap"}>
                    {renderLinks()}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Toolbar>
        {renderLoginForm()}
      </WidthLimiter>
    </AppBar>
  );
}
