import axios from "axios";
import { getCookie } from "../helpers/cookies";

export const buildAuthHeader = () => {
    // const token = localStorage.getItem('token');
    const token = getCookie('market-session-jwt-token');
    const headers = {
        ...(!!token && { "Authorization": "Bearer "+ token, }),
    }
    return headers;
}

export const parseFormDataFromJson = (jsonData) => {
    const bodyFormData = new FormData();
    for (const [key, value] of Object.entries(jsonData)) {
        bodyFormData.append(key, value);
    }
    return bodyFormData;
}

export const is_multiple = (num1, num2) => {
    let n1 = null;
    let n2 = null;
    try {
        n1 = parseInt(num1);
        n2 = parseInt(num2);
    }
    catch (err) {
        return false;
    }
    return n2%n1===0;    
}

export const getRequest = async ({ requestUrl, headers, params }) => {
    const authHeader = buildAuthHeader();
    return axios.get(
        requestUrl,
        {
            headers: {
                ...authHeader,
                ...headers,
            },
            // ...(headers && { headers }),
            ...(params && { params }),
        }
    ).then(response => {
        console.log(response.data);
        return response.data;
    }).catch(err => {
        console.log(err);
        throw err;
    })
}