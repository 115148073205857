import React from 'react'
import { useDispatch } from 'react-redux';
import { formatCurrency, formatPrice } from '../../../helpers/price';
import { set_product } from '../../../redux/slices/oneProductSlice';
import CardOverlay from '../CardOverlay';

function Product({ product, isAdmin }) {
    const dispatch = useDispatch();
    if (!product) return null;
    const { image, name, id, price, price_currency } = product;

    const openPopUp = () => {
        dispatch(set_product({
            id,
            isAdmin,
        }))
    }

    const renderPrice = () => {
        return (
            formatPrice(price).concat(formatCurrency(price_currency))
        );
    }

    return (
        <CardOverlay
            image={image}
            title={name}
            subtitle={ renderPrice() }
            href={`#`}
            onClick={openPopUp}
        />
    )
}

export default Product;