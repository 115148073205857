import { List, ListItem, Stack } from "@mui/material";
import React from "react";
import SupplierHeader from "./SupplierHeader";
import CartProduct from "./CartProduct";

const SupplierGroup = ({
  group,
  setUnderDeletionProductId,
  calculateTaxNumberOfferForSupplier,
}) => {
  const renderProducts = (products) => {
    console.log({ products });
    return (
      <List
        aria-label="supplier-products"
        style={{ width: "100%", marginTop: "0px" }}
      >
        {products.map((product) => {
          return (
            <ListItem
              key={product.id}
              style={{
                paddingRight: 0,
                paddingLeft: "8px",
                paddingBottom: 2,
                paddingTop: 2,
              }}
            >
              <CartProduct
                product={product}
                showDeletionModal={setUnderDeletionProductId}
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const { supplier, products, cost_summary, cost_currency } = group[1];
  if (supplier) {
    return (
      <Stack component="nav" aria-label="suppliers">
        <SupplierHeader
          supplier={supplier}
          cost_before_offer={cost_summary}
          currency={cost_currency}
          calculateTaxNumberOfferForSupplier={
            calculateTaxNumberOfferForSupplier
          }
        />
        {renderProducts(products)}
      </Stack>
    );
  }
  return null;
};

export default SupplierGroup;
