
import * as Yup from 'yup';
import { max_msg, min_msg, store_name_max, store_name_min } from '../../../../helpers/generalValidation';

const required_msg = "Υποχρεωτικό πεδίο";
const digitsOnly = (value) => /^\d+$/.test(value);


const basicStoreValidationSchema = {
    name: Yup.string()
        .required(required_msg)
        .min(store_name_min, min_msg(store_name_min))
        .max(store_name_max, max_msg(store_name_max)),
    address: Yup.string()
        .required(required_msg),
    city: Yup.string()
        .required(required_msg),
    tax_no: Yup.string()
        .required(required_msg)
        .test('Digits only', 'Το πεδίο επιτρέπεται να έχει μόνο αριθμούς', digitsOnly)
        .length(9, "Πρέπει να αποτελείται από 9 φηψία"),
    state: Yup.string()
        .required(required_msg),
    post_code: Yup.string()
        .required(required_msg)
        .test('Digits only', 'Το πεδίο επιτρέπεται να έχει μόνο αριθμούς', digitsOnly)
        .length(5, "Πρέπει να αποτελείται από 5 φηψία"),
    tax_authority: Yup.string()
        .required(required_msg),
    phone_number: Yup.string()
        .required(required_msg)
        .test('Digits only', 'Το πεδίο επιτρέπεται να έχει μόνο αριθμούς', digitsOnly)
        .min(10, "Πρέπει να περιέχει τουλάχιστον 10 ψηφία"),
}

export const CustomerStoreValidationSchema = Yup.object().shape({
    ...basicStoreValidationSchema,
})

export const SupplierStoreValidationSchema = Yup.object().shape({
    ...basicStoreValidationSchema,
})