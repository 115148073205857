import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getSuppliersCall } from "../../api/suppliers"

const initialState = {
    all: [],
    all_status: "idle",
    suggestions: [],
    suggestions_status: "idle",
}

export const getSuppliers = createAsyncThunk(
    'suppliers/fetchSuppliers',
    async ({ type, categories }) => {
        try {
            const response = await getSuppliersCall(type, categories);
            return response.data;
        }
        catch (err) {
            return [];
        }
    }
)

export const suppliersSlice = createSlice({
    name: "suppliers",
    initialState,
    reducers: {
        set_suppliers_suggestions: (state, action) => {
            console.log(action.payload);
            const suggestions = action.payload;
            state.suggestions = suggestions;
            state.suggestions_status = "idle";
        },
        set_suppliers_suggestions_status: (state, action) => {
            const { status } = action.payload;
            state.suggestions_status = status;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSuppliers.pending, (state) => {
                state.all_status = "loading";
            })
            .addCase(getSuppliers.fulfilled, (state, action) => {
                state.all_status = "idle";
                state.all = action.payload;
            })
    }
});


export const { set_supplier, set_suppliers_suggestions, set_suppliers_suggestions_status } = suppliersSlice.actions;

export const selectSuppliers = (state) => state.suppliers;

export default suppliersSlice.reducer;