import React from "react";
import { useSearchParams } from "react-router-dom";

export const useSetSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const addSearchParams = (newSearchParams) => {
    const current = {};
    for (let [key, value] of searchParams.entries()) {
      current[key] = value;
    }
    console.log({ current });
    console.log({ newSearchParams });
    const newParams = { ...current, ...newSearchParams };
    const cleared = Object.fromEntries(
      Object.entries(newParams).filter(([_, v]) => v != null)
    );
    setSearchParams(cleared);
  };

  return { addSearchParams };
};
