export const zIndexes = {
    backToTop: 100,
    generalDialog: 1300,
    searchContainer: 2,
    buttonsContainer: 1,
    zero: 0,
    cartFAB: 2,
    categoriesFAB: 100,
    catalogFAB: 100,
    header: 120,
    secondarySearchBar: 9,
    secondaryNavbar: 10,
    primaryNavbar: 121,
    supplierAvatar: 0,
    productAvatar: 0,
    helpDrawer: 1,
}