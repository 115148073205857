import { AddOutlined, Close } from '@mui/icons-material'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Snackbar, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { deleteProductCall } from '../../../../api/products';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { useRefreshCatalog } from '../../../../hooks/useRefreshCatalog';
import { selectAuth } from '../../../../redux/slices/authSlice';
import MyDivider from '../../../General/MyDivider';
import { SlideTransition } from '../../../General/SlideTransition';
import { UnVerified } from '../UnVerified';
import OfferFormDialog from './OfferFormDialog';
import ProductFormDialog from './ProductFormDialog';
import ProductsArea from './ProductsArea'

function ProductsTabBody({ isPreviewMode=false, supplier_store, disabled = false }) {
    const { refreshCatalog } = useRefreshCatalog();
    const { isMobile } = useIsMobile();

    const [showingUpdateForm, setShowingUpdateForm] = useState(0);
    const [product, setProduct] = useState(null);

    const [showingCreateForm, setShowingCreateForm] = useState(false);
    const [showingDeletionModal, setShowingDeletionModal] = useState(false);
    const [showingOfferForm, setShowingOfferForm] = useState(false);

    const [message, setMessage] = useState(null);

    const showCreateForm = () => {
        setShowingCreateForm(true);
        setProduct(null);
    }
    const hideCreateForm = () => {
        setShowingCreateForm(false);
        setProduct(null);
    }

    const showUpdateProduct = (product, type) => {
        console.log({ product });
        setProduct(product);
        if (type==="delete") {
            setShowingDeletionModal(true);
        }
        else if (type==="offer") {
            showOfferForm();
        }
    }
    const hideUpdateForm = () => {
        setProduct(null);
        setShowingDeletionModal(false);
    }

    const showOfferForm = () => {
        setShowingOfferForm(true);
    }
    const hideOfferForm = () => {
        setProduct(null);
        setShowingOfferForm(false);
    }

    useEffect(() => {
        if (product) {
            if (showingDeletionModal || showingOfferForm) {
                ;
            }
            else {
                setShowingUpdateForm(true);
            }
        }
        else {
            setShowingUpdateForm(false);
        }
    }, [product, showingDeletionModal])

    const deleteProduct = () => {
        console.log("going to delete: ", product);
        const { id } = product;
        deleteProductCall(id)
        .then(response => {
            console.log(response.data);
            setTimeout(() => {
                hideUpdateForm();
                refreshCatalog();
            }, 200);
            setMessage({
                text: "Το προϊόν διαγράφηκε",
                severity: "info",
            });
        })
        .catch(err => {
            console.log(err);
            setMessage({
                text: "Συγγνώμη, δεν μπορέσαμε να διαγράψουμε το προϊόν σας",
                severity: "warning",
            });
        })
    }

    const closeMessage = () => {
        setMessage(null);
    }

    const renderMessage = () => {
        if (message) {
            const { text, severity } = message;
            return (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    autoHideDuration={3000}
                    onClose={closeMessage}
                    TransitionComponent={SlideTransition}
                >
                    <Alert onClose={closeMessage} severity={severity}>
                        {text}
                    </Alert>
                </Snackbar>
            )
        }
    }

    const renderModal = () => {
        return (
            <Dialog open fullWidth={isMobile}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h6">
                                Είσαι σίγουρος ότι θες να διαγράψεις το προϊόν;
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent="flex-end">
                                <IconButton onClick={hideUpdateForm}>
                                    <Close sx={{ fontSize: "30px"}} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    Αυτή η ενέργεια είναι μη αντιστρέψιμη
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={hideUpdateForm}>
                        Ακυρο
                    </Button>
                    <Button onClick={deleteProduct}>
                        Διαγραφη
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderProductForm = () => {
        if (showingCreateForm) {
            return (
                <ProductFormDialog hideMe={hideCreateForm} isEdit={false} supplier_id_for_by_admin_create={supplier_store?.id} />
            )
        }
        else if (showingUpdateForm) {
            return (
                <ProductFormDialog hideMe={hideUpdateForm} isEdit={true} product={product} disabled={disabled} />
            )
        }
        else if (showingDeletionModal) {
            return (
                renderModal()
            )
        }
        else if (showingOfferForm && product) {
            return (
                <OfferFormDialog hideMe={hideOfferForm} product={product} />
            )
        }
        return null;
    }

    if (isPreviewMode) {
        return (
            <Stack spacing={2}>
                <Typography variant="h6">
                    Τα προϊόντα μου
                </Typography>
                <MyDivider marginBottom={1} />
                <UnVerified />
            </Stack>
        )
    }

    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant="h6">
                            Τα προϊόντα μου
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent={'flex-end'}>
                            <Button variant="contained" onClick={showCreateForm} endIcon={<AddOutlined />} disabled={disabled}>
                                Προσθηκη νεου προϊοντος
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <MyDivider marginBottom={1} />
            <Grid item xs={12}>
                <ProductsArea setProduct={showUpdateProduct} supplier_store={supplier_store} />
            </Grid>
            { renderProductForm() }
            { renderMessage() }
        </Grid>
    )
}

export default ProductsTabBody;