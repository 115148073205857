import { Alert, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getHelpTextCall } from "../../api/helpPage";
import Spinner from "../General/Spinner";

export const HelpText = ({ term_key: key }) => {
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getHelpTextCall(key)
      .then((response) => {
        const { id, key, value } = response.data;
        setText(value);
        setIsLoading(false);
      })
      .catch((err) => {
        setText("");
        setIsLoading(false);
      });
  }, [key]);

  if (isLoading) {
    return <Spinner />;
  }
  if (text) {
    return (
      <div dangerouslySetInnerHTML={{
        __html: text,
      }}>
        {/* {text} */}
      </div>
    );
  }
  return (
    <Alert severity="info">
      Συγγνώμη, δεν μπορέσαμε να βρούμε το κείμενο που ψάχνετε.
    </Alert>
  );
};
