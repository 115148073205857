import { Button, FormHelperText, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography, DialogTitle, Checkbox, IconButton, Slider } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFieldErrors, renderSelectHelperText } from '../../../../helpers/getFieldErrors';
import MyTextFieldWithoutGrid from '../../../MyTextFieldWithoutGrid';
import { ProductVolumeWithHyperQuantity, ProductVolumeWithoutHyperQuantity, ProductWeightWithHyperQuantity, ProductWeightWithoutHyperQuantity } from './ProductValidationSchema';
import SendIcon from '@mui/icons-material/Send';
import { createProductCall, putProductCall } from '../../../../api/products';
// import { set_message } from '../../../../redux/slices/oneProductSlice';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { checkLogged, selectAuth } from '../../../../redux/slices/authSlice';
import { setSnackMessage } from '../../../../redux/slices/snackMessageSlice';
import { Cancel, Close } from '@mui/icons-material';
import { getCategoriesCall, getSubCategoriesCall } from '../../../../api/general';
import AvatarEditor from 'react-avatar-editor';
import { useDidMountEffect } from '../../../../hooks/useDidMountEffect';
import MyDivider from '../../../General/MyDivider';
import { currencies, vat_percentages } from '../../../../data/data';
import { is_multiple } from '../../../../api/helpers';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { blue } from '@mui/material/colors';
import { useRefreshCatalog } from '../../../../hooks/useRefreshCatalog';

function ProductForm({ isEdit, product, hideMe, supplier_id_for_by_admin_create, disabled = false }) {
    const { isMobile } = useIsMobile();
    const dispatch = useDispatch();
    const { refreshCatalog } = useRefreshCatalog();
    
    const [firstRender, setFirstRender] = useState(true);

    useDidMountEffect(() => {
        setFirstRender(false);
    })

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const [measuringIn, setMeasuringIn] = useState("kilos");
    const [withPackage, setWithPackage] = useState("false");
    const [hasHyperQuantity, setHasHyperQuantity] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageDirty, setImageDirty] = useState(false);
    const [currentImgPreview, setCurrentImgPreview] = useState(null);

    const editorRef = useRef(null);

    const initialValues = {
        name: "",
        price: "",
        price_currency: "euros",
        category: "",
        sub_category: "",
        image: "",
        weight: 0,
        weight_metric: "kg",
        volume: 0,
        volume_metric: "lt",
        package_quantity: 1,
        min_quantity: 1,
        max_quantity: "",
        vat_percentage: "",
        is_available: "true",
        description: "",
        hyper_price: "",
        hyper_min_quantity: "",
        clear_image: false,
        zoomLevel: 100,
    }

    useEffect(() => {
        if (product && product.hyper_min_quantity) {
            setHasHyperQuantity(true);
        }
        if (product && product.volume) {
            setMeasuringIn("litre");
        }
        else {
            setMeasuringIn("kilos");
        }
        if (product && product.package_quantity && product.package_quantity>1) {
            setWithPackage("true");
            updateSteps(product.package_quantity);
        }
    }, [product])

    useEffect(() => {
        if (withPackage==="false" && !firstRender) {
            console.log("updating steps because of no package")
            updateSteps(1);
        }
    }, [withPackage])

    useEffect(() => {
        getCategoriesCall()
        .then(response => {
            console.log(response.data);
            setCategories(response.data);
        })
        .catch(err => {
            console.log(err);
        })
        getSubCategoriesCall()
        .then(response => {
            console.log(response.data);
            setSubCategories(response.data);
        })
        .catch(err => {
            console.log(err);
        })
    }, [])

    const getValidationSchema = () => {
        if (measuringIn==="kilos") {
            if (hasHyperQuantity) return ProductWeightWithHyperQuantity;
            return ProductWeightWithoutHyperQuantity;
        }
        if (hasHyperQuantity) return ProductVolumeWithHyperQuantity;
        return ProductVolumeWithoutHyperQuantity;
    }

    const parseErrorMessage = (error) => {
        let text = "Συγγνώμη, δεν μπορέσαμε να αποθηκεύσουμε το προϊόν σας";
        if (error.response?.status===400) {
            const msg = error.response.data;
            console.log("400 baddd")
            if (msg==="A product cannot have a hyperquantity price and an offer at the same time") {
                text = "Δεν μπορείτε να προσθέσετε υπερχονδρική τιμή σε ένα προϊόν που έχει ενεργή προσφορά"
            }
            else if (msg==="This product has an active offer with greater price than the given one") {
                text = "Έχετε δηλώσει ενεργή προσφορά για το προϊόν, με τιμή μεγαλύτερη από αυτήν που προσπαθείτε να εισάγετε.\n Αν θέλετε να χαμηλώσετε τόσο την τιμή του προϊόντος, πρέπει πρώτα να διαγράψετε την προσφορά."
            }
            else if (msg==="A product with this name already exists for this supplier") {
                text = "Έχετε προσθέσει ήδη ένα προϊόν με αυτό το όνομα";
            }
        }
        return text;
    }

    const submitBody = (apiCall, id, data) => {
        console.log("submitting:", { data });
        apiCall(id, data)
        .then(response => {
            console.log(response.data);
            dispatch(setSnackMessage({
                text: "Το προϊόν σας αποθηκεύτηκε με επιτυχία",
                severity: "success",
            }));
            setLoading(false);
            refreshCatalog();
            hideMe();
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
            dispatch(setSnackMessage({
                text: parseErrorMessage(err),
                severity: "warning",
            }))
        })

    }

    const submit = async (values) => {
        console.log(imageDirty);
        console.log({ imageDirty });
        setLoading(true);
        console.log(values);
        const data = { ...values };

        // PARSE IS AVAILABLE AS BOOLEAN (NOW A "TRUE"/"FALSE" STRING)
        data["is_available"] = (values["is_available"]!=="false" && values["is_available"]!==false);
        
        // KEEP ONLY WEIGHT OR VOLUME DEPENDING ON THE measuringIn STATE VARIABLE
        if (measuringIn==="litre") {
        //     data["volume"] = 0;
            data["volume_metric"] = "lt";
        }
        else {
        //     data["weight"] = 0;
            data["weight_metric"] = "kg";
        }

        // IF SUB_CATEGORY==="" => DELETE IT FROM JSON
        if (!data["sub_category"]) {
            delete data["sub_category"];
        }
        
        // IF IMAGE IS MANUALLY CLEARED
        if (!data["image"] && imageDirty) {
            data["clear_image"] = true;
        }

        // IF IMAGE NOT GIVEN OR IS THE SAME
        if (!data["image"] || !imageDirty) {
            delete data["image"];
        }

        if (!hasHyperQuantity) {
            delete data["hyper_price"];
            delete data["hyper_min_quantity"];
            if (isEdit) {
                data["clear_hyper_price"] = true;
            }
        }

        data["max_quantity"] = data["max_quantity"] || 1000000;
        data["supplier_id_for_by_admin_create"] = supplier_id_for_by_admin_create;
        let id = 0;
        let apiCall = createProductCall
        if (isEdit) {
            id = product.id;
            apiCall = putProductCall;
        }

        console.log("------------------------------")
        console.log(data);
        console.log("------------------------------")
        if (data["image"] /*|| data["zoomLevel"]!==100*/) {
            const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
            fetch(canvas)
                .then(res => res.blob())
                .then(blob => {
                    if (data["image"] /*|| data["zoomLevel"]!==100*/) {
                        // setResultPreview(window.URL.createObjectURL(blob));
                        const name = data["image"]?.name;
                        const file = new File([blob], name /* || "image", { type: blob?.type } */);
                        data["image"] = file;
                    }
                    submitBody(apiCall, id, data);
                });
        }
        else {
            console.log({ data });
            submitBody(apiCall, id, data);
        }
    }

    const preProcess = (prod) => {
        if (prod) {
            const editted = { ...prod };
            if (prod["is_available"]) {
                editted["is_available"] = "true";
            }
            else {
                editted["is_available"] = "false";
            }
            console.log({ editted });
            const { weight_metric, volume_metric, ...rest_product } = editted;
            return rest_product;
        }
        console.log({ prod });
        return prod;
    }

    console.log({ initialValues: !isEdit ? initialValues : { ...initialValues, ...preProcess(product)}})

    const { category, sub_category } = product || {};
    const product2 = {
        ...product,
        category: category?.id,
        sub_category: sub_category?.id || 0,
        max_quantity: product?.max_quantity ? (product.max_quantity===1000000 ? null : product.max_quantity) : null
    };

    const updateWithPackage = (event, setFieldValue) => {
        console.log ({ val: event.target.value });
        const with_package = event.target.value;
        setWithPackage(with_package);
        if (with_package==="false") {
            setFieldValue("package_quantity", 1);
        }
    }

    const renderVatSelect = (errors, touched) => {
        return (
            <FormControl fullWidth variant="outlined" disabled={disabled}>
                <InputLabel>ΦΠΑ</InputLabel>
                <Field
                    as={Select}
                    name="vat_percentage"
                    label="ΦΠΑ"
                    id="vat_percentage"
                    {...getFieldErrors("vat_percentage", errors, touched)}
                >
                    {vat_percentages?.map(({id, name}) =>
                        <MenuItem key={id} value={id}>{name}</MenuItem>
                    )}
                </Field>
                { renderSelectHelperText("vat_percentage", errors, touched) }
            </FormControl>
        )
    }
    const renderPriceCurrency = (errors, touched, disabled=false) => {
        return (
            <FormControl fullWidth variant="outlined" disabled={disabled}>
                <InputLabel>Νόμισμα</InputLabel>
                <Field
                    as={Select}
                    name="price_currency"
                    label="Νόμισμα"
                    id="price_currency"
                    {...getFieldErrors("price_currency", errors, touched)}
                >
                    {currencies?.map(({id, name}) =>
                        <MenuItem key={id} value={id}>{name}</MenuItem>
                    )}
                </Field>
            </FormControl>
        )
    }
    const renderPriceFields = (errors, touched) => {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel id="price-group-label">Τιμολόγηση Χονδρικής</FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <MyTextFieldWithoutGrid
                            name="price"
                            label="Τιμή ανά μονάδα"
                            id="price"
                            type="number"
                            {...getFieldErrors("price", errors, touched)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        { renderPriceCurrency(errors, touched, disabled) }
                    </Grid>
                    <Grid item xs={4}>
                        { renderVatSelect(errors, touched) }
                    </Grid>
                    
                </Grid>
            </Grid>
        )
    }

    const renderHyperPriceFields = (errors, touched, setFieldValue, setFieldError) => {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel id="hyper-price-group-label">Τιμολόγηση υπερχονδρικής</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Με υπερχονδρική"
                            control={
                                <Checkbox
                                    checked={hasHyperQuantity}
                                    onChange={(event) => {
                                        const checked = event.target.checked;
                                        setHasHyperQuantity(checked);
                                        if (checked) {
                                            setFieldValue("hyper_price", "");
                                            setFieldValue("hyper_min_quantity", "");
                                        }
                                        else {
                                            setFieldValue("hyper_price", "");
                                            setFieldValue("hyper_min_quantity", "");
                                            setTimeout(() => {
                                                setFieldError("hyper_price", undefined);
                                                setFieldError("hyper_min_quantity", undefined);
                                            })
                                        }
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <MyTextFieldWithoutGrid
                            disabled={!hasHyperQuantity}
                            name="hyper_price"
                            label="Τιμή υπερχονδρικής ανά μονάδα"
                            id="hyper_price"
                            type="number"
                            {...getFieldErrors("hyper_price", errors, touched)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        { renderPriceCurrency(errors, touched, true) }
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <MyTextFieldWithoutGrid
                            disabled={!hasHyperQuantity}
                            name="hyper_min_quantity"
                            label={`Ελάχιστος αριθμός μονάδων υπερχονδρικής`}
                            id="hyper_min_quantity"
                            type="number"
                            // onKeyDown={(event) => {if (event.keyCode!==38 && event.keyCode!==40) event.preventDefault();}}
                            {...getFieldErrors("hyper_min_quantity", errors, touched)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderMetricRadio = (setFieldValue, setFieldError) => {
        return (
            <FormControl disabled={disabled}>
                <FormLabel id="metric-radio-buttons-group-label">Μέτρηση σε</FormLabel>
                <RadioGroup
                    aria-labelledby="metric-radio-buttons-group-label"
                    defaultValue="kg"
                    name="metric-buttons-group"
                    value={measuringIn}
                    onChange={event => {
                        const measure = event.target.value;
                        setMeasuringIn(measure);
                        if (measure==="kilos") {
                            setFieldValue("volume", 0);
                            setFieldValue("volume_metric", "lt");
                            console.log("clearing volume error");
                            setTimeout(() => {
                                setFieldError("volume", undefined);
                                setFieldError("volume_metric", undefined);
                            }, 0);
                        }
                        else {
                            setFieldValue("weight", 0);
                            setFieldValue("weight_metric", "kg");
                            console.log("clearing weight");
                            setTimeout(() => {
                                setFieldError("weight", undefined);
                                setFieldError("weight_metric", undefined);
                            }, 0);
                        }
                    }}
                    row
                    sx={{ paddingLeft: 2 }}
                    disabled={disabled}
                >
                    <FormControlLabel value="kilos" control={<Radio />} label="Βάρος" />
                    <FormControlLabel value="litre" control={<Radio />} label="Όγκο" />
                </RadioGroup>
            </FormControl>
        )
    }
    const renderMetric = (errors, touched) => {
        let valueField = (
            <MyTextFieldWithoutGrid
                name="weight"
                label="Βάρος"
                id="weight"
                type="number"
                inputProps={{
                    step: "0.5"
                }}
                endAdornment={"kg"}
                disabled={disabled}
                {...getFieldErrors("weight", errors, touched)}
            />
        );
        if (measuringIn!=="kilos") {
            valueField = (
                <MyTextFieldWithoutGrid
                    name="volume"
                    label="Όγκος"
                    id="volume"
                    type="number"
                    endAdornment={"L"}
                    disabled={disabled}
                    {...getFieldErrors("volume", errors, touched)}
                />
            );
        }
        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {valueField}
                </Grid>
            </Grid>
        )
    }
    const renderMetricFields = (errors, touched, values, setFieldValue, setFieldError) => {
        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item>
                        { renderMetricRadio(setFieldValue, setFieldError) }
                    </Grid>
                    <Grid item xs marginTop={1}>
                        { renderMetric(errors, touched, values) }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderCategoriesFields = (sub_categories, errors, touched, setFieldValue, setFieldTouched, values) => {
        return (
            <>
                <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Κατηγορία</InputLabel>
                        <Field
                            as={Select}
                            // name="category"
                            label="Κατηγορία"
                            id="category"
                            {...getFieldErrors("category", errors, touched)}
                            value={values.category}
                            onChange={(event) => {
                                setFieldValue("category", event.target.value);
                                setFieldTouched("category", true);
                                setFieldValue("sub_category", "");
                                setFieldTouched("sub_category", true);
                            }}
                            onBlur={(event) => {
                                setFieldTouched("category", true);
                            }}
                            disabled={disabled}
                        >
                            {categories?.map(({id, name}) =>
                                <MenuItem key={id} value={id}>{name}</MenuItem>
                            )}
                        </Field>
                        { renderSelectHelperText("category", errors, touched) }
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Υποκατηγορία</InputLabel>
                        <Field
                            as={Select}
                            name="sub_category"
                            label="Υποκατηγορία"
                            id="sub_category"
                            {...getFieldErrors("sub_category", errors, touched)}
                            disabled={disabled}
                        >
                            {sub_categories?.map(({id, name}) =>
                                <MenuItem key={id} value={id}>
                                        {id!==0 && name}
                                        {id==0 && "Χωρίς υποκατηγορία"}
                                </MenuItem>
                            )}
                        </Field>
                        { renderSelectHelperText("sub_category", errors, touched) }
                    </FormControl>
                </Grid>
            </>
        )
    }

    const renderAvailabilityRadio = (setFieldValue, values) => {
        return (
            <Grid item xs={12}>
                <FormControl>
                    <FormLabel id="metric-group-label">Απόθεμα</FormLabel>
                    <RadioGroup
                        aria-labelledby="availability-radio-buttons-group-label"
                        onChange={(event)=>setFieldValue("is_available", event.target.value)}
                        value={values.is_available}
                        row
                        sx={{ paddingLeft: 2 }}
                    >
                        <FormControlLabel value={"true"}  control={<Radio />} label="Σε απόθεμα" />
                        <FormControlLabel value={"false"} control={<Radio />} label="Μη διαθέσιμο" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        )
    }

    const renderPackageRadio = (setFieldValue) => {
        return (
            <FormControl disabled={disabled}>
                <FormLabel id="package-group-label">Πώληση</FormLabel>
                <RadioGroup
                    aria-labelledby="package-radio-buttons-group-label"
                    name="package-buttons-group"
                    value={withPackage}
                    onChange={event => updateWithPackage(event, setFieldValue)}
                    row
                    sx={{ paddingLeft: 2 }}
                    disabled={disabled}
                >
                    <FormControlLabel value={"false"} control={<Radio />} label="Ανά μονάδα" />
                    <FormControlLabel value={"true"}  control={<Radio />} label="Σε συσκευασία" />
                </RadioGroup>
            </FormControl>
        )
    }

    const updateSteps = (package_quantity_) => {
        const package_quantity = parseInt(package_quantity_);
        console.log("Updating steps to", typeof package_quantity, package_quantity);
        if (package_quantity>0) {
            const max_quantity_input = document.getElementById("max_quantity");
            if (max_quantity_input) {
                max_quantity_input.step = package_quantity;
            }
            const min_quantity_input = document.getElementById("min_quantity");
            if (min_quantity_input) {
                min_quantity_input.step = package_quantity;
            }
            const hyper_min_quantity_input = document.getElementById("hyper_min_quantity");
            if (hyper_min_quantity_input) {
                hyper_min_quantity_input.step = package_quantity;
            }
            console.log(hyper_min_quantity_input);
            console.log(min_quantity_input);
            console.log(max_quantity_input);
        }
    }

    const renderQuantityFields = (values, errors, touched, setFieldValue) => {
        return(
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        { renderPackageRadio(setFieldValue) }
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <MyTextFieldWithoutGrid
                            disabled={disabled || withPackage==="false"}
                            // name="package_quantity"
                            value={values.package_quantity}
                            label="Ποσότητα συσκευασίας"
                            id="package_quantity"
                            type="number"
                            onChange={(event) => {
                                setFieldValue("package_quantity", parseInt(event.target.value));
                                setFieldValue("min_quantity", parseInt(event.target.value));
                                setFieldValue("max_quantity", "");
                                setFieldValue("hyper_min_quantity", "");
                                updateSteps(event.target.value);
                            }}
                            // onKeyDown={(event) => {if (event.keyCode!==38 && event.keyCode!==40) event.preventDefault();}}
                            {...getFieldErrors("package_quantity", errors, touched)}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <MyTextFieldWithoutGrid
                            name="min_quantity"
                            disabled={disabled || withPackage==="true"}
                            label={`Ελάχιστος αριθμός μονάδων ανά παραγγελία`}
                            id="min_quantity"
                            type="number"
                            // onKeyDown={(event) => {if (event.keyCode!==38 && event.keyCode!==40) event.preventDefault();}}
                            {...getFieldErrors("min_quantity", errors, touched)}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <MyTextFieldWithoutGrid
                            name="max_quantity"
                            label={`Μέγιστος αριθμός μονάδων ανά παραγγελία`}
                            id="max_quantity"
                            type="number"
                            placeholder="-"
                            disabled={disabled}
                            // onKeyDown={(event) => {if (event.keyCode!==38 && event.keyCode!==40) event.preventDefault();}}
                            {...getFieldErrors("max_quantity", errors, touched)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderDescriptionField = (errors, touched) => {
        return (
            <Grid item xs={12}>
                <MyTextFieldWithoutGrid
                    name="description"
                    label="Περιγραφή"
                    id="description"
                    type="text"
                    fullLiner
                    multiline
                    minRows={3}
                    disabled={disabled}
                    {...getFieldErrors("description", errors, touched)}
                />
            </Grid>
        )
    }

    const updateImage = (event, setFieldValue, setFieldError) => {
        console.log("updating file")
        const files = event.currentTarget?.files;
        // if (!files?.length) {
        //     const input = document.getElementById("product-image-input");
        //     files = input?.files;
        // }
        setImageDirty(true);
        if (files?.length) {
            setFieldValue("image", files[0]);
            setCurrentImgPreview(URL.createObjectURL(files[0]));
        }
        else {
            setFieldValue("image", "");
            setCurrentImgPreview(null);
        }
    }

    const renderImageField = (errors, touched, setFieldValue, setFieldTouched, setFieldError, values) => {
        const { error, helperText } = getFieldErrors("image", errors, touched);
        return (
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justifyContent={'space-between'}>
                            <Grid item>
                                <Typography variant="subtitle1">
                                    Εικόνα προϊόντος
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadIcon />}
                                    size="small"
                                    disabled={disabled}
                                    >
                                    Ενημερωση
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/png, image/jpeg"
                                        id="product-image-input"
                                        hidden
                                        onChange={(event) => updateImage(event, setFieldValue, setFieldError)}
                                        onFocus={() => setFieldTouched("image", true)}
                                    disabled={disabled}
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <MyDivider marginBottom={1} />
                    {/* <Box width="100%"/> */}
                    {values["image"] &&
                        <Grid item xs={12}>
                            <Grid container justifyContent={'flex-end'}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    size="small"
                                    onClick={(event) => updateImage(event, setFieldValue, setFieldError)}
                                    disabled={disabled}
                                >
                                    Διαγραφη
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    { renderImage(values, setFieldValue, setFieldTouched)}
                    <Grid item xs={12}>
                        <FormHelperText error>
                            {helperText}
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderImage = (values, setFieldValue, setFieldTouched) => {
        if (values["image"]) {
            let src = values["image"];
            if (imageDirty) src = currentImgPreview;
            return (
                <Grid item xs={12} sx={{ marginLeft: isMobile ? -4 : 0 }}>
                    {/* <img
                        src={src}
                        style={{ width: "200px", height: "200px", borderRadius: "7px" }}
                        alt="product"
                    /> */}
                    <AvatarEditor
                        style={{ marginLeft: isMobile ? "-25px" : 0, border: "1px solid lightgrey", borderRadius: "7px", backgroundColor: blue[100]  }}
                        image={src}
                        ref={editorRef}
                        width={isMobile ? 300 : 400}
                        height={isMobile ? 225 : 300}
                        border={50}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={values.zoomLevel/100}
                        rotate={0}
                        crossOrigin="anonymous"
                    />
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography variant="body2">Εστίαση</Typography>
                        </Grid>
                        <Grid item xs sx={{ maxWidth: "300px" }}>
                            <Slider
                                size="medium"
                                value={values.zoomLevel}
                                onChange={(event) => { setFieldValue("zoomLevel", event.target.value); setFieldTouched("zoomLevel", true); }}
                                aria-label="Small"
                                valueLabelDisplay="on"
                                max={200}
                                min={10}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Typography variant="subtitle2">
                Δεν έχετε ανεβάσει ακόμα εικόνα για το προϊόν
            </Typography>
        )
    }

    const preSubmit = (setFieldTouched, errors, values) => {
        Object.keys(initialValues).forEach(val => {
            setFieldTouched(val, true);
        })
        console.log({ errors });
        if (Object.keys(errors).length) {
            dispatch(setSnackMessage({
                text: "Υπάρχουν σφάλματα στα κοκκινισμένα πεδία",
                severity: "warning",
            }));
        }
        else {
            const { max_quantity, min_quantity, package_quantity, hyper_min_quantity, weight, volume } = values;
            if (!weight) values.weight = 0;
            if (!volume) values.volume = 0;
            if (max_quantity && hyper_min_quantity) {
                if (hyper_min_quantity>max_quantity) {
                    dispatch(setSnackMessage({
                        text: "Η ελάχιστη ποσότητα υπερχονδρικής δεν πρέπει να ξεπερνάει την μέγιστη ποσότητα ανά παραγγελία",
                        severity: "warning",
                    }))
                    return;
                }
            }
            if (package_quantity && package_quantity>1) {
                if (min_quantity && !is_multiple(package_quantity, min_quantity)) {
                    console.log({ min_quantity, package_quantity });
                    dispatch(setSnackMessage({
                        text: "Η ελάχιστη ποσότητα πρέπει να είναι πολλαπλάσιο της ποσότητας πακέτου",
                        severity: "warning",
                    }))
                    return;
                }
                if (max_quantity && !is_multiple(package_quantity, max_quantity)) {
                    console.log({ max_quantity, package_quantity });
                    dispatch(setSnackMessage({
                        text: "Η μέγιστη ποσότητα πρέπει να είναι πολλαπλάσιο της ποσότητας πακέτου",
                        severity: "warning",
                    }))
                    return;
                }
                if (hyper_min_quantity && !is_multiple(package_quantity, hyper_min_quantity)) {
                    console.log({ hyper_min_quantity, package_quantity });
                    dispatch(setSnackMessage({
                        text: "Η ελάχιστη ποσότητα υπερχονδρικής πρέπει να είναι πολλαπλάσιο της ποσότητας πακέτου",
                        severity: "warning",
                    }))
                    return;
                }
            }
            submit(values);
        }
    }

    const renderSubmit = (setFieldTouched, errors, values) => {
        return (
            <LoadingButton
                type="submit"
                variant="outlined"
                loading={loading}
                onClick={()=>preSubmit(setFieldTouched, errors, values)} 
                color='inherit'
                sx={{ bgcolor: "white" }}
                endIcon={<SendIcon />}
            >
                Δημοσιευση
            </LoadingButton>
        )
    }

    const renderClose = () => {
        return (
            <Button
                onClick={hideMe}
                variant="outlined"
                color='error'
                sx={{ bgcolor: "white" }}
                endIcon={<Cancel />}
                >
                Ακυρωση
            </Button>
        )
    }

    const renderTitle = () => (
        <Grid container>
            <Grid item xs={10}>
                <Typography variant="subtitle1">
                    {isEdit ? "Επεξεργασία" : "Δημιουργιά" } Προϊόντος
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Grid container justifyContent="flex-end">
                    <IconButton onClick={hideMe}>
                        <Close sx={{ fontSize: "30px"}} />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )

    const renderName = (errors, touched) => {
        return (
            <Grid item xs={12}>
                <MyTextFieldWithoutGrid
                    name="name"
                    label="Όνομα"
                    id="name"
                    type="text"
                    {...getFieldErrors("name", errors, touched)}
                    fullLiner
                    disabled={disabled}
                />
            </Grid>
        )
    }

    const renderBasicsFields = (sub_categories, errors, touched, setFieldValue, setFieldTouched, values) => {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel id="price-group-label">Βασικά στοιχεία</FormLabel>
                    </Grid>
                    { renderName(errors, touched) }
                    { renderCategoriesFields(sub_categories, errors, touched, setFieldValue, setFieldTouched, values) }
                </Grid>
            </Grid>
        )
    }

    const renderForm = ({ sub_categories, values, errors, touched, setFieldTouched, setFieldValue, setFieldError }) => {
        return (
            <Form>
                <Grid container rowSpacing={3} paddingTop={1} paddingBottom={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            
                            { renderBasicsFields(sub_categories, errors, touched, setFieldValue, setFieldTouched, values) }
                            { renderPriceFields(errors, touched) }
                            { renderMetricFields(errors, touched, values, setFieldValue, setFieldError)}
                            { renderAvailabilityRadio(setFieldValue, values) }
                            { renderQuantityFields(values, errors, touched, setFieldValue) }
                            { renderHyperPriceFields(errors, touched, setFieldValue, setFieldError) }
                            { renderDescriptionField(errors, touched) }
                            { renderImageField(errors, touched, setFieldValue, setFieldTouched, setFieldError, values) }
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    return (
        <Formik
            initialValues={!isEdit ? initialValues : { ...initialValues, ...product2}}
            // validate={(value) => {
            //     try {
            //       validateYupSchema(value, getValidationSchema(), true, value);
            //     } catch (err) {
            //       return yupToFormErrors(err); //for rendering validation errors
            //     }
            //     return {};
            //   }}
            validationSchema={getValidationSchema()}
            onSubmit={submit}
        >
            {({ values, errors, touched, setFieldValue, setFieldTouched, setFieldError, ...props}) => {
 
                const sub_categories = subCategories.filter(sub_cat => (!sub_cat?.id || sub_cat?.category?.id===values.category));
                return (
                    <>
                        <DialogTitle sx={{ bgcolor: 'info.main', color: "white" }}>
                            { renderTitle() }
                        </DialogTitle>
                        <DialogContent>
                            { renderForm({ sub_categories, values, errors, touched, setFieldTouched, setFieldValue, setFieldError }) }
                        </DialogContent>
                        <DialogActions sx={{ bgcolor: 'info.main' }}>
                            <Grid container justifyContent={'space-between'}>
                                { renderClose() }
                                { renderSubmit(setFieldTouched, errors, values) }
                            </Grid>
                        </DialogActions>
                    </>
                )
            }}    
        
        </Formik>

    )
}

export default ProductForm;