import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { queriesKeys } from "../../api/queriesKeys";
import { getOrderAgainDataCall } from "../../api/customer";
import { Alert, Button, Grid, Stack } from "@mui/material";
import ProductRow from "../Profile/Supplier/Products/ProductRow";
import { useSelector } from "react-redux";
import { selectCart } from "../../redux/slices/cartSlice";
import Spinner from "../General/Spinner";
import { GeneralDialog } from "../General/GeneralDialog";

export const OrderAgainDialog = ({ cart_id, handleClose }) => {
  const { tuples } = useSelector(selectCart);
  const { data, isLoading, isError } = useQuery(
    [queriesKeys["getOrderAgainData"], cart_id],
    () => getOrderAgainDataCall(cart_id),
    {
      enabled: !!cart_id,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  console.log(data);

  return (
    <GeneralDialog
      open
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      title="Παραγγείλτε ξανά"
      content={
        <>
          {isLoading && <Spinner />}
          {isError && (
            <Alert severity="error">
              Παρουσιάστηκε κάποιο σφάλμα, η παραγγελία σας δεν βρέθηκε.
            </Alert>
          )}
          {!!data?.length && !isError && (
            <Stack spacing={2}>
              {data?.map(({ product, quantity }) => {
                if (!!product)
                  return (
                    <ProductRow
                      key={product.id}
                      product={product}
                      tuple={tuples?.[product.id]}
                      isAdmin={false}
                      initQuantity={quantity}
                    />
                  );
              })}
            </Stack>
          )}
        </>
      }
      actions={
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ textTransform: "none" }}
          >
            Κλείσιμο
          </Button>
        </Grid>
      }
    ></GeneralDialog>
  );
};
