import { useSelector } from "react-redux";
import { selectAuth } from "../redux/slices/authSlice";
import { useQuery } from "react-query";
import { queriesKeys } from "../api/queriesKeys";
import { getOneTaxNumberOffersCall } from "../api/customer";


export const useTaxNumberOfferForCustomer = () => {
  const { user, logged } = useSelector(selectAuth);

  const { data: taxNumberOffers, isLoading: areTaxNumberOffersLoading } =
    useQuery(
      [queriesKeys["getOneTaxNumberOffers"], user?.customer_store?.tax_no],
      () => getOneTaxNumberOffersCall(user?.customer_store?.tax_no),
      {
        enabled: !!logged && !!user?.customer_store?.tax_no,
      }
    );

  const calculateTaxNumberOfferForSupplier = (
    supplier_id,
    cost_before_offer
  ) => {
    let emptyOffer = {
      isLoading: false,
      cost_before_offer,
      cost_after_offer: cost_before_offer,
      offer_percentage: 0,
    };
    if (!taxNumberOffers) return emptyOffer;
    const offer = taxNumberOffers?.find(
      ({ supplier_id: id }) => id === supplier_id
    );
    if (offer) {
      return {
        isLoading: areTaxNumberOffersLoading,
        cost_before_offer,
        cost_after_offer: cost_before_offer * (1 - offer.offer_percentage || 0),
        offer_percentage: offer.offer_percentage || 0,
      };
    }
    return emptyOffer;
  };

  return {
    calculateTaxNumberOfferForSupplier,
    taxNumberOffersNormalizedBySupplierId: taxNumberOffers?.reduce(
      (acc, offer) => ({ ...acc, [offer.supplier_id]: offer }),
      {}
    ),
    areTaxNumberOffersLoading,
  };
};
