import { Add, Remove } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react'

function QuantityButtons({ onAdd, onSubtract, quantity, size="medium", justifyContent="flex-start" }) {
    return (
        <Grid maxWidth={100} container justifyContent={justifyContent} alignItems="center" sx={{ border: "1px solid grey", borderRadius: "4px" }} padding={0} margin={0}>
            <Grid item xs={4} padding={0}>
                <Grid container justifyContent={'center'} alignItems="center">
                    <IconButton size={size} onClick={onSubtract} sx={{ padding: "0px 2px", width: 1, borderRadius: 0 }}>
                        <Remove fontSize={size} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={4} sx={{ borderRight: "1px solid grey", borderLeft: "1px solid grey" }}>
                <Typography fontSize={size} variant="subtitle1" align='center'>
                    {quantity}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Grid container justifyContent={'center'} alignItems="center">
                    <IconButton size={size} onClick={onAdd} sx={{ padding: "0px 2px", width: 1, borderRadius: 0 }}>
                        <Add fontSize={size} />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default QuantityButtons;