import React from "react";
import { Stack, Typography } from "@mui/material";
import { useOneSupplierTaxNumberOffersData } from "../../../../hooks/useOneSupplierTaxNumberOffersData";
import { TaxNumberOffersForm } from "./TaxNumberOffersForm";
import Spinner from "../../../General/Spinner";

export const OneSupplierTaxNumberOffers = ({
  supplier_store,
  disabled = false,
}) => {
  const { id } = supplier_store;

  const {
    taxNumbers,
    taxNumberOffers,
    isLoading,
    refresh,
  } = useOneSupplierTaxNumberOffersData(id);

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Eκπτώσεις ΑΦΜ</Typography>
      {isLoading && <Spinner />}
      {!isLoading && (
        <TaxNumberOffersForm
          supplier_id={id}
          taxNumberOffers={taxNumberOffers}
          taxNumbers={taxNumbers}
          refresh={refresh}
          disabled={disabled}
        />
      )}
    </Stack>
  );
};
