import { ArrowBackIos } from "@mui/icons-material";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { SubCategoryRow } from "./SubCategoryRow";

export const CategoryRow = ({
  id,
  name,
  sub_categories,
  products_count,
  isSelected,
  selectedSubCategoryId,
  chooseSubCat,
  type,
}) => {
  if (type === "back_to_all") {
    return (
      <ListItem key={id} disablePadding>
        <Grid container>
          <Grid item xs={12}>
            <ListItemButton
              sx={{ width: 1 }}
              onClick={() => chooseSubCat(null, null)}
              selected={false}
            >
              <ListItemIcon sx={{ minWidth: "25px" }}>
                <ArrowBackIos fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary="Όλες οι κατηγορίες"
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItemButton>
          </Grid>
        </Grid>
      </ListItem>
    );
  }
  return (
    <ListItem key={id} disablePadding>
      <Grid container>
        <Grid item xs={12}>
          <ListItemButton
            sx={{ width: 1 }}
            onClick={() => chooseSubCat(null, id)}
            selected={isSelected && !selectedSubCategoryId}
          >
            <ListItemText
              primary={
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant="body1" fontWeight="bold">
                      {name}
                    </Typography>
                  </Grid>
                  {!!products_count && (
                    <Grid item sx={{ paddingTop: "4px !important" }}>
                      <Typography
                        variant="caption"
                        sx={{ color: `grey !important` }}
                      >
                        ({products_count})
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              }
            />
          </ListItemButton>
        </Grid>
        {sub_categories?.length > 0 && (
          <Grid item key={`${id}-2`} xs={12}>
            <List component="div" disablePadding>
              {sub_categories.map((sub_cat) => (
                <SubCategoryRow
                  key={sub_cat?.id}
                  {...sub_cat}
                  isSelected={isSelected && selectedSubCategoryId == sub_cat.id}
                  chooseSubCat={() => chooseSubCat(sub_cat.id, id)}
                />
              ))}
            </List>
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};
