import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import EditStoreForm from "../../../Profile/Customer/Store/EditStoreForm";
import MinOrderForm from "../Shipping/MinOrderForm";

function StoreFormContainer({ supplier_store, refresh, disabled = false }) {
  return (
    <Stack spacing={1}>
      <MinOrderForm supplier_store={supplier_store} refresh={refresh} disabled={disabled} />
      <Typography variant="h6" paddingTop={2}>
        Η επιχείρησή μου
      </Typography>
      <EditStoreForm
        user={{ supplier_store }}
        isSupplier
        refresh={refresh}
        disabled={disabled}
      />
    </Stack>
  );
}

export default StoreFormContainer;
