import { Alert, List } from "@mui/material";
import React from "react";
import Spinner from "../../../General/Spinner";
import { CategoryRow } from "./CategoryRow";

export const CatalogContent = ({
  isCatalogLoading,
  catalog,
  selectedCategoryId,
  selectedSubCategoryId,
  chooseSubCat,
}) => {
  if (isCatalogLoading) {
    return <Spinner />;
  }
  if (catalog?.length) {
    return (
      <List aria-label="categories" sx={{ width: "100%", paddingTop: 0 }}>
        {/* {renderOffersCategory()} */}
        {!!selectedCategoryId && (
          <CategoryRow key={0} type="back_to_all" chooseSubCat={chooseSubCat} />
        )}
        {catalog.map((category) => (
          <CategoryRow
            key={category.id}
            {...category}
            isSelected={category.id == selectedCategoryId}
            selectedSubCategoryId={selectedSubCategoryId}
            chooseSubCat={chooseSubCat}
          />
        ))}
      </List>
    );
  }
  return <Alert severity="info">Ο κατάλογος του προμηθευτή δεν βρέθηκε</Alert>;
};
