import { Box } from '@mui/material';
import React from 'react'

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ width: "100%" }}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt:2, width: 1 }}>
                {children}
          </Box>
        )}
      </div>
    );
}
