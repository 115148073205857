import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api/auth/authService";

const initialState = {
    user: {},
    isSupplier: false,
    token: null,
    logged : false,
    msg: "",
    status: "idle",
    result: "success",
    favs_msg: "",
    favs_result: "",
    favs_status: "idle",
}

const updateState = (payload, state, log=false) => {
    const { status, msg, user, token, isSupplier } = payload;
    console.log({ payload });
    if (log) {
        console.log("payload returned from logged: ", payload);
    }
    if (status==="success") {
        state.logged = true;
        state.token = token;
        state.user = user;
        state.msg = "Επιτυχής σύνδεση";
        state.isSupplier = isSupplier; 
    }
    else if (status==="error") {
        state.logged = false;
        state.token = null;
        state.user = {};
        state.msg = msg;
    }
    state.result = status;
    state.status = "idle"
}

export const login = createAsyncThunk(
    "users/login",
    async ({ data, rememberMe }) => {
        const response = await authService.login({ jsonData: data, rememberMe });
        return response;
    }
)

export const register = createAsyncThunk(
    "users/register",
    async ({ data, rememberMe }) => {
        const response = await authService.register({ jsonData: data, rememberMe });
        return response;
    }
)

export const checkLogged = createAsyncThunk(
    "users/logged",
    async () => {
        const response = await authService.logged();
        return response;
    }
)

export const updateUser = createAsyncThunk(
    "users/updateState",
    async () => {
        const response = await authService.logged();
        return response;
    }
)

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            const { status } = authService.logout();
            if (status==="success") {
                state = {
                    ...initialState,
                    msg: "Επιτυχής αποσύνδεση",
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.status = "loading";
            })
            .addCase(login.fulfilled, (state, action) => {
                updateState(action.payload, state);
            })
            .addCase(register.pending, (state) => {
                state.status = "loading";
            })
            .addCase(register.fulfilled, (state, action) => {
                updateState(action.payload, state);
            })
            .addCase(checkLogged.pending, (state) => {
                state.status = "loading";
            })
            .addCase(checkLogged.fulfilled, (state, action) => {
                updateState(action.payload, state, true);
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                const { user } = action.payload;
                if (user) {
                    state.user = user;
                }
            })
    }
});

export const { logout } = authSlice.actions;

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;