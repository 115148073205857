// https://teacode.io/blog/how-to-speed-up-your-react-app-using-cloudinary-and-lazy-loading
// https://www.npmjs.com/package/react-lazy-load-image-component

import { Skeleton } from "@mui/material";
import React, { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LazyCloudinaryImage } from "./LazyCloudinaryImage";

export const LazyImage = memo(({
  src,
  height = "100%",
  width = "100%",
  aspectRatio = "4/3",
}) => {
  return (
    <div style={{ width: "100%", aspectRatio: "4/3" }}>
      <LazyCloudinaryImage src={src} />
    </div>
  );

  return (
    <LazyLoadImage
      alt={"logo"}
      placeholder={
        <Skeleton
          variant="rectangular"
          width={"inherit"}
          height={"inherit"}
          sx={{ aspectRatio }}
          animation="wave"
        />
      }
      height={height}
      src={src}
      width={width}
      delayMethod="debounce"
      delayTime={2000}
      style={{ aspectRatio, height: "100%", width: "100%" }}
    />
  );
});
