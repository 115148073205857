import {
  alpha,
  Avatar,
  Button,
  CardHeader,
  ClickAwayListener,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { searchGlobal } from "../../../api/general";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  selectProducts,
  set_products_suggestions,
  set_products_suggestions_status,
} from "../../../redux/slices/productsSlice";
import {
  selectSuppliers,
  set_suppliers_suggestions,
  set_suppliers_suggestions_status,
} from "../../../redux/slices/suppliersSlice";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import {
  selectCategories,
  set_keyword,
} from "../../../redux/slices/categoriesSlice";
import { set_product } from "../../../redux/slices/oneProductSlice";
import Spinner from "../../General/Spinner";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { Clear, Search } from "@mui/icons-material";
import useDebounce from "../../../hooks/useDebounce";
import { zIndexes } from "../../../zIndexes";
import { useQuery } from "react-query";
import { queriesKeys } from "../../../api/queriesKeys";

const useStyles = makeStyles((theme) => ({
  supplier: {
    width: "100%",
    border: "none",
    backgroundColor: "inherit",
    padding: "1px !important",
    justifyContent: "center",
    textAlign: "left",
  },
  product: {},
}));

function SearchBar({ onStopSearching }) {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const [showingSuggestions, setShowingSuggestions] = useState(false);
  const [key, setKey] = useState("");
  const debouncedKey = useDebounce(key, searchParams.get("key") ? 0 : 500);

  const { keyword } = useSelector(selectCategories);
  useEffect(() => {
    dispatch(set_keyword(searchParams.get("key")));
  }, [searchParams.get("key")]);

  useEffect(() => {
    setKey(keyword);
  }, [keyword]);

  const { isMobile } = useIsMobile();

  const { suggestions: productsSuggestions } = useSelector(selectProducts);
  const { suggestions: suppliersSuggestions } = useSelector(selectSuppliers);

  const avatarStyles = {
    width: 24,
    height: 24,
    marginRight: "-10px",
  };

  const { data, isLoading, isError } = useQuery(
    [queriesKeys["searchGlobal"], debouncedKey],
    () => searchGlobal(debouncedKey),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const getAvatar = ({ logo, name }) => {
    if (logo) {
      return <Avatar sx={{ ...avatarStyles }} src={logo} alt="profile" />;
    }
    return (
      <Avatar
        style={{ zIndex: zIndexes.zero }}
        sx={{
          bgcolor: alpha(theme.palette.primary.main, 0.3),
          ...avatarStyles,
        }}
      >
        {name?.slice(0, 1)}
      </Avatar>
    );
  };

  const onSupplierClick = (id) => {
    setShowingSuggestions(false);
    // navigate(`/suppliers/${id}`);
    window.location.href = `/suppliers/${id}`;
  };

  const onProductClick = (id) => {
    setShowingSuggestions(false);
    const product_ = productsSuggestions.find((prod) => prod.id === id);
    dispatch(
      set_product({
        id: product_.id,
        isAdmin: false,
      })
    );
  };

  const SupplierSearchResult = ({ name, logo, id }) => {
    return (
      <Button
        color="inherit"
        sx={{ width: 1 }}
        onClick={() => onSupplierClick(id)}
      >
        <CardHeader
          avatar={getAvatar({ logo, name })}
          title={name}
          titleTypographyProps={{ fontSize: "10px" }}
          className={classes.supplier}
        />
      </Button>
    );
  };

  const ProductSearchResult = ({ name, image, id }) => {
    return (
      <Button
        color="inherit"
        sx={{ width: 1 }}
        onClick={() => onProductClick(id)}
      >
        <CardHeader
          avatar={getAvatar({ logo: image, name })}
          title={name}
          titleTypographyProps={{ fontSize: "10px" }}
          className={classes.supplier}
        />
      </Button>
    );
  };

  const renderKindSuggestions = ({ suggestions, component, title }) => {
    let content = (
      <Typography variant="body2" align="center" marginTop={1}>
        Δεν βρέθηκαν αποτελέσματα
      </Typography>
    );
    if (suggestions?.length) {
      content = suggestions.slice(0, 3).map((row) => {
        return (
          <MenuItem>
            <Grid item key={row.id} xs={12}>
              {component(row)}
            </Grid>
          </MenuItem>
        );
      });
    }
    return (
      <Stack padding={1}>
        <Typography textAlign={"center"} variant="subtitle2" fontWeight="bold">
          {title}
        </Typography>
        {content}
      </Stack>
    );
  };

  const renderSuggestions = () => {
    if (isLoading || debouncedKey !== key) {
      return <Spinner size={20} />;
    }
    return (
      <Grid container justifyContent={"center"} spacing={3}>
        <Grid item md={6} sm={12}>
          {renderKindSuggestions({
            suggestions: suppliersSuggestions,
            component: SupplierSearchResult,
            title: "Προμηθευτές",
          })}
        </Grid>
        <Grid item md={6} sm={12}>
          {renderKindSuggestions({
            suggestions: productsSuggestions,
            component: ProductSearchResult,
            title: "Προϊόντα",
          })}
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (isLoading) {
      dispatch(set_suppliers_suggestions_status({ status: "loading" }));
      dispatch(set_products_suggestions_status({ status: "loading" }));
    }
    else {
      const { products = [], suppliers = [] } = data || {};
      dispatch(set_products_suggestions(products));
      dispatch(set_suppliers_suggestions(suppliers));
    }
  }, [data, isLoading, isError])


  const submit = (event) => {
    console.log(`submitting with ${key}`);
    if (!key) return;
    dispatch(set_keyword(key));
    setShowingSuggestions(false);
    window.scroll({
      top: 200,
      left: 0,
      behavior: "smooth",
    });
    const form = document.getElementById("search-form");
    if (form) form.blur();
    navigate(`/search?key=${key}`);
  };

  return (
    // <Grid container justifyContent="center" sx={{ position: "absolute", marginLeft: 4 }}>
    <Grid container justifyContent="center" width={1}>
      <ClickAwayListener onClickAway={() => setShowingSuggestions(false)}>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            width: isMobile ? "inherit" : "inherit",
            margin: "0px 10px",
            position: "relative",
            border: "1px solid lightgrey",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Formik
                initialValues={{ key: /* keyword || */ key || "" }}
                enableReinitialize
                onSubmit={submit}
              >
                {({ setFieldValue, ...props }) => {
                  return (
                    <Form style={{ width: "100%" }} id="search-form">
                      <Grid
                        container
                        onClick={() => setShowingSuggestions(true)}
                        width={1}
                      >
                        <Grid item xs={!!key?.length ? 10 : 12}>
                          <Field
                            fullWidth
                            as={InputBase}
                            startAdornment={
                              <Search
                                htmlColor="grey"
                                sx={{ paddingRight: "4px" }}
                              />
                            }
                            placeholder="Αναζήτηση προμηθευτή ή προϊόντος"
                            name="key"
                            type="text"
                            style={{ height: "100%", padding: "8px" }}
                            onChange={(event) => {
                              setKey(event.target.value);
                              setFieldValue("key", event.target.value);
                            }}
                          />
                        </Grid>
                        {!!key?.length && (
                          <Grid item xs={2}>
                            <Grid container justifyContent={"flex-end"}>
                              <IconButton
                                onClick={() =>
                                  !!onStopSearching
                                    ? onStopSearching()
                                    : dispatch(set_keyword(null))
                                }
                                sx={{ p: "10px" }}
                                aria-label="clear"
                              >
                                <Clear />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
            {showingSuggestions && (
              <Paper
                component={Grid}
                item
                xs={12}
                elevation={0}
                sx={{
                  position: "absolute",
                  width: "inherit",
                  top: "51px",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  border: "1px solid lightgrey",
                  minHeight: "100px",
                }}
              >
                {renderSuggestions()}
              </Paper>
            )}
          </Grid>
        </Paper>
      </ClickAwayListener>
    </Grid>
  );
}

export default SearchBar;
