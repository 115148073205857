import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIsMobile } from '../../hooks/useIsMobile';
import { closeConfirmationDialog, selectConfirmationDialog } from '../../redux/slices/confirmationDialogSlice'

function ConfirmationDialog() {
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();
    const { title, message, onConfirm } = useSelector(selectConfirmationDialog);

    const closeMe = () => dispatch(closeConfirmationDialog());

    const confirm = () => {
        onConfirm();
        closeMe();
    }

    if (title) {
        return (
            <Dialog open={true} onClose={closeMe} fullWidth={isMobile}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10}>
                            {title}                        
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent="flex-end">
                                <IconButton onClick={closeMe}>
                                    <Close sx={{ fontSize: "30px"}} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={closeMe}>
                        Ακυρο
                    </Button>
                    <Button coloe="primary" onClick={confirm}>
                        Διαγραφή
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    return null;
}

export default ConfirmationDialog;