import * as Yup from 'yup';

const required_msg = "Υποχρεωτικό πεδίο";

const min_msg = (num) => `Πρέπει να περιέχει τουλάχιστον ${num} χαρακτήρες`;
const max_msg = (num) => `Πρέπει να περιέχει το πολύ ${num} χαρακτήρες`;
const positive_msg = `Δεν μπορεί να έχει μηδενική ή αρνητική τιμή`;

const hasMaxTwoDigits = (number) => {
    if (!number) return true;
    const parts = number.toString().split(".");
    if (parts.length===1) return true;
    else if (parts.length===2) {
        const decimals = parts[1];
        return decimals.length<=2;
    }
    return false;
}

const baseProductSchema = {
    name: Yup.string()
        .required(required_msg)
        .min(4, min_msg(4))
        .max(60, max_msg(60)),
    price: Yup.number()
        .required(required_msg)
        .moreThan(0, "Δεν γίνεται να έχει αρνητική ή μηδενική τιμή")
        .test(
            "maxDigitsAfterDecimal",
            "Μπορεί να έχει το πολύ δύο δεκαδικά ψηφία",
            (number) => hasMaxTwoDigits(number)
        ),
    price_currency: Yup.string()
        .required(required_msg),
    category: Yup.string()
        .required(required_msg),
    sub_category: Yup.string()
        .required(required_msg),
    package_quantity: Yup.number()
        .required(required_msg)
        .min(1, positive_msg), 
    min_quantity: Yup.number()
        .required(required_msg)
        .min(1, positive_msg),
    max_quantity: Yup.number()
        .min(1, positive_msg)
        .min(
            Yup.ref("min_quantity"),
            `Η μέγιστη ποσότητα πρέπει να ξεπερνάει την ελάχιστη`,
        )
        .nullable(true)
        .transform((_, val) => val === Number(val) ? val : null),
    description: Yup.string()
        .max(800, max_msg(800))
        .nullable(),
    vat_percentage: Yup.string()
            .required(required_msg),
    // image: Yup.mixed().required(required_msg),
}

export const hyperQuantity = {
    hyper_min_quantity: Yup.number()
        .required(required_msg)
        .min(1, positive_msg)
        .moreThan(
            Yup.ref("min_quantity"),
            `Πρέπει να ξεπερνάει την ελάχιστη ποσότητα χονδρικής`,
        ),
        // .test({
        //     name: "multiplication-validation",
        //     test: function (value) {
        //         const { max_quantity } = this.options.context;
        //         console.log(this.options.context);
        //         if (max_quantity && value>max_quantity) {
        //             this.createError({
        //                 message: "aaa",
        //                 path: "hyper_min_quantity"
        //             });
        //         }
        //         else {
        //             return true;
        //         }
        //     }
        // }),
        // .max(
        //     Yup.ref("max_quantity"),
        //     `Πρέπει να μην ξεπερνάει την μέγιστη ποσότητα χονδρικής`
        // )
        //.transform((_, val) => val === Number(val) ? val : null),
    hyper_price: Yup.number()
        .required(required_msg)
        .moreThan(0, "Δεν γίνεται να έχει αρνητική ή μηδενική τιμή")
        .lessThan(
            Yup.ref("price"),
            "Πρέπει να είναι μικρότερη από την τιμή χονδρικής",
        )
        .transform((_, val) => val === Number(val) ? val : null)
        .test(
            "maxDigitsAfterDecimal",
            "Μπορεί να έχει το πολύ δύο δεκαδικά ψηφία",
            (number) => hasMaxTwoDigits(number)
        ),
}

const weight = {
    weight: Yup.number().typeError("Εισάγετε έγκυρο αριθμό")
        // .required(required_msg)
        .nullable()
        .min(0, "Δεν γίνεται να έχει αρνητική τιμή"),
    weight_metric: Yup.string(),
        // .required(required_msg),    
}

const volume = {
    volume: Yup.number().typeError("Εισάγετε έγκυρο αριθμό")
        // .required(required_msg)
        .nullable()
        .min(0, "Δεν επιτρέπεται να έχει αρνητική τιμή"),
    volume_metric: Yup.string(),
        // .required(required_msg),
}

export const ProductWeightWithHyperQuantity = Yup.object().shape({
    ...baseProductSchema,
    ...weight,
    ...hyperQuantity,
})

export const ProductWeightWithoutHyperQuantity = Yup.object().shape({
    ...baseProductSchema,
    ...weight,
})

export const ProductVolumeWithHyperQuantity = Yup.object().shape({
    ...baseProductSchema,
    ...volume,
    ...hyperQuantity,
})

export const ProductVolumeWithoutHyperQuantity = Yup.object().shape({
    ...baseProductSchema,
    ...volume,
})


const OfferBasicSchema = {
    product_name: Yup.string()
        .required(required_msg),
    product_price: Yup.number()
        .required(required_msg)
        .min(0, "Δεν γίνεται να έχει αρνητική τιμή"),
    package_quantity: Yup.number()
        .required(required_msg)
        .min(1, positive_msg), 
    product_min_quantity: Yup.number()
        .required(required_msg)
        .min(1, positive_msg),
    product_max_quantity: Yup.number()
        .required(required_msg)
        .min(1, positive_msg)
        .min(
            Yup.ref("product_min_quantity"),
            `Η μέγιστη ποσότητα πρέπει να ξεπερνάει την ελάχιστη`,
        ),
    price: Yup.number()
        .required(required_msg)
        .moreThan(0, "Δεν γίνεται να έχει αρνητική ή μηδενική τιμή")
        .lessThan(
            Yup.ref("product_price"),
            `Η τιμή της προσφοράς πρέπει να είναι μικρότερη της αρχικής τιμής`,
        )
        .test(
            "maxDigitsAfterDecimal",
            "Μπορεί να έχει το πολύ δύο δεκαδικά ψηφία",
            (number) => hasMaxTwoDigits(number)
        ),
    min_quantity: Yup.number()
        .required(required_msg)
        .min(1, positive_msg)
        .min(
            Yup.ref("product_min_quantity"),
            `Πρέπει να ξεπερνάει την ελάχιστη ποσότητα της κανονικής παραγγελίας`,
        )
        .max(
            Yup.ref("product_max_quantity"),
            `Δεν μπορεί να ξεπερνάει την μέγιστη ποσότητα της κανονικής παραγγελίας`
        )
}
export const OfferValidationSchema = Yup.object().shape({
    ...OfferBasicSchema,
})