import { makeStyles } from "@mui/styles";
import React from "react";
import { useIsMobile } from "../../hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
  container: {
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: 'none',
      borderRadius: "10px",
    }
  }
}));

function Scrollable({
  children,
  isScrollable = true,
  height,
  maxHeight,
  minHeight,
}) {
  const classes = useStyles();
  const { isMobile } = useIsMobile();

  if (isScrollable) {
    return (
      <div
      className={isMobile ? "" : classes.container}
        style={{
          height: height || "100%",
          ...(maxHeight && { maxHeight }),
          ...(minHeight && { minHeight }),
          overflowY: "scroll",
        }}
      >
        {children}
      </div>
    );
  }
  return children;
}

export default Scrollable;
