import React, { useMemo } from "react";
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

export const LazyCloudinaryImage = ({ src }) => {
  // Create a Cloudinary instance and set your cloud name.
  const cld = useMemo(() => (
    new Cloudinary({
      cloud: {
        cloudName: process.env.REACT_APP_CLOUD_NAME || "da7mbf3bi",
      },
    })
  ), [])

  const myImage = useMemo(() => (
    cld.image(src?.split("/").slice(-1)?.[0])
  ), [cld])


    if (src?.includes("%")) {
      return (
        <img style={{ width: "100%", height: "100%" }} src={src} />
      )
    }

  // Render the image in a React component.
  return (
    <AdvancedImage
      width="100%"
      height="100%"
      cldImg={myImage}
      plugins={[lazyload(), placeholder({ mode: "blur" })]}
    />
  );
};
