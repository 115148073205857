import { CheckCircle, Close } from '@mui/icons-material';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { queriesKeys } from '../../api/queriesKeys';
import { getSuppliersCallAdmin } from '../../api/suppliers';
import MyDivider from '../General/MyDivider';

export const SuppliersTable = () => {
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const theme = useTheme();

    const { data, isLoading } = useQuery(
        queriesKeys['getSuppliers'],
        () => getSuppliersCallAdmin("all"), {
            refetchOnWindowFocus: false,
        }
    );

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            sortable: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            sortable: true,
        },
        {
            field: 'verified',
            headerName: 'Verified',
            width: 200,
            sortable: true,
            renderCell: (params) => {
                const verified = params.row.verified;
                if (verified) {
                    return (
                        <CheckCircle
                            htmlColor={theme.palette.success.main}
                        />
                    )
                }
                return (
                    <Close
                        htmlColor={theme.palette.error.main}
                    />
                )
            }
        },
        {
            field: 'cancelled_orders',
            headerName: 'Cancelled orders',
            width: 200,
            sortable: true,
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 200,
            renderCell: (params) => {
                const id = params.row.id;
                return (
                    <Button
                        variant="contained"
                        onClick={()=>navigate(`/super/suppliers?id=${id}`)}
                    >
                        Edit
                    </Button>
                )
            }
        }
    ]

    console.log({ data });

    const rows = isLoading ? [] : (data || []);

    return (
        <Grid container>
            <Typography variant="h6">
                Suppliers
            </Typography>
            <MyDivider />
            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                rowCount={rows?.length || 0}
                loading={isLoading}
                pagination
                pageSize={20}
                onPageChange={(newPage) => setPage(newPage)}
            />
        </Grid>
    )
    
}