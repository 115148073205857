import React, { cloneElement } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { NavigateNext } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectBreadCrumb } from "../../redux/slices/breadCrumbSlice";
import { useTheme } from "@mui/material";

// https://mui.com/material-ui/react-breadcrumbs/

export const BreadCrumb = () => {
  const theme = useTheme();
  const { links, color } = useSelector(selectBreadCrumb);
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNext fontSize="small" htmlColor={color} />}
      sx={{
        // marginTop: -5,
        // marginBottom: 5,
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: "inherit",
        // color: "white",
        borderRadius: 3,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: 1,
      }}
    >
      {links?.map(({ href, text, icon }) => (
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color={color}
          href={href}
        >
          {!!icon &&
            cloneElement(icon, {
              fontSize: "inherit",
              sx: { mr: 0.5 },
            })}
          {text}
        </Link>
      ))}
    </Breadcrumbs>
  );
};
