export const currencies = [
    {
        id: 'euros',
        name: '€',
    },
];

export const volume_metrics = [
    {
        id: 'lt',
        name: "L"
    },
    {
        id: "ml",
        name: "ml"
    },
]

export const weight_metrics = [
    {
        id: 'kg',
        name: "kg"
    },
    {
        id: "gr",
        name: "gr"
    },
]

export const vat_percentages = [
    {
        id: 0.0,
        name: "0%",
    },
    {
        id: 0.04,
        name: "4%"
    },
    {
        id: 0.06,
        name: "6%",
    },
    {
        id: 0.09,
        name: "9%",
    },
    {
        id: 0.13,
        name: "13%"
    },
    {
        id: 0.17,
        name: "17%"
    },
    {
        id: 0.24,
        name: "24%",
    },
]

export const days = [
    {
        name: "Δευτέρα",
        value: 0,
    },
    {
        name: "Τρίτη",
        value: 1,
    },
    {
        name: "Τετάρτη",
        value: 2,
    },
    {
        name: "Πέμπτη",
        value: 3,
    },
    {
        name: "Παρασκευή",
        value: 4,
    },
    {
        name: "Σάββατο",
        value: 5,
    },
    {
        name: "Κυριακή",
        value: 6,
    }
]

export const times = [
    {
        name: "01:00",
        value: 1,
    },
    {
        name: "02:00",
        value: 2,
    },
    {
        name: "03:00",
        value: 3,
    },
    {
        name: "04:00",
        value: 4,
    },
    {
        name: "05:00",
        value: 5,
    },
    {
        name: "06:00",
        value: 6,
    },
    {
        name: "07:00",
        value: 7,
    },
    {
        name: "08:00",
        value: 8,
    },
    {
        name: "09:00",
        value: 9,
    },
    {
        name: "10:00",
        value: 10,
    },
    {
        name: "11:00",
        value: 11,
    },
    {
        name: "12:00",
        value: 12,
    },
    {
        name: "13:00",
        value: 13,
    },
    {
        name: "14:00",
        value: 14,
    },
    {
        name: "15:00",
        value: 15,
    },
    {
        name: "16:00",
        value: 16,
    },
    {
        name: "17:00",
        value: 17,
    },
    {
        name: "18:00",
        value: 18,
    },
    {
        name: "19:00",
        value: 19,
    },
    {
        name: "20:00",
        value: 20,
    },
    {
        name: "21:00",
        value: 21,
    },
    {
        name: "22:00",
        value: 22,
    },
    {
        name: "23:00",
        value: 23,
    },
    {
        name: "00:00",
        value: 0,
    },
]