export const queriesKeys = {
    'superuserCarts': 'superuser/carts/get',
    'getOneCart': 'carts/one/get',
    'countCarts': 'carts/all/count',
    'getCustomers': 'customers/all/get',
    'getSuppliers': 'suppliers/all/get',
    'getOneSupplier': 'suppliers/one/get',
    'getOneSupplierOrders': 'suppliers/one/orders/get',
    'getOneSupplierCatalog': 'suppliers/one/catalog/get',
    'getOneSupplierProductsFiltered': 'suppliers/one/products/filtered/get',
    'getOneSupplierOffers': `suppliers/one/offers/all/get`,
    'getOneSupplierTaxNumberOffers': `suppliers/one/tax-number-offers/all/get`,
    'getOneTaxNumberOffers': `tax-numbers/one/tax-number-offers/get`,
    'searchGlobal': `search/global`,
    'getOrderAgainData': `orders/one/again/get`,
}