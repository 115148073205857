import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Menu } from "@mui/icons-material";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { zIndexes } from "../../zIndexes";
import { useEffect } from "react";
import { useState } from "react";
import { HelpText } from "./HelpText";
import { Stack } from "@mui/material";
import { setLinks } from "../../redux/slices/breadCrumbSlice";
import { useDispatch } from "react-redux";
import Cart from "../Cart/Cart";
import { CommonQuestions } from "./CommonQuestions";
import { useIsMobile } from "../../hooks/useIsMobile";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
}));

const links = [
  // {
  //   text: "Όραμα εταιρείας",
  //   page: "vision",
  //   component: <HelpText term_key={"company_vision"} />,
  // },
  // {
  //   type: "divider",
  // },
  {
    text: "Πώς λειτουργεί",
    page: "howto",
    component: <HelpText term_key={"how_it_works"} />,
  },
  {
    text: "Συχνές ερωτήσεις για προμηθευτές",
    page: "suppliers",
    component: <CommonQuestions for_suppliers={true} />,
  },
  {
    text: "Συχνές ερωτήσεις για επιχειρήσεις",
    page: "customers",
    component: <CommonQuestions for_suppliers={false} />,
  },
  {
    type: "divider",
  },
  {
    text: "Πολιτική απορρήτου",
    page: "privacy",
    component: <HelpText term_key={"privacy_policy"} />,
  },
  {
    text: "Όροι χρήσης",
    page: "terms",
    component: <HelpText term_key={"terms_of_usage"} />,
  },
  {
    text: "Πολιτική cookies",
    page: "cookies-policy",
    component: <HelpText term_key={"cookies_policy"} />,
  },
  {
    type: "divider",
  },
  {
    text: "Επικοινωνία",
    page: "contact",
    component: <HelpText term_key={"contact"} />,
  },
];

export default function DrawerLeft() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(links[0]);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile])

  useEffect(() => {
    const urlPage = searchParams.get("page");
    if (!urlPage) {
      navigate(`/help?page=${links[0]?.page}`);
    } else {
      setCurrentPage(links.find(({ page }) => page === urlPage));
    }
  }, [searchParams, navigate]);

  useEffect(() => {
    dispatch(setLinks({
      links: [
        {
          text: "MarketOn",
          href: "/",
        },
        {
          text: "Βοήθεια",
          href: `/help?page=contact`,
        },
        {
          text: currentPage.text,
          href: "#",
        },
      ],
      color: "white",
    }))
  }, [currentPage, dispatch])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        zIndex: zIndexes.helpDrawer,
        minHeight: 500,
      }}
    >
      {!open && (
        <IconButton sx={{ height: "min-content" }} onClick={handleDrawerOpen}>
          <Menu />
        </IconButton>
      )}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "inherit !important",
            overflowY: "hidden",
          },
          "& .MuiDrawer-root": {
            position: "absolute",
          },
          "& .MuiPaper-root": {
            position: "absolute",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{ justifyContent: "space-between", padding: 1 }}>
          <Typography variant="body1">Πληροφορίες</Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {links.map(({ text, page, type }, index) => {
            if (type === "divider") {
              return <Divider key={index} />;
            }
            return (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  selected={currentPage?.page === page}
                  component={NavLink}
                  to={`/help?page=${page}`}
                >
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <Main open={open}>
        <Stack spacing={2}>
          <Typography variant="h5">{currentPage?.text}</Typography>
          {currentPage?.component}
        </Stack>
        <Cart isDrawer />
      </Main>
    </Box>
  );
}
