import { alpha, useTheme, Button } from "@mui/material";
import React from "react";
import MyAvatar from "../General/MyAvatar";
import { Price } from "./Price";

const SupplierHeader = ({ supplier, cost_before_offer, currency, calculateTaxNumberOfferForSupplier }) => {
  const theme = useTheme();
  const { name, id, min_order_cost } = supplier;

  const goToSupplier = () => {
    // navigate(`/suppliers/${id}`);
    window.location.href = `/suppliers/${id}`;
  };

  const { cost_after_offer, offer_percentage } = calculateTaxNumberOfferForSupplier(id, cost_before_offer);

  return (
    <MyAvatar
      component={Button}
      logo={supplier?.logo}
      name={supplier?.name?.slice(0, 1)}
      alt="profile"
      title={name}
      action={
        <div>
          <Price
            price={cost_before_offer}
            currency={currency}
            strike={!!offer_percentage}
            fontSize="medium"
          />
          {!!offer_percentage && (
            <Price
              price={cost_after_offer}
              currency={currency}
              strike={false}
              fontSize="medium"
            />
          )}
        </div>
      }
      onClick={goToSupplier}
      sx={{
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
        borderRadius: "10px",
        textTransform: "none",
      }}
    />
  );
};

export default SupplierHeader;
