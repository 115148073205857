import React, { useState } from "react";
import { Grid, Menu, MenuItem, Divider, IconButton, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { selectAuth } from "../../../redux/slices/authSlice";
import { useSelector } from "react-redux";
import LoginForm from "../../LoginRegister/Login";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { GeneralDialog } from "../../General/GeneralDialog";
import authService from "../../../api/auth/authService";

function UserMenu({ htmlColor = "white", showFavourites = false, fontSize }) {
  const auth = useSelector(selectAuth);
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const [showingLoginForm, setShowingLoginForm] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutAction = () => {
    // localStorage.removeItem("token");
    authService.logout();
    window.location.href = "/";
  };

  const renderIcon = () => {
    if (auth?.user?.is_superuser) return null;
    return (
      <IconButton
        size="large"
        onClick={handleClick}
        sx={{ ...(isMobile && { paddingBottom: 0 }) }}
      >
        {/* <Badge badgeContent={badgeContent} color='error'> */}
        <AccountCircle
          fontSize={fontSize || "medium"}
          sx={{ padding: 0 }}
          htmlColor={htmlColor}
        />
        {/* </Badge> */}
      </IconButton>
    );
  };

  const renderMenu = () => {
    if (!auth || !auth.logged || !auth.user) {
      return (
        <Menu
          disableEnforceFocus
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => setShowingLoginForm(true)}>Σύνδεση</MenuItem>
          <MenuItem onClick={() => navigate("/customers/register")}>
            Εγγραφή
          </MenuItem>
          <MenuItem onClick={() => navigate("/help?page=contact")}>
            Βοήθεια
          </MenuItem>
        </Menu>
      );
    } else if (auth?.user?.is_superuser) {
      return (
        <Button
          size="large"
          sx={{ fontSize: "15px", marginTop: 0.5 }}
          onClick={logoutAction}
          color="inherit"
        >
          Logout
        </Button>
      );
    } else {
      const name =
        auth.user.supplier_store?.name || auth.user.customer_store?.name;
      // const profile_link = auth.isSupplier ? `/suppliers/${auth.user.id}` : `/customers/${auth.user.id}`
      const profile_link = auth.user?.supplier_store ? `/admin` : `/account`;
      // const profile_link = `/admin`;
      return (
        <Menu
          disableEnforceFocus
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem sx={{ width: 1 }} disabled>
            {name}
          </MenuItem>
          <Divider />
          <MenuItem sx={{ width: 1 }} onClick={() => navigate(profile_link)}>
            Η επιχείρησή μου
          </MenuItem>
          {showFavourites && !auth.isSupplier && (
            <MenuItem sx={{ width: 1 }} onClick={() => navigate("/favourites")}>
              Αγαπημένα
            </MenuItem>
          )}
          <MenuItem
            sx={{ width: 1 }}
            onClick={() => navigate("/help?page=contact")}
          >
            Βοήθεια
          </MenuItem>
          <MenuItem sx={{ width: 1 }} onClick={logoutAction}>
            Αποσύνδεση
          </MenuItem>
        </Menu>
      );
    }
  };

  const renderLoginForm = () => {
    if (showingLoginForm) {
      return (
        <GeneralDialog
          fullWidth
          title="Καλώς ήρθατε στο MarketOn"
          onClose={() => setShowingLoginForm(false)}
          content={<LoginForm showMessages />}
        />
      );
    }
    return null;
  };

  return (
    <Grid container justifyContent={"flex-end"} alignItems="center" padding={0}>
      {renderIcon()}
      {renderMenu()}
      {renderLoginForm()}
    </Grid>
  );
}

export default UserMenu;
