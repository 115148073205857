import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import history from "./history.js";
import './index.css';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { RouterScrollToTop } from './components/General/RouterScrollToTop.js';
import { Help } from './pages/Help.js';
import { OneOrderEditPage } from './pages/OneOrderEditPage.js';
import { SuperUserSuppliers } from './pages/SuperUserSuppliers.js';
import Spinner from './components/General/Spinner.jsx';

const App = lazy(() => import('./pages/App'));
const Register = lazy(() => import('./pages/Register'));
const SupplierProfile = lazy(() => import('./pages/SupplierProfile'));
const Admin = lazy(() => import('./pages/Admin'));
const OrderConfirmation = lazy(() => import('./pages/OrderConfirmation'));
const CustomerProfile = lazy(() => import('./pages/CustomerProfile'));
const PasswordUpdate = lazy(() => import('./pages/PasswordUpdate'));
const Favourites = lazy(() => import('./pages/Favourites'));
const NotFound = lazy(() => import('./pages/NotFound'));
const SuperUser = lazy(() => import('./pages/Superuser'));


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 980,
      lg: 1024,
      xl: 1536,
    },
  },
});

const queryClient = new QueryClient({
  refetchOnWindowFocus: false,
});

if (process.env.NODE_ENV === 'production') {
console.log = () => {}
console.error = () => {}
console.debug = () => {}
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Suspense fallback={<Spinner />}>
            <BrowserRouter History={history}>
              <RouterScrollToTop />
              <Routes>
                <Route path="/"                      element={<App />}                       />
                <Route path="/search"                element={<App search />}                />
                <Route path="/suppliers/:id"         element={<SupplierProfile />}           />
                <Route path="/suppliers/register"    element={<Register type="supplier" />}  />
                <Route path="/customers/register"    element={<Register type="customer" />}  />
                <Route path="/confirmation"          element={<OrderConfirmation />}         />
                <Route path="/admin"                 element={<Admin />}                     />
                <Route path="/account"               element={<CustomerProfile />}           />
                <Route path="/favourites"            element={<Favourites />}                />
                <Route path="/password-reset"        element={<PasswordUpdate />}            />
                <Route path="/super"                 element={<SuperUser /> }                />
                <Route path="/super/orders"          element={<SuperUser /> }                />
                <Route path="/super/suppliers"       element={<SuperUserSuppliers /> }       />
                <Route path="/super/orders/search"   element={<OneOrderEditPage /> }         />
                <Route path="/help"                  element={<Help /> }                     />
                <Route path="*"                      element={<NotFound />}                  />
              </Routes>
            </BrowserRouter>
          </Suspense>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
