import { Fab, Grow, useScrollTrigger } from "@mui/material";
import React from "react";

export const DynamicScrollFab = ({
  text,
  icon,
  onClick,
  endText,
}) => {
  const scrollDirection = useScrollTrigger();

  return (
    <div style={{ position: "relative" }}>
      <Grow
        in={!scrollDirection}
        timeout={500}
        sx={{ position: "absolute", right: 0 }}
      >
        <Fab
          variant="extended"
          size="large"
          color="primary"
          onClick={onClick}
          style={{ textTransform: "none" }}
        >
          {icon}
          {text}
          {endText}
        </Fab>
      </Grow>
      <Grow in={scrollDirection} timeout={500}>
        <Fab variant="extended" size="large" color="primary" onClick={onClick}>
          {icon}
          {endText}
        </Fab>
      </Grow>
    </div>
  );
};
