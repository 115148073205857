export const scrollToHeader = () => {
  const headerEnd = document.getElementById("header-end");
  if (headerEnd) {
    var headerOffset = 100;
    var elementPosition = headerEnd.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset + headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
  else {
    window.scrollTo({
      top: 150,
      behavior: "smooth",
    });
  }

};
