import React from 'react'
import { useSelector } from 'react-redux';
import { selectOneProduct } from '../../redux/slices/oneProductSlice';
import { ConsentDialog } from '../Cookies/ConsentDialog';
import ProductPopUp from '../Home/Products/PopUp/Main';
import { BackToTop } from './BackToTop';
import ConfirmationDialog from './ConfirmationDialog'
import SnackbarMessage from './SnackbarMessage'

function GlobalComponents() {
    const { id } = useSelector(selectOneProduct);
    
    return (
        <>
            <ConsentDialog />
            <SnackbarMessage />
            <ConfirmationDialog />
            <ProductPopUp key={id} />
            {/* <BackToTop /> */}
        </>
    )
}

export default GlobalComponents;