import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getProductsCall } from "../../api/products";
import { productsPaginationStep } from "../../helpers/paginationSteps";

const initialState = {
    all: [],
    all_status: "idle",
    hasMore: true,
    suggestions: [],
    suggestions_status: "idle",
}

export const getProducts = createAsyncThunk(
    'products/fetchProducts',
    async ({ type, categories, start, end }) => {
        try {
            const response = await getProductsCall({ type, categories, start, end });
            return {
                data: response.data,
                concat: start!==1,
            };
        }
        catch (err) {
            return {
                data: [],
                concat: true,
            }
        }
    }
)

export const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        set_products_suggestions: (state, action) => {
            const suggestions = action.payload;
            state.suggestions = suggestions;
        },
        set_products_suggestions_status: (state, action) => {
            const { status } = action.payload;
            state.suggestions_status = status;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.all_status = "loading";
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.all_status = "idle";
                const { data, concat } = action.payload;
                state.hasMore = data?.length===productsPaginationStep;
                state.all = concat ? state.all.concat(data) : data;
            })
    }
});

export const { set_products_suggestions, set_products_suggestions_status } = productsSlice.actions;

export const selectProducts = (state) => state.products;

export default productsSlice.reducer;