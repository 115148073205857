import {
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { getCookie, setCookie } from "../../helpers/cookies";
import { ConsentDetails } from "./ConsentDetails";

export const ConsentDialog = () => {
  const [informed, setInformed] = useState(
    getCookie("marketon-cookies-informed") === "true"
  );
  const theme = useTheme();

  const updateUserInformed = () => {
    console.log("informing user...");
    setCookie("marketon-cookies-informed", "true", 200);
    setInformed(true);
  };

  if (!informed) {
    return (
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          zIndex: 99999,
          padding: "8px 0px",
          borderRadius: 0,
          width: 1,
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Stack alignItems="center" spacing={2} width={"80%"} marginLeft={"10%"}>
          <Typography variant="h6" align="center">
            Το MarketOn χρησιμοποιεί cookies
          </Typography>
          <ConsentDetails />
          <Grid container maxWidth={800} justifyContent={"space-between"}>
            <Button
              variant="outlined"
              component={NavLink}
              to="/help?page=cookies-policy"
              sx={{ textTransform: "none" }}
              size="large"
            >
              Πληροφορίες
            </Button>
            <Button
              variant="contained"
              onClick={updateUserInformed}
              sx={{ textTransform: "none" }}
              size="large"
            >
              Εντάξει
            </Button>
          </Grid>
        </Stack>
      </Paper>
    );
  }

  return null;
};
