import { FormHelperText } from "@mui/material";

export const getFieldErrors = (name, errors, touched) => {
    if (Object.keys(errors).length) {
        const error = touched[name] && errors[name]?.length>0;
        const helperText = error ? errors[name] : " ";
        return { error, helperText }    
    }
    return { error: false, helperText: " " }
}

export const getFieldErrorsForFieldArray = (name, index, property, errors, touched) => {
    if (Object.keys(errors).length) {
        const error = touched[name] && errors[name]?.[index]?.[property];
        const helperText = error ? errors[name]?.[index]?.[property] : " ";
        return { error, helperText }
    }
    return { error: false, helperText: " " }
}

export const renderSelectHelperText = (name, errors, touched) => {
    const { error, helperText } = getFieldErrors(name, errors, touched);
    if (error) {
        return (
            <FormHelperText id={`${name}-helper-text`} error>
                {helperText}
            </FormHelperText>
        )
    }
    return null;
}