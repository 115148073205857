import axios from "axios";
import config from "./config";
import { buildAuthHeader, getRequest, parseFormDataFromJson } from "./helpers";
axios.defaults.baseURL = config.apiUrl;



export const getSuppliersCall = (type, categories) => {    
    const requestUrl = "/suppliers";
    if (type==="all") {
        return axios.get(requestUrl);
    }
    console.log(categories);
    const params = { categories }
    return axios.get(requestUrl, { params });
}

export const getSuppliersCallAdmin = () => {
    const requestUrl = "/superuser/suppliers";
    return getRequest({ requestUrl });
}

export const getOneSupplierCall2 = (id) => {
    const requestUrl = `/suppliers/${id}`;
    return getRequest({ requestUrl });
}

export const searchSupplierProducts = (key, supplierId) => {
    const requestUrl = `/search`;
    const params = { key, supplier: supplierId };
    return getRequest({ requestUrl, params });
}

export const getOneSupplierTaxNumberOffers = (supplierId) => {
    const requestUrl = `/suppliers/${supplierId}/tax-number-offers`;
    return getRequest({ requestUrl });
}

export const createOneSupplierTaxNumberOffersCall = (supplierId, body) => {
    const requestUrl = `/suppliers/${supplierId}/tax-number-offers/bulk`;
    const headers = buildAuthHeader();
    return axios.post(requestUrl, body, { headers });
}

export const updateFavSuppliersCall = (type, supplier_id) => {
    const requestUrl = `/suppliers/favs/${supplier_id}`;
    const headers = buildAuthHeader();

    if (type==="add") {
        return axios.post(requestUrl, {}, { headers });
    }
    return axios.delete(requestUrl, { headers });
}

export const getFavsCall = () => {
    const requestUrl = 'suppliers/favs';
    const headers = buildAuthHeader();
    return axios.get(requestUrl, { headers });
}

export const getOneSupplierCall = (id) => {
    const requestUrl = `/suppliers/${id}`;
    return axios.get(requestUrl);
}

export const updateOneSupplierCall = (id, body_json) => {
    const body_form = parseFormDataFromJson(body_json);
    const requestUrl = `/suppliers/${id}`;
    const headers = buildAuthHeader();
    console.log({ requestUrl, body_form, headers })
    return axios.put(requestUrl, body_form, { headers });
}

export const getOneSupplierOrderStatsCall = () => {
    const requestUrl = `/orders/stats`;
    const headers = buildAuthHeader();
    return axios.get(requestUrl, { headers });
}

export const getNotificationsCall = () => {
    const requestUrl = `/notifications/supplier`;
    const headers = buildAuthHeader();
    return axios.get(requestUrl, { headers });
}

export const MarkNotificationAsSeenCall = (id) => {
    const requestUrl = `/carts/${id}/seen`;
    const headers = buildAuthHeader();
    return axios.put(requestUrl, {}, { headers });
}

export const UpdateMinOrderCall = (supplier_id, body_json) => {
    const requestUrl = `/min-order-cost/${supplier_id}/update`;
    const headers = buildAuthHeader();
    const body_form = parseFormDataFromJson(body_json);
    return axios.put(requestUrl, body_form, { headers });
}

export const ClearMinOrderCall = (supplier_id) => {
    const requestUrl = `/min-order-cost/${supplier_id}/update`;
    const headers = buildAuthHeader();
    return axios.delete(requestUrl, { headers });
}

export const updateCartDeliveryDate = ({ id, date }) => {
    const requestUrl = `/carts/${id}/date`;
    const headers = buildAuthHeader();
    const bodyForm = new FormData();
    bodyForm.append("estimated_delivery_date", date);
    return axios.put(requestUrl, bodyForm, { headers });
}

export const getTopSuppliers = ({ kind="global" }) => {
    const headers = buildAuthHeader();
    let requestUrl = `/suggestions/personal?kind=suppliers`;
    if (kind==="global") requestUrl = `/suggestions?kind=suppliers`;
    return axios.get(requestUrl, { headers });
}

export const getSupplierOffersCall = (id) => {
    const requestUrl = `/offers?supplier=${id}`;
    return axios.get(requestUrl);
}

export const updateSupplierDescription = ({ id, description }) => {
    const requestUrl = `/suppliers/${id}/description`;
    const headers = buildAuthHeader();
    const body = { description };
    return axios.put(requestUrl, body, { headers });
}

export const updateSupplierHighlights = ({ id, highlights }) => {
    const requestUrl = `/suppliers/${id}/highlights`;
    const headers = buildAuthHeader();
    const body = { highlights };
    return axios.put(requestUrl, body, { headers });
}

export const requestSupplierSamples = ({ supplierId, text }) => {
    const requestUrl = `/suppliers/${supplierId}/samples`;
    const headers = buildAuthHeader();
    const body = { text };
    return axios.post(requestUrl, body, { headers });
}