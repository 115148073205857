import { Typography, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { getTopSuppliers } from '../../../api/suppliers';
import MyCarousel from '../../General/MyCarousel';
import Spinner from '../../General/Spinner';
import Supplier from './Supplier';

function TopSuppliers({ kind="global" }) {
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getTopSuppliers({ kind })
        .then(response => {
            console.log(response.data);
            setSuppliers(response.data);
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
        })
    }, [])

    const renderTitle = () => {
        if (kind==="global") {
            return "Κορυφαίοι προμηθευτές";
        }
        else {
            return "Εμπιστεύεστε συχνά";
        }
    }

    if (loading) {
        return (
            <Spinner />
        )
    }
    if (suppliers?.length) {
        return (
            <Grid container padding={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color={"black"}>
                        { renderTitle() }
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MyCarousel items={suppliers.map((supplier) => <Supplier supplier={supplier} />)} />
                </Grid>
            </Grid>
        )
    }
    return null;
}

export default TopSuppliers;