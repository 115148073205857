import React from "react";
import { useQueryClient } from "react-query";
import { queriesKeys } from "../api/queriesKeys";

export const useRefreshCatalog = () => {
  const queryClient = useQueryClient();
  const refreshCatalog = () => {
    queryClient.invalidateQueries(queriesKeys["getOneSupplierCatalog"]);
    queryClient.invalidateQueries(
      queriesKeys["getOneSupplierProductsFiltered"]
    );
    queryClient.invalidateQueries(queriesKeys["getOneSupplierOffers"]);
  };

  return { refreshCatalog };
};
