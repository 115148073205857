import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkLogged, selectAuth } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../General/Spinner";
import SideBar2 from "./SideBar2";

export const SuperUserLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isSupplier, token, logged, status } = useSelector(selectAuth);

  useEffect(() => {
    if (!logged) dispatch(checkLogged());
  }, [dispatch]);

  const renderContent = () => {
    if (status === "loading") {
      return <Spinner />;
    }
    if (user?.is_superuser) {
      return children;
    }
    navigate("/");
    return null;
  };

  return <SideBar2>{renderContent()}</SideBar2>;
};
