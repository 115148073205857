import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getNotificationsCall } from "../../api/suppliers"

const initialState = {
    notifications: [],
    notifications_status: "idle",
}

export const getNotifications = createAsyncThunk(
    'notifications/fetch',
    async () => {
        try {
            const response = await getNotificationsCall();
            return response.data;
        }
        catch (err) {
            return [];
        }
    }
)

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getNotifications.pending, (state) => {
                state.notifications_status = "loading";
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.notifications_status = "idle";
                state.notifications = action.payload;
            })
    }
});

export const selectNotifications = (state) => state.notifications;

export default notificationsSlice.reducer;