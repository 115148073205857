import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useIsMobile } from "../../hooks/useIsMobile";

const MyDrawer = ({
  children,
  open,
  position = "right",
  onClose,
  overflowY,
}) => {
  const { isMobile } = useIsMobile();
  const width = React.useMemo(() => {
    if (position === "top" || position === "bottom") {
      return "auto";
    }
    if (isMobile) {
      return "100vw";
    }
    return 400;
  }, [position, isMobile]);
  return (
    <Drawer anchor={position} open={open} onClose={onClose}>
      <Box
        sx={{
          width,
          padding: 2,
          ...(isMobile && { paddingRight: 0 }),
          overflowY: overflowY || "auto",
        }}
        role="presentation"
        onKeyDown={onClose}
      >
        {children}
      </Box>{" "}
    </Drawer>
  );
};

export default MyDrawer;
