import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  links: [],
  color: "white",
};

export const breadCrumbSlice = createSlice({
  name: "breadCrumb",
  initialState,
  reducers: {
    setLinks: (state, action) => {
      const { links, color } = action.payload;
      state.links = links;
      state.color = color;
    },
    clearLinks: (state, action) => {
      state.links = [];
      state.color = "white";
    },
  },
});

export const { setLinks, clearLinks } = breadCrumbSlice.actions;
export const selectBreadCrumb = (state) => state.breadCrumb;

export default breadCrumbSlice.reducer;
