import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import MyTextField from "../../../MyTextField";
import { Clear } from "@mui/icons-material";
import { TaxNumberOffersFormValidationSchema } from "./TaxNumberOffersFormValidationSchema";
import { getFieldErrorsForFieldArray } from "../../../../helpers/getFieldErrors";
import { useMutation } from "react-query";
import { createOneSupplierTaxNumberOffersCall } from "../../../../api/suppliers";
import { useDispatch } from "react-redux";
import { setSnackMessage } from "../../../../redux/slices/snackMessageSlice";
import { LoadingButton } from "@mui/lab";

export const TaxNumberOffersForm = ({
  supplier_id,
  taxNumberOffers,
  taxNumbers,
  disabled,
}) => {
  const dispatch = useDispatch();

  const createOneSupplierTaxNumberOffers = async (body) => {
    const response = await createOneSupplierTaxNumberOffersCall(
      supplier_id,
      body
    );
    return response.data;
  };

  const mutation = useMutation(createOneSupplierTaxNumberOffers, {
    onSuccess: (data) => {
      console.log("Successfully created customer offer:", data);
      dispatch(
        setSnackMessage({
          text: "Οι εκπτώσεις αποθηκεύτηκαν με επιτυχία",
          severity: "success",
        })
      );
    },
    onError: (error) => {
      console.error("Failed to create customer offer:", error);
      dispatch(
        setSnackMessage({
          text: "Δεν μπορέσαμε να αποθηκεύσουμε τις εκπτώσεις σας",
          severity: "error",
        })
      );
    },
  });

  const handleSubmit = (values) => {
    const withSupplier = values.taxNumberOffers.map((taxNumberOffer) => ({
      ...taxNumberOffer,
      offer_percentage: Number(taxNumberOffer.offer_percentage) / 100,
      supplier_id,
    }));
    const duplicateTaxNumbers = withSupplier
      .map((taxNumberOffer) => taxNumberOffer.tax_no)
      .filter((taxNo, index, array) => array.indexOf(taxNo) !== index);
    if (duplicateTaxNumbers.length) {
      dispatch(
        setSnackMessage({
          text: `Δεν μπορείτε να έχετε δύο εκπτώσεις για το ίδιο ΑΦΜ (${duplicateTaxNumbers.join(
            ", "
          )})`,
          severity: "error",
        })
      );
      return;
    }
    mutation.mutate(withSupplier);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ taxNumberOffers: taxNumberOffers.map(({ offer_percentage, ...rest }) => ({ offer_percentage: offer_percentage*100, ...rest }))}}
      onSubmit={handleSubmit}
      validationSchema={TaxNumberOffersFormValidationSchema}
    >
      {({ values, touched, errors }) => (
        <Form>
          <FieldArray name="taxNumberOffers">
            {({ push, remove }) => (
              <Stack spacing={0}>
                {!values.taxNumberOffers.length && (
                  <Alert severity="info">
                    Δεν έχετε προσθέσει εκπτώσεις για συγκεκριμένα ΑΦΜ
                  </Alert>
                )}
                {values.taxNumberOffers.map((taxNumberOffer, index) => (
                  <Grid container spacing={1} key={index}>
                    <Grid item md={6}>
                      <FormControl fullWidth disabled={disabled}>
                        <InputLabel>ΑΦΜ</InputLabel>
                        <Field
                          name={`taxNumberOffers.${index}.tax_no`}
                          as={Select}
                          label="ΑΦΜ"
                          fullWidth
                          select
                          variant="outlined"
                          size="small"
                          {...getFieldErrorsForFieldArray(
                            `taxNumberOffers`,
                            index,
                            "tax_no",
                            errors,
                            touched
                          )}
                        >
                          {taxNumbers.map((taxNumber) => (
                            <MenuItem key={taxNumber} value={taxNumber}>
                              {taxNumber}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <Field
                        name={`taxNumberOffers.${index}.offer_percentage`}
                        as={TextField}
                        label="Εκπτωση"
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          endAdornment: "%",
                        }}
                        {...getFieldErrorsForFieldArray(
                          `taxNumberOffers`,
                          index,
                          "offer_percentage",
                          errors,
                          touched
                        )}
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={() => remove(index)}
                        disabled={disabled}
                      >
                        <Clear />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Grid container justifyContent={"space-between"} marginTop={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => push({ tax_no: "", offer_percentage: "" })}
                      disabled={disabled}
                    >
                      Προσθηκη
                    </Button>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      loading={mutation.isLoading}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={disabled}
                    >
                      Αποθηκευση
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Stack>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};
