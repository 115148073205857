import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Avatar, CardHeader, Chip, Grid, IconButton, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Clear } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { interactive_add, interactive_subtract, remove_product } from '../../redux/slices/cartSlice';
import { makeStyles } from "@mui/styles";
import { setSnackMessage } from '../../redux/slices/snackMessageSlice';
import { formatCurrency, formatPrice } from '../../helpers/price';
import MyDivider from '../General/MyDivider';
import QuantityButtons from '../General/QuantityButtons';
import { updateCartTupleCall } from '../../api/superuser';
import { useQueryClient } from 'react-query';
import { queriesKeys } from '../../api/queriesKeys';
import { useIsMobile } from '../../hooks/useIsMobile';
import { setConfirmationDialog } from '../../redux/slices/confirmationDialogSlice';
import placeholderImage from "../../images/product_placeholder_image.png";
import { Price } from '../Cart/Price';

const useStyles = makeStyles((theme) => ({
    invisibleCell: {
        borderBottom: "none !important",
        padding: "0px 0px !important",
        height: 10,
    },
    invisibleRow: {
        height: 10
    }
  })
);


function ProductsTable({ products, disabled, isHistory, supplierView, totalCost, totalCostAfterOffer, offerPercentage, isSuperUser, deleteCartTuple }) {
    const queryClient = useQueryClient();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();
    const [subCost, setSubCost]  = useState(0.0);
    const [currency, setCurrency] = useState("euros");
    const [vats, setVats] = useState({});

    useEffect(() => {
        const sub_cost = products
            ?.map(product => product.cost)
            .reduce((a, b) => a+b, 0);
        setSubCost(sub_cost);
        const currency_ = products[0]?.product_info?.price_currency;
        setCurrency(currency_);
        const vats_ = groupByVat();
        console.log({ vats_ });
        setVats(vats_);
    }, [products])

    const preDeleteProduct = (id, cart_tuple_id) => {
        dispatch(setConfirmationDialog({
            title: `Είσαι σίγουρος ότι θες να διαγράψεις το προϊόν ${!isSuperUser ? 'από το καλάθι σου' : ''};`,
            onConfirm: () => removeProduct(id, cart_tuple_id),
        }))
    }
    const removeProduct = (id, cart_tuple_id) => {
        if (isSuperUser) {
            if (products && products.length>1) {
                deleteCartTuple(cart_tuple_id);
            }
            else {
                dispatch(setSnackMessage({
                    text: "A cart cannot be empty. If you want it to be empty, just deactivate it",
                    severity: "warning",
                }))
            }
        }
        else {
            dispatch(remove_product({ product_id: id }));
        }
    }

    const renderProductImage = ({ image, name }) => {
        return (
            <Avatar
                src={image || placeholderImage}
                alt="product"
                sx={{ height: 30, width: 30, marginRight: 1 }}
            />
        )
    }

    const updateCartTuple = ({ id, quantity }) => {
        console.log("superuser updating the order ", id);
        console.log("to the quantity", quantity);
        updateCartTupleCall({ id, quantity })
        .then(response => {
            dispatch(setSnackMessage({
                text: "Updated successfully",
                severity: "info",
            }))
            console.log(response);
            queryClient.invalidateQueries(queriesKeys['getOneCart']);
        })
        .catch(err => {
            dispatch(setSnackMessage({
                text: err.response.status===400 ? err.response?.data : "We could not update the entry",
                severity: "error",
            }))
        })
    }

    const add = (product) => {
        const { product_info, quantity, cart_tuple_id } = product;
        const { id, max_quantity, package_quantity } = product_info;
        
        // update call should happend immediately
        // package_quantiy skipped
        if (isSuperUser) {
            updateCartTuple({
                id: cart_tuple_id,
                quantity: quantity+1 // not + package_quantity
            });
        }
        // all validations take place normally
        else {
            const new_quantity = quantity + package_quantity
            if (new_quantity > max_quantity) {
                dispatch(setSnackMessage({
                    text: `Η μέγιστη δυνατή παραγγελία για το προϊόν είναι ${max_quantity} μονάδες`,
                }));
                return;
            }
            else {
                const payload = {
                    product_id: id,
                };
                dispatch(interactive_add(payload));
            }
        }
    }

    const subtract = (product) => {
        const { product_info, quantity, cart_tuple_id } = product;
        const { id, min_quantity, package_quantity } = product_info;
        // update call should happend immediately
        if (isSuperUser) {
            if (quantity===1) return;
            updateCartTuple({
                id: cart_tuple_id,
                quantity: quantity-1,
            });
        }
        else {
            const new_quantity = quantity - package_quantity;
            console.log(product, product_info);
            if (new_quantity<min_quantity) {
                dispatch(setSnackMessage({
                    text: `Η ελάχιστη δυνατή παραγγελία για το προϊόν είναι ${min_quantity} μονάδες`,
                }));
                return;
            }
            else {
                const payload = {
                    product_id: id,
                };
                dispatch(interactive_subtract(payload));
            }
        }
    }

    const renderProductActions = (product) => {
        const { quantity } = product;
        if ((!disabled && !supplierView && !isHistory) || isSuperUser) {
            return (
                <Grid container justifyContent={isMobile ? "center" : "flex-end"}>
                    <Grid item>
                    <QuantityButtons
                        onAdd={()=>add(product)}
                        onSubtract={()=>subtract(product)}
                        quantity={quantity}
                        size={"medium"}
                        justifyContent={isMobile ? "center" : "flex-end"}
                    />
                    </Grid>
                </Grid>

            )
        }
        return (
            <Typography variant={'subtitle2'} style={{ marginTop: "0px", padding: "0px 5px" }}>
                {quantity}
            </Typography>
        )
    }

    const renderPrice = ({ price, price_currency, costCase, cost, normal_cost, quantity }) => {
        if (costCase==="normal") {
            // console.log({ normal_cost, quantity, cost, price })
            return (
                <Grid container alignItems={'center'} justifyContent='flex-end'>
                    <Grid item>
                        <Typography variant="body2">
                            {formatPrice(price)}
                            {formatCurrency(price_currency)}
                        </Typography>
                    </Grid>
                    <Grid item paddingLeft={1}>
                        <Typography variant="caption">
                            χωρίς ΦΠΑ
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
        else {
            const normal_price = Math.round(normal_cost/quantity*100)/100;
            return (
                <Grid container alignItems={'center'} justifyContent='flex-end' spacing={1}>
                    {!isNaN(normal_price) &&
                        <Grid item>
                            <Typography variant="body2" color="GrayText" sx={{ textDecoration: "line-through" }}>
                                {formatPrice(normal_price)}
                                {formatCurrency(price_currency)}
                            </Typography>
                        </Grid>
                    }
                    <Grid item>
                        <Typography variant="body2">
                            {formatPrice(price)}
                            {formatCurrency(price_currency)}
                        </Typography>
                    </Grid>
                    <Grid item paddingLeft={1}>
                        <Typography variant="caption">
                            χωρίς ΦΠΑ
                        </Typography>
                    </Grid>
                </Grid>
            )

        }
    }

    const renderSimpleCost = ({ cost, price_currency }) => {
        return (
            <Grid container alignItems={'center'} justifyContent='flex-end'>
                <Grid item fontWeight={'bold'} fontSize='medium'>
                    {formatPrice(cost)}
                    {formatCurrency(price_currency)}
                </Grid>
            </Grid>
        )
    }

    const renderFinalCost = ({ cost, cost_after_offer, offer_percentage, price_currency }) => {
        return (
            <Grid container alignItems={'center'} justifyContent='flex-end'>
                {!!offer_percentage &&
                    <Price price={cost_after_offer} currency={price_currency} /> 
                }
                <Price price={cost} currency={price_currency} strike={!!offer_percentage} />
            </Grid>
        )
    }

    const renderSpaceBetweenLine = (left, right) => {
        return (
            <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        {left}
                    </Grid>
                    <Grid item>
                        { right }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderRemoveButton = (id, cart_tuple_id) => {
        return (
            <IconButton
                onClick={()=>
                    preDeleteProduct(id, cart_tuple_id)
                }
                size={"medium"}
                sx={{ padding: "4px" }}>
                <Clear />
            </IconButton>
        )
    }

    const renderMobileHeader = ({ id, name, image, cart_tuple_id }) => {
        return (
            <CardHeader
                avatar={ renderProductImage({ image, name }) }
                action={ !supplierView && !disabled && !isHistory && renderRemoveButton(id, cart_tuple_id) }
                title={name}
            />
        )
    }

    const renderOneProduct = (product) => {
        const { product_info, cost, costCase, normal_cost, quantity, cart_tuple_id } = product;
        const { id, name, price, price_currency, image, vat_percentage } = product_info;

        if (isMobile) {
            return (
                <Paper elevation={1} sx={{ width: 1, marginBottom: 1 }} disabled={disabled}>
                    <Grid container justifyContent={'center'} padding={0.5}>
                        <Grid item xs={12}>
                            { renderMobileHeader({ id, name, image, cart_tuple_id })}
                        </Grid>
                        <MyDivider marginBottom={1} />
                        { renderSpaceBetweenLine("Τιμή μονάδας", renderPrice({ price, price_currency, costCase, cost, normal_cost, quantity }))}
                        { renderSpaceBetweenLine("ΦΠΑ",  `${vat_percentage*100}%`, 0)}
                        <MyDivider marginBottom={1} />
                        <Grid item xs={12} paddingTop={1} paddingBottom={1}>
                            { renderSpaceBetweenLine("Ποσότητα",  renderProductActions(product))}
                        </Grid>
                        <MyDivider marginBottom={1} />
                        <Grid item xs={12}>
                            { renderSpaceBetweenLine("Σύνολο", renderSimpleCost({ cost, price_currency }))}
                        </Grid>
                    </Grid>
                </Paper>
            )
        }

        return (
            <TableRow
                key={id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="right" width={"10px"} sx={{ padding: 0 }}>
                    { ((!disabled && !supplierView  && !isHistory) || isSuperUser) && renderRemoveButton(id, cart_tuple_id) }
                </TableCell>
                <TableCell align="right">
                    <Grid container alignItems={'center'}>
                        { renderProductImage({ image , name }) }
                        {name}
                    </Grid>
                    </TableCell>
                <TableCell align="right">
                    { renderPrice({ price, price_currency, costCase, cost, normal_cost, quantity })}
                </TableCell>
                <TableCell align="right">
                    {vat_percentage*100}%
                </TableCell>
                <TableCell align="right">
                        { renderProductActions(product) }
                </TableCell>
                <TableCell align="right">
                        { renderSimpleCost({ cost, price_currency }) }
                </TableCell>

            </TableRow>
        )
    }

    const groupByVat = () => {
        // console.log({ products });
        const vat_extra_costs = {};
        let index = 0;
        if (products) {
            for (let i=0; i<products.length; i++) {
                let product = products[i];
                console.log("----------------------")
                console.log({ index })
                index += 1;
                console.log({ vat_extra_costs });
                console.log({ product_info: product.product_info });
                const { vat_percentage } = product.product_info;
                console.log(vat_percentage);
                const { cost } = product;
                const extra_cost = Math.round(vat_percentage*cost*100)/100
                if (extra_cost) {
                    if (vat_extra_costs[vat_percentage*100]) vat_extra_costs[vat_percentage*100] += extra_cost;
                    else vat_extra_costs[vat_percentage*100] = extra_cost;
                }
            }
        }
        return vat_extra_costs;
    }

    const renderTwoColumnsRow = ({ key, col1, col2 }) => {
        return (
            <TableRow
                key={key}
                className={classes.invisibleRow}
            >
                <TableCell align="right" className={classes.invisibleCell}></TableCell>
                <TableCell align="right" className={classes.invisibleCell}></TableCell>
                <TableCell align="right" className={classes.invisibleCell}></TableCell>
                <TableCell align="right" className={classes.invisibleCell}></TableCell>
                <TableCell align="right" className={classes.invisibleCell}>
                    { col1 }
                </TableCell>
                <TableCell sx={{ border: "none" }} align="right">
                    { col2 }
                </TableCell>
            </TableRow>
        )
    }

    const renderVatRows = () => {
        if (isMobile) {
            return (
                <>
                    {Object.entries(vats).map(([vat, cost]) => {
                        return (
                            renderSpaceBetweenLine(`ΦΠΑ ${vat}%`, renderSimpleCost({ cost, price_currency: currency }))
                        )
                    })}
                </>
            )
        }

        return (
            <>
                {Object.entries(vats).map(([vat, cost]) => {
                    const column1 = (
                        <Typography variant="caption">
                            ΦΠΑ {vat}%
                        </Typography>
                    );
                    const column2 = (
                        renderSimpleCost({ cost, price_currency: currency })
                    );
                    return (
                        renderTwoColumnsRow({
                            key: vat,
                            col1: column1,
                            col2: column2,
                        })
                    )
                })}
            </>
        )
    }

    const renderSubCostRow = () => {
        if (isMobile) {
            return(
                renderSpaceBetweenLine("Υποσύνολο", renderSimpleCost({ cost: subCost, price_currency: currency}) )
            )
        }
        const column1 = (
            <Typography variant="caption">
                Υποσύνολο
            </Typography>
        );
        const column2 = (
            renderSimpleCost({ cost: subCost, price_currency: currency})
        );
        return (
            renderTwoColumnsRow({
                key: 0,
                col1: column1,
                col2: column2,
            })
        )
    }

    const renderTotalCostRow = () => {
        if (isNaN(totalCost)) return null;
        const column1 = (
            <Typography variant="caption">
                Σύνολο
            </Typography>
        );
        const column2 = renderFinalCost({
            cost: totalCost,
            cost_after_offer: totalCostAfterOffer,
            offer_percentage: offerPercentage,
            price_currency: currency
        });
        if (isMobile)
            return renderSpaceBetweenLine("Σύνολο", column2);
        return (
            renderTwoColumnsRow({
                key: 10000,
                col1: column1,
                col2: column2,
            })
        )
    }

    if (isMobile) {
        return (
            <>
                <Grid container>
                    {products.map((product) => 
                        renderOneProduct(product)    
                    )}
                    <Paper elevation={0} sx={{ width: 1, padding: 1 }}>
                        { renderSubCostRow() }
                        { renderVatRows() }
                        {/* { renderTotalCostRow() } */}
                    </Paper>
                </Grid>
            </>
        )
    }
    return (
        <>
            <TableContainer sx={{ opacity: disabled ? 0.5 : 1 }} component={Paper} elevation={0}>
                <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableCell align="right"></TableCell>
                        <TableCell width={300}>Προϊόν</TableCell>
                        <TableCell align="right">Τιμή μονάδας</TableCell>
                        <TableCell align="right">ΦΠΑ</TableCell>
                        <TableCell align="right">Ποσότητα</TableCell>
                        <TableCell align="right">Συνολική τιμή</TableCell>
                    </TableHead>
                    <TableBody>
                        {products.map((product) => (
                            renderOneProduct(product)
                        ))}
                        { renderSubCostRow() }
                        { renderVatRows() }
                        { renderTotalCostRow() }
                    </TableBody>
                </Table>
            </TableContainer>
            {!!offerPercentage && (
                <Alert severity='info' sx={{ width: "fit-content" }}>
                    Αποκλειστική έκπτωση {(offerPercentage*100).toFixed(2)} % στο σύνολο της παραγγελίας
                </Alert>
            )}
        </>
    );
}

export default ProductsTable;