import { Close, ExpandMore, Print } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Badge, Button, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MarkNotificationAsSeenCall, updateCartDeliveryDate } from '../../../../api/suppliers';
import { formatCurrency, formatPrice } from '../../../../helpers/price';
import { getNotifications } from '../../../../redux/slices/notificationsSlice';
import MyDivider from '../../../General/MyDivider';
import ProductsTable from '../../../OderConfirmation/ProductsTable';
import AvailabilityDaysTimes from '../../../Profile/AvailabilityDaysTimes';
import StoreInfoTable from './StoreInfoTable';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { StaticDatePicker } from '@mui/lab';
import { setSnackMessage } from '../../../../redux/slices/snackMessageSlice';
import { useDidMountEffect } from '../../../../hooks/useDidMountEffect';
import locale from 'date-fns/locale/en-US'
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { zIndexes } from '../../../../zIndexes';
import html2pdf from 'html2pdf.js';
import { OrderAgainDialog } from '../../../OrderAgain/OrderAgainDialog';

if (locale && locale.options) {
    locale.options.weekStartsOn = 1;
}
function OneOrder({ order, customerView=false, isSuperUser=false, fullPage=false, initExpanded=false, deleteCartTuple }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isFocused, setIsFocused] = useState(false);
    const [isOrderAgainDialogOpen, setIsOrderAgainDialogOpen] = useState(false);
    const { isMobile } = useIsMobile();
    const {
        id,
        tuples,
        datetime,
        customer,
        seen,
        cost_with_vat,
        cost_without_vat,
        cost_currency,
        tax_number_offer_percentage,
        cost_after_tax_number_offer_with_vat,
        cost_after_tax_number_offer_without_vat,
        comment,
        estimated_delivery_date,
        is_cancelled,
        supplier,
    } = order;
    const [products, setProducts] = useState([]);
    const [expanded, setExpanded] = useState(initExpanded);
    const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState(null);
    const [dirty, setDirty] = useState(false);
    const [customerDays, setCustomerDays] = useState([]);
    const [showingCalendar, setShowingCalendar] = useState(false);
    // const supplier = tuples ? tuples[0]?.supplier : null;

    const [firstRender, setFirstRender] = useState(true);

    useDidMountEffect(() => {
        setFirstRender(false);
    })

    const markAsSeen = () => {
        if (seen) {
            return;
        }
        MarkNotificationAsSeenCall(id)
            .then(response => {
                console.log(response);
                dispatch(getNotifications());
            })
            .catch(err => {
                console.log(err);
                dispatch(getNotifications());
        })
    }

    /*  populate the products array to be in the same form as in the `Cart.jsx` etc files
        so that the products table component can be used again just the way it is
    */

    useEffect(() => {
        setEstimatedDeliveryDate(estimated_delivery_date ? new Date(estimated_delivery_date) : null);
    }, [estimated_delivery_date])

    useEffect(() => {
        const products_ = [];
        if (tuples) {
            tuples.forEach((tuple) => {
                const {
                    cost_without_vat,
                    quantity,
                    product,
                    c_product_name,
                    c_product_price,
                    c_product_vat,
                    cost_currency,
                    id,
                } = tuple;
                // if (customerView) total_cost_ += cost_with_vat;
                // else total_cost_ += cost_without_vat;
                let product_info = {
                    id: product?.id || 0,
                    image: product?.image || null,
                    name: c_product_name,
                    price: c_product_price,
                    vat_percentage: c_product_vat,
                    price_currency: cost_currency,
                    package_quantity: product?.package_quantity || 1,
                    min_quantity: product?.min_quantity || 1,
                    max_quantity: product?.max_quantity,
                }
                products_.push({
                    cost: cost_without_vat,
                    quantity,
                    product_info,
                    cart_tuple_id: id,
                })
            })
            setProducts(products_);
        }
    }, [tuples])

    useEffect(() => {
        if (!seen && !customerView && fullPage && !isSuperUser) {
            markAsSeen();
        }
    }, [])

    useEffect(() => {
        if (!isSuperUser) setIsFocused(searchParams.get("order")==order?.id && !customerView);
    }, [order, searchParams])

    useEffect(() => {
        if (isFocused) {
            console.log(`${id} is focused`)
            const elem = document.getElementById(`order-${id}`);
            console.log(elem);
            if (elem) {
                elem.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                setTimeout(() => {
                    window.scrollBy(0,-100)
                }, 1000);
            }
        }
    }, [isFocused])

    // useEffect(() => {
    //     console.log(id, Boolean(estimated_delivery_date), dirty, "changed to ", estimatedDeliveryDate);
    // }, [estimatedDeliveryDate])

    useEffect(() => {
        if (!customer) return;
        const { availability_days_times } = customer;
        if (!availability_days_times?.length) {
            setCustomerDays([0, 1, 2, 3, 4, 5, 6]);
        }
        else {
            setCustomerDays(availability_days_times.map(({ day }) => day));
        }
    }, [customer])

    const getAvatar = (who="customer") => {
        if (who==="customer") {
            const { name, owner: { id, is_deleted } } = customer;
            let display_name = name;
            if (is_deleted) {
                display_name = `Διαγεγραμμένος χρήστης ${id}`;
            }
            return (
                <Avatar
                    sx={{ bgcolor: blue[300], height: 25, width: 25 }}
                >
                    {display_name.slice(0, 1)}
                </Avatar>
            )
        }
        if (supplier) {
            const { name, logo, owner: { is_deleted } } = supplier;
            let display_name = name;
            if (is_deleted) {
                display_name = `Διαγεγραμμένος χρήστης`;
            }
            if (logo) {
                return (
                    <Avatar
                        sx={{ height: 25, width: 25 }}
                        src={logo}
                        alt="profile" />
                )
            }
            return (
                <Avatar
                    style={{ zIndex: zIndexes.supplierAvatar }}
                    sx={{ bgcolor: blue[300], height: 25, width: 25 }}
                >
                    {display_name?.slice(0, 1)}
                </Avatar>
            )
        }
    }

    const formatDatetime = () => {
        const d = moment(datetime).format("DD-MM-YYYY hh:mm");
        return d;
    }

    const renderPrice = (price, currency, strike=false) => {
        return (
            <Typography
                fontWeight={strike ? "normal" : 'bold'}
                fontSize='medium'
                sx={{ paddingLeft: 3, paddingTop: "4px", textDecoration: strike ? "line-through" : "auto" }}
            >
                {formatPrice(price)}
                {formatCurrency(currency)}
            </Typography>
        )
    }

    const renderSimpleCost = () => {
        let cost = cost_without_vat;
        let after_offer_cost = cost_after_tax_number_offer_without_vat;
        if (customerView) {
            cost = cost_with_vat;
            after_offer_cost = cost_after_tax_number_offer_with_vat;
        }
        return (
            <Grid container spacing={2} alignItems="center" width={130}>
                <Grid item md={6}>
                    {renderPrice(after_offer_cost, cost_currency)}
                </Grid>
                {tax_number_offer_percentage > 0 && (
                    <Grid item md={6}>
                        {renderPrice(cost, cost_currency, true)}
                    </Grid>
                )}
            </Grid>
        )
    }

    const renderSimpleCostAndPrint = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item md={6} xs={12}>
                    { renderSimpleCost() }
                </Grid>
                <Grid item md={6} xs={12}>
                    { renderPrint() }
                </Grid>
            </Grid>
        )
    }

    const renderTitle = (name) => {
        let content = (
            <Typography variant="body2">
                {name}
            </Typography>
        )
        if (!customerView && !seen) {
            content = (
                <Badge badgeContent={"Νεα"} color="error">
                    <Typography sx={{ paddingRight: 2 }} variant="body2">
                        {name}
                    </Typography>
                </Badge>
            )
        }
        return (
            content
        )
    }

    const handlePrintCore = () => {
        // create a PDF document with the component of the order and download it to the user's computer
        const input = document.getElementById(`order-${id}`);
        html2pdf()
            .set({
                margin: 0,
                filename: `order-${id}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, letterRendering: true },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
            })
            .from(input)
            .save()
    }

    const handlePrint = (e) => {
        e.stopPropagation();
        if (!expanded)
            setExpanded(true);
        setTimeout(() => {
            handlePrintCore();
        }, 1000);
    };

    const renderPrint = () => {
        return (
            <Grid container alignItems="center">
                <Button
                    onClick={handlePrint}
                    startIcon={<Print />}
                    sx={{ padding: 0, textTransform: "none" }}
                    color="primary"
                >
                    Λήψη PDF
                </Button>
            </Grid>
        )
    }

    const renderHeader = () => {
        if (customerView && supplier){
            const { name, owner: { is_deleted } } = supplier;
            return (
                <Grid container alignContent="center" alignItems="flex-start">
                    <Grid item md={3}>
                        <CardHeader
                            avatar={ getAvatar("supplier") }
                            action={ renderSimpleCost() }
                            title={ is_deleted ? `Διαγεγραμμένος χρήστης` : name }
                            subheader={formatDatetime()}
                        />
                    </Grid>
                    <Grid item md={2} xs={12} paddingTop={isMobile ? 0 : 2}>
                        <Grid container justifyContent={"flex-end"}>
                            { renderState() }
                        </Grid>
                    </Grid>
                </Grid>

            )
        }
        const { name, owner: { is_deleted } } = customer;
        return (
            <Grid container alignContent="center" alignItems="flex-start">
                <Grid item sx={{ width: "100%" }}>
                    <CardHeader
                        avatar={ getAvatar("customer") }
                        action={ renderSimpleCostAndPrint() }
                        title={ renderTitle(is_deleted ? `Διαγεγραμμένος χρήστης` : name) }
                        subheader={formatDatetime()}    
                        // set the action width to 40% of the card width
                        sx={{ width: "100%", ".MuiCardHeader-action": { width: "40%" } }}
                    />
                </Grid>
                {is_cancelled &&
                    <Grid item md={"auto"} xs={12} paddingTop={isMobile ? 0 : 2}>
                        <Grid item md={"auto"} xs={12} paddingTop={isMobile ? 0 : 2}>
                            <Grid container justifyContent={"flex-end"}>
                                { renderState() }
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        )
    }

    const goToSupplier = (id) => {
        // navigate(`/suppliers/${id}`);
        window.location.href = `/suppliers/${id}`;
    }

    const goToOrder = (id) => {
        if (customerView) {
            navigate(`/account?page=order&key=${id}`);
        }
        else {
            navigate(`/admin?page=order&key=${id}`);
        }
    }

    const updateDeliveryDate = (date) => {
        if (firstRender) {
            return;
        }
        updateCartDeliveryDate({ id, date: moment(date).format("YYYY-MM-DD") })
        .then(response => {
            setDirty(true);
            console.log(response.data);
            dispatch(setSnackMessage({
                text: "Θα ενημερώσουμε την επιχείρηση για την ημερομηνία παράδοσης",
                severity: "info",
            }));
            console.log("updating", id, "to", date);
            setEstimatedDeliveryDate(date);
        })
        .catch(err => {
            setDirty(true);
            console.log(err);
            dispatch(setSnackMessage({
                text: "Συγγνώμη, δεν μπορέσαμε να αποθηκεύσουμε την ημερομηνία παράδοσης",
                severity: "warning",
            }))
        })
    }

    const OrderFullPageButton = () => {
        return (
            <Button
                variant='outlined'
                fullWidth={isMobile}
                sx={{ fontSize: isMobile ? 'small' : 'small' }}
                onClick={()=>goToOrder(id)}
            >
                Λεπτομερειες παραγγελιας
            </Button>
        )
    }

    const MoreBySupplierButton = () => {
        const { id, name } = supplier;
        return (
            <Button
                variant='outlined'
                fullWidth={isMobile}
                sx={{ fontSize: isMobile ? 'small' : 'small' }}
                onClick={()=>goToSupplier(id)}
            >
                Περισσοτερα απo {name}
            </Button>
        )
    }

    const OrderAgainButton = () => {
        if (customerView) {
            return (
                <Button
                    variant='contained'
                    fullWidth={isMobile}
                    sx={{ fontSize: isMobile ? 'small' : 'small' }}
                    onClick={() => setIsOrderAgainDialogOpen(true)}
                >
                    Παραγγειλτε ξανα
                </Button>
            )
        }
        return null;
    }

    const renderMoreButtons = () => {
        if (customerView) {
            const { owner: { is_deleted } } = supplier;
            return (
                <Grid item>
                    <Grid container spacing={1}>
                        {!is_deleted &&
                            <Grid item>
                                { MoreBySupplierButton() }
                            </Grid>
                        }
                        {!fullPage &&
                            <Grid item>
                                { OrderFullPageButton() }
                            </Grid>
                        }
                        <Grid item>
                            { OrderAgainButton() }
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        else {
            return (
                <>
                    <Grid item>
                        { OrderFullPageButton() }
                    </Grid>
                    <Grid item>
                        { OrderAgainButton() }
                    </Grid>
                </>
            )
        }
    }

    const renderActions = () => {
        if (customerView || !fullPage) {
            if (isMobile) {
                return (
                    <Grid container rowSpacing={1}>
                        { renderMoreButtons() }
                    </Grid>
                )
            }
            return (
                <Grid container justifyContent={"space-between"}>
                    { renderMoreButtons() }
                </Grid>
            )
        }
        return null;
    }

    const renderAvailability = ({ man }) => {
        if (fullPage) {
            return (
                <Grid item lg={6} md={6} sm={10} xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2" fontWeight={"bold"}>
                                {customerView ? "Ημέρες-περιοχές παραδόσεων" : "Ωράριο λειτουργίας"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AvailabilityDaysTimes
                                availability_details={man?.availability_days_times}
                                isSupplier={!customerView}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            )
        }
        return null;
    }

    const renderGeneralInfo = ({ man }) => {
        if (fullPage) {
            return(
                <Grid item lg={6} md={6} sm={10} xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2" fontWeight={"bold"}>
                                Γενικά στοιχεία
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <StoreInfoTable store={man} isSupplier={!customerView} />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        return null;
    }

    const renderExtraInfo = () => {
        if (!fullPage) return null;
        let man = customerView ? supplier : customer;
        if (man?.owner?.is_deleted) return null;
        return (
            <Grid container padding={1} spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={"bold"}>
                        Πληροφορίες {customerView ? "προμηθευτή" : "επιχείρησης"}
                    </Typography>
                </Grid>
                <MyDivider />
                { renderGeneralInfo({ man }) }
                { renderAvailability({ man }) }
            </Grid>
        )
    }

    const renderComment = () => {
        if (comment) {
            return (
                <TextField
                    id="order-comment"
                    label="Σχόλια επιχείρησης"
                    multiline
                    minRows={3}
                    maxRows={5}
                    fullWidth
                    value={comment}
                    disabled
                    sx={{ color: "black" }}
                />
            )
        }
    }

    const renderState = () => {
        let content = "Μη αναγνωσμένη";
        let severity = "info";
        console.log({ is_cancelled });
        if (is_cancelled) {
            content = "Έχει ακυρωθεί";
            severity = "error";
        }
        else if (estimated_delivery_date) {
            content = `Παράδοση: ${moment(estimated_delivery_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`
            severity = moment(estimated_delivery_date, "YYYY-MM-DD")<=moment() ? "default" : "success";
        }
        else if (seen) {
            content = "Αναγνωσμένη"
        }
        return (
                <Chip size="small" color={severity} label={content} />
        )
    }

    function disableDays(date) {
        const day = date.getDay();
        const dayIndex = day===0 ? 6 : day-1;
        return !customerDays.includes(dayIndex);
    }

    const renderUpdateStateCalendar = () => {
        if (isSuperUser) return null;
        let content = "Εκτιμώμενη ημέρα παράδοσης της παραγγελίας";
        let severity = "info";
        // console.log({ customerView, deleted: customer?.is_deleted });
        if (!customerView && customer) {
            const { owner: { is_deleted } } = customer;
            if (is_deleted) return null;
        }
        if (!estimated_delivery_date && !dirty) {
            content = "Δηλώστε την εκτιμώμενη ημέρα παράδοσης της παραγγελίας";
            severity = "warning";
        }
        if (!showingCalendar) {
            if (!!estimatedDeliveryDate) {
                return (
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography variant="body2" fontWeight={"bold"}>
                                Επιλεγμένη Ημέρα Παράδοσης
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => setShowingCalendar(true)} sx={{ textDecoration: "underline" }}>
                                {moment(estimatedDeliveryDate).format("DD/MM/YYYY")}
                            </Button>
                        </Grid>
                    </Grid>
                )
            }
            return (
                <Grid container justifyContent="center">
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={() => setShowingCalendar(true)}
                            sx={{ textTransform: "none" }}
                        >
                            Αποδοχή Παραγγελίας
                        </Button>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Dialog open onClose={() => setShowingCalendar(false)}>
                <DialogTitle>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">
                                Αποδοχή Παραγγελίας
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => setShowingCalendar(false)}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent={"center"}>
                        <Grid item sx={{ maxWidth: "400px !important"}}>
                            <Alert severity={severity}>
                                { content }
                            </Alert>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                <StaticDatePicker
                                    value={estimatedDeliveryDate}
                                    defaultValue={null}
                                    displayStaticWrapperAs="desktop"
                                    openTo="day"
                                    views={["day"]}
                                    maxDate={moment().add(1, 'M').toDate()}
                                    minDate={moment().toDate()}
                                    shouldDisableDate={disableDays}
                                    onChange={(date) => {
                                        updateDeliveryDate(date);
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} />
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="medium"
                        onClick={() => setShowingCalendar(false)}
                        sx={{ textTransform: "none" }}
                    >
                        Κλείσιμο
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderCalendarComment = () => {
        if (customerView) {
            return (
                <Grid item xs={12}>
                    { renderComment() }
                </Grid>
            )
        }
        if (comment) {
            return (
                <Grid item xs={12}>
                    <Grid container spacing={1} padding={1}>
                        <Grid item md={6} xs={12}>
                            { renderUpdateStateCalendar() }
                        </Grid>
                        <Grid item md={6} xs={12}>
                            { renderComment() }
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid item xs={12}>
                { renderUpdateStateCalendar() }
            </Grid>
        )
    }
    
    return (
        <Paper id={`order-${id}`} sx={{ ...(!customerView && { bgcolor: isFocused ? `${blue[100]} !important` : "inherit" }) }}>
            <Grid item xs={12}>
                <Accordion expanded={expanded} onChange={()=>{setExpanded(!expanded); markAsSeen(); }} sx={{ ...(customerView && { bgcolor: "inherit" }) }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        { customer && renderHeader() }
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "4px !important"}}>
                        <Grid container rowSpacing={2} >
                            <Grid item xs={12} marginBottom={isMobile ? 1 : 0 }>
                                <ProductsTable
                                    products={products}
                                    supplierView={!customerView}
                                    isHistory
                                    isSuperUser={isSuperUser}
                                    deleteCartTuple={deleteCartTuple}
                                    // disabled
                                    totalCost={Math.round(cost_with_vat*100)/100}
                                    totalCostAfterOffer={cost_after_tax_number_offer_with_vat}
                                    offerPercentage={tax_number_offer_percentage}
                                />
                            </Grid>
                            { renderCalendarComment() }
                            {/* {fullPage && customerView &&
                                <Grid item xs={12}>
                                    { renderState() }
                                </Grid>
                            } */}
                            <Grid item xs={12}>
                                { renderExtraInfo() }
                            </Grid>
                            <Grid item xs={12}>
                                { renderActions() }
                            </Grid>
                            {/* {!fullPage && customerView &&
                                <Grid item xs={12}>
                                    { renderState() }
                                </Grid>
                            } */}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {isOrderAgainDialogOpen && (
                <OrderAgainDialog
                    cart_id={order.id}
                    handleClose={() => setIsOrderAgainDialogOpen(false)}
                />
            )}
        </Paper>
    )
}

export default OneOrder;