import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./slices/authSlice";
import CategoriesReducer from "./slices/categoriesSlice";
import SuppliersSlice from "./slices/suppliersSlice";
import oneSupplierSlice from "./slices/oneSupplierSlice";
import ProductsReducer from "./slices/productsSlice";
import OneProductSlice from "./slices/oneProductSlice";
import CartSlice from "./slices/cartSlice";
import SnackMessageSlice from "./slices/snackMessageSlice";
import NotificationsSlice from "./slices/notificationsSlice";
import ConfirmationDialogSlice from "./slices/confirmationDialogSlice";
import BreadCrumbSlice from "./slices/breadCrumbSlice";
import FavouritesSlice from "./slices/favouritesSlice";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    categories: CategoriesReducer,
    suppliers: SuppliersSlice,
    oneSupplier: oneSupplierSlice,
    products: ProductsReducer,
    oneProduct: OneProductSlice,
    cart: CartSlice,
    snackMessage: SnackMessageSlice,
    notifications: NotificationsSlice,
    confirmationDialog: ConfirmationDialogSlice,
    breadCrumb: BreadCrumbSlice,
    favourites: FavouritesSlice,
  },
});
