import {
  Grid,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

export const SubCategoryRow = ({
  id,
  name,
  products_count,
  isSelected,
  chooseSubCat,
}) => {
  if (!id) return null;
  return (
    <Grid item key={id} xs={12}>
      <ListItemButton
        sx={{ pl: 4, width: 1 }}
        key={id}
        onClick={chooseSubCat}
        selected={isSelected}
      >
        <ListItemText
          primary={
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="body1">{name}</Typography>
              </Grid>
              {!!products_count && (
                <Grid item sx={{ paddingTop: "5px !important" }}>
                  <Typography
                    variant="caption"
                    sx={{ color: `grey !important` }}
                  >
                    ({products_count})
                  </Typography>
                </Grid>
              )}
            </Grid>
          }
        />
      </ListItemButton>
    </Grid>
  );
};
