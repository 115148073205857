import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, selectAuth } from "../../redux/slices/authSlice";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Field, Form } from "formik";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { LoginValidationSchema } from "./LoginRegisterValidationSchema";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  myLink: {
    fontSize: "1.1em",
    textTransform: "none !important",
  },
  container: {
    textAlign: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    justifyContent: "center",
  },
}));

function LoginForm({ showMessages }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [rememberMe, setRememberMe] = useState(true);

  const submit = (values) => {
    console.log({ rememberMe });
    dispatch(login({ data: values, rememberMe }));
  };

  const [showingMsg, setShowingMsg] = useState(false);

  const auth = useSelector(selectAuth);
  const { logged, status, msg, result, token } = auth;

  const getFieldErrors = (name, errors, touched) => {
    if (Object.keys(errors).length) {
      const error = touched[name] && errors[name]?.length > 0;
      const helperText = error ? errors[name] : " ";
      return { error, helperText };
    }
    return { error: false, helperText: " " };
  };

  useEffect(() => {
    setShowingMsg(status === "idle" && msg.length > 0);
    if (logged) {
      setTimeout(() => {
        const decoded = jwt_decode(token);
        console.log({ decoded });
        console.log({ isSupplier: decoded.isSupplier });
        const link = decoded.isSupplier ? "/admin" : "/";
        console.log({ link });
        window.location.href = link;
      }, 500);
      console.log(auth);
    }
  }, [auth]);

  const initialValues = {
    email: "",
    password: "",
  };

  const renderWelcome = () => {
    return <h3>Σύνδεση</h3>;
  };

  const renderChoice = () => {
    return (
      <Grid container marginTop={2}>
        <Grid item xs={12}>
          <Grid container justifyContent={"center"}>
            <Button
              sx={{ textDecoration: "underline" }}
              className={classes.myLink}
              onClick={() => navigate("/password-reset")}
            >
              Ξεχάσατε τον κωδικο σας;
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"space-between"}>
            <Grid padding={0} item md={6} sm={6} xs={12}>
              <Button
                sx={{ textDecoration: "underline" }}
                className={classes.myLink}
                onClick={() => (window.location.href = "/customers/register")}
              >
                Θέλω να ανοίξω λογαριασμό
              </Button>
            </Grid>
            <Grid padding={0} item md={6} sm={6} xs={12}>
              <Button
                sx={{ textDecoration: "underline" }}
                className={classes.myLink}
                onClick={() => (window.location.href = "/")}
              >
                Συνέχεια χωρίς λογαριασμό
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const hideMsg = () => {
    setShowingMsg(false);
  };

  const renderMessages = () => {
    if (msg?.length && showingMsg && showMessages) {
      return (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={hideMsg}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={hideMsg} severity={result}>
            {msg}
          </Alert>
        </Snackbar>
      );
    }
    return null;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={LoginValidationSchema}
        onSubmit={submit}
      >
        {({ touched, errors, ...props }) => (
          <div className={classes.container}>
            {renderWelcome()}
            <Form>
              <Grid
                container
                spacing={2}
                paddingLeft={0}
                className={classes.container}
              >
                <Grid item lg={7} md={8} sm={10} xs={12}>
                  <Field
                    fullWidth
                    as={TextField}
                    name="email"
                    label="Email"
                    id="email"
                    variant="outlined"
                    type="text"
                    {...getFieldErrors("email", errors, touched)}
                  />
                </Grid>
                <Grid item lg={7} md={8} sm={10} xs={12}>
                  <Field
                    fullWidth
                    as={TextField}
                    name="password"
                    label="Κωδικός Πρόσβασης"
                    id="password"
                    variant="outlined"
                    type="password"
                    {...getFieldErrors("password", errors, touched)}
                  />
                </Grid>
                <Grid item lg={7} md={8} sm={10} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                    }
                    label="Να παραμείνω συνδεδεμένος"
                  />
                </Grid>
                <Grid item lg={7} md={8} sm={10} xs={12}>
                  <Grid className={classes.buttonContainer} container>
                    <LoadingButton
                      type="submit"
                      size="large"
                      variant="contained"
                      loading={status === "loading"}
                    >
                      ΣΥΝΔΕΣΗ
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
            {renderChoice()}
          </div>
        )}
      </Formik>
      {renderMessages()}
    </>
  );
}

export default LoginForm;
