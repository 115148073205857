import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import React from "react";

export const LikeButton = ({ liked, onClick, loading, className, size="medium" }) => {
  if (loading) {
    return <LoadingButton loading />;
  }
  return (
    <IconButton className={className} onClick={onClick} size={size}>
      {liked && <Favorite color="primary" />}
      {!liked && <FavoriteBorder />}
    </IconButton>
  );
};
