import axios from "axios";
import config from "./config";
import { buildAuthHeader, getRequest, parseFormDataFromJson } from "./helpers";
axios.defaults.baseURL = config.apiUrl;

export const updateOneCustomerCall = (id, body_json) => {
    const body_form = parseFormDataFromJson(body_json);
    const requestUrl = `/customers/${id}`;
    const headers = buildAuthHeader();
    console.log({ requestUrl, body_form, headers })
    return axios.put(requestUrl, body_form, { headers });
}

export const getAllCustomersCall = () => {
    const requestUrl = "/customers";
    return getRequest({ requestUrl });
}

export const getOneTaxNumberOffersCall = (taxNumber) => {
    const requestUrl = `/tax-numbers/${taxNumber}/offers`;
    return getRequest({ requestUrl });
}

export const getOrderAgainDataCall = (cartId) => {
    const requestUrl = `/carts/${cartId}/again`;
    return getRequest({ requestUrl });
}