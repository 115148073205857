import React from 'react'
import { SuperUserLayout } from '../components/SuperUser/SuperUserLayout'
import { SuppliersSearch } from '../components/SuperUser/SuppliersSearch'

export const SuperUserSuppliers = () => {
    return (
        <SuperUserLayout>
            <SuppliersSearch />
        </SuperUserLayout>
    )
}