import axios from "axios";
import config from "./config";

axios.defaults.baseURL = config.apiUrl;

export const getHelpTextCall = (key) => {
    const requestUrl = `/texts/${key}`;
    return axios.get(requestUrl);
}

export const getQuestionsCall = (for_suppliers) => {
    const requestUrl = `/questions`;
    const params = {
        for_suppliers: !!for_suppliers ? 1 : 0,
    };
    return axios.get(requestUrl, { params });
}