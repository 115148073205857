import axios from "axios";
import config from "./config";
import { buildAuthHeader, getRequest, parseFormDataFromJson } from "./helpers";

axios.defaults.baseURL = config.apiUrl;


export const getCategoriesCall = () => {
    const requestUrl = "/categories";
    return axios.get(requestUrl);
}

export const getSubCategoriesCall = () => {
    const requestUrl = "/subcategories";
    return axios.get(requestUrl);
}

export const searchGlobal = (key) => {
    const requestUrl = `/search`;
    const params = { key };
    return getRequest({ requestUrl, params });
}

export const updatePersonalInfoCall = (id, body_json) => {
    const requestUrl = `/users/${id}`
    const body_form = parseFormDataFromJson(body_json);
    body_form.append("username", body_json["email"]);
    const headers = buildAuthHeader();
    return axios.put(requestUrl, body_form, { headers });
}

export const sendOrderCall = (cart_tuples) => {
    const requestUrl = `/carts`;
    const headers = buildAuthHeader();
    return axios.post(requestUrl, cart_tuples, { headers });
}

export const getOrderCall = ({ supplier, customer, month, year, start, end }) => {
    const requestUrl = `/carts`;
    const params = {
        ...(supplier && { supplier }),
        ...(customer && { customer }),
        ...(month && { month }),
        ...(year && { year }),
        start,
        end,
    };
    const headers = buildAuthHeader();
    return axios.get(requestUrl, { headers, params });
}

export const updateShippingCall = (body_json) => {
    const requestUrl = `availability/update`;
    const headers = buildAuthHeader();
    return axios.put(requestUrl, body_json, { headers });
}

export const deleteShippingCall = () => {
    const requestUrl = `availability/update`;
    const headers = buildAuthHeader();
    return axios.delete(requestUrl, { headers });
}

export const getOneOrderCall = (id) => {
    const requestUrl = `carts/${id}`;
    const headers = buildAuthHeader();
    return axios.get(requestUrl, { headers });
}

export const deleteAccountCall = (id) => {
    const requestUrl = `users/${id}`;
    const headers = buildAuthHeader();
    return axios.delete(requestUrl, { headers });
}