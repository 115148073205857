import { Button, Fab } from "@mui/material";
import React from "react";
import { useState } from "react";
import { RequestSamplesDialog } from "./RequestSamplesDialog";

export const RequestSamples = ({ supplierId }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  return (
    <div styles={{ position: "relative" }}>
      {!!isDialogOpen && (
        <RequestSamplesDialog
          isOpen={isDialogOpen}
          handleClose={handleCloseDialog}
          supplierId={supplierId}
        />
      )}
      <Button
        color="primary"
        size="large"
        variant="outlined"
        onClick={handleOpenDialog}
        sx={{
          textTransform: "none",
        }}
      >
        Ζητήστε δείγματα
      </Button>
    </div>
  );
};
