import { Button, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { formatCurrency, formatPrice } from "../../helpers/price";
import { useIsMobile } from "../../hooks/useIsMobile";

const CartFooter = ({
  entities,
  height,
  calculateTaxNumberOfferForSupplier,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const total_cost = useMemo(() => {
    let total = 0;
    entities.forEach((entity) => {
      const { cost } = entity;
      const { cost_after_offer } = calculateTaxNumberOfferForSupplier(
        entity?.product?.supplier.id,
        cost
      );
      total += cost_after_offer;
    });
    return total;
  }, [entities]);

  const currency = useMemo(
    () => entities[0]?.product.price_currency,
    [entities]
  );

  return (
    <Grid
      container
      justifyContent={"center"}
      rowSpacing={1}
      width={1}
      padding={"2px"}
      margin={0}
      height={height || "auto"}
    >
      <Grid item xs={8}>
        <Grid container justifyContent={"center"}>
          <Grid container justifyContent="space-between" width={"minContent"}>
            <Typography variant="body1" fontWeight="bold">
              Σύνολο
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              {formatPrice(total_cost)}
              {formatCurrency(currency)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" textAlign={"center"}>
          <Typography variant="subtitle2">
            *Οι τιμές δεν περιλαμβάνουν ΦΠΑ
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Button
            onClick={() => navigate("/confirmation")}
            size={isMobile ? "large" : "small"}
            variant="contained"
            color="primary"
            fullWidth
          >
            Ολοκληρωση παραγγελιας
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CartFooter;
