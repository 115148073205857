import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCart, hide } from "../../redux/slices/cartSlice";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import SupplierGroup from "./SupplierGroup";
import MyDrawer from "../General/MyDrawer";
import { CartBody } from "./CartBody";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useTaxNumberOfferForCustomer } from "../../hooks/useTaxNumberOfferForCustomer";

function Cart({ isDrawer = true, width, left }) {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const [UnderDeletionProductId, setUnderDeletionProductId] = useState(null);
  const { tuples, showing } = useSelector(selectCart);
  const [entities, setEntities] = useState(Object.values(tuples || {}));

  const { calculateTaxNumberOfferForSupplier, areTaxNumberOffersLoading } =
    useTaxNumberOfferForCustomer();

  useEffect(() => {
    setEntities(Object.values(tuples || {}));
  }, [tuples]);

  useEffect(() => {
    /*if (isMobile)*/ dispatch(hide());
    // else dispatch(show());
  }, [isMobile]);

  const closeDialog = () => {
    console.log("hiding it...");
    dispatch(hide());
  };

  const splitBySupplier = (tuples) => {
    if (!tuples) return;
    // console.log(tuples);
    let result = {};
    /* will end up looking like:
            result = {
                supplier_id : {
                    supplier: {},
                    products: [
                        {
                           product_info: { ... },
                           quantity,
                           cost,
                        }
                    ],
                    cost_summary,
                    cost_currency,
                }
            }
        */
    tuples.forEach((tuple) => {
      const { product, quantity, cost, costCase } = tuple;
      const { supplier } = product;
      const { id } = supplier;
      let products = result[id]?.products || [];

      const product_obj = {
        product_info: product,
        quantity,
        cost,
        costCase,
        normal_cost: quantity * product.price,
      };
      products = products.concat(product_obj);

      const cost_summary = products
        .map((product) => product.cost)
        .reduce((a, b) => a + b, 0);

      const cost_currency = products
        ? products[0].product_info.price_currency
        : "";
      // console.log(products, cost_currency);
      result[id] = {
        supplier,
        products,
        cost_summary,
        cost_currency,
      };
    });
    return result;
  };

  const renderTuples = () => {
    const supplierTuples = splitBySupplier(entities) || {};
    return (
      <Stack direction={"column"}>
        {Object.entries(supplierTuples).map((group) => {
          if (group) {
            return (
              <SupplierGroup
                key={group.supplier_id}
                group={group}
                setUnderDeletionProductId={setUnderDeletionProductId}
                calculateTaxNumberOfferForSupplier={
                  calculateTaxNumberOfferForSupplier
                }
              />
            );
          }
          return null;
        })}
      </Stack>
    );
  };

  const renderContent = () => {
    if (!entities.length) {
      return (
        <Grid
          container
          justifyContent={"center"}
          padding={3}
          width={1}
          sx={{ overflowX: "none" }}
        >
          <Grid item xs={12} textAlign={"center"}>
            <ProductionQuantityLimitsIcon sx={{ fontSize: 80 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center">
              Το καλάθι σας είναι άδειο
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return renderTuples();
  };

  if (isMobile || isDrawer) {
    return (
      <MyDrawer open={showing} onClose={closeDialog} overflowY={"hidden !important"}>
        <CartBody
          entities={entities}
          closeDialog={closeDialog}
          renderContent={renderContent}
          calculateTaxNumberOfferForSupplier={
            calculateTaxNumberOfferForSupplier
          }
        />
      </MyDrawer>
    );
  }
  return (
    <Paper
      elevation={1}
      sx={{
        position: "sticky",
        top: 100,
        width: width || "100%",
        maxWidth: "100%",
        maxHeight: "inherit",
      }}
    >
      <CartBody
        entities={entities}
        renderContent={renderContent}
        isDrawer={false}
        calculateTaxNumberOfferForSupplier={calculateTaxNumberOfferForSupplier}
      />
    </Paper>
  );
}

export default Cart;
