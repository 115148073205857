import { Avatar, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import React from 'react'
import { makeStyles } from "@mui/styles";
import { LocationOn, Class } from '@mui/icons-material';
import { useIsMobile } from '../../../../hooks/useIsMobile';

const useStyles = makeStyles((theme) => ({
    supplier_row: {
        justifyContent: "center",
        textAlign: "left",
        "&:hover": {
            cursor: "pointer",
        },
    },
    supplier_icon: {
        borderRadius: "7px",
        height: "auto",
        minHeight: "200px",
        maxHeight: "300px",
        width: "100%",
        maxWidth: "300px"
    },
  })
);


function Supplier({ supplier, closeMe }) {
    const classes = useStyles();
    const { isMobile } = useIsMobile();

    if (!supplier) return null;

    console.log({ supplier });
    const { city, logo, name, categories } = supplier;
    const avatarStyles = {
        width: 24,
        height: 24,
        marginRight: "-10px",
    }

    const goToSupplierProfile = () => {
        // dispatch(set_product(null));
        closeMe();
        // navigate(`/suppliers/${supplier.id}`);
        window.location.href = `/suppliers/${supplier.id}`;

    }

    const renderLine = (icon, content) => {
        return (
            <Grid item xs={12}>
                <Grid container alignContent={'center'}>
                    <Grid item xs={1}>
                        {icon}
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="body2" sx={{ paddingLeft: isMobile ? 2 : 0.7 }}>
                            {content}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderLocation = (variant="body2") => {
        let icon = <LocationOn />;
        if (isMobile) {
            icon = <LocationOn />;
        }
        const content = (
            <Typography variant={variant}>
                {city}
            </Typography>
        );
        return renderLine(icon, content);
    }

    const getAvatarIcon = () => {
        if (logo) {
            let height = "20px";
            let width = "20px";
            if (!isMobile) {
                height = "25px";
                width = "25px";
            }
            return (
                <Avatar
                    sx={{ width, height }}
                    src={logo}
                    alt="profile"
                    sizes='xs'
                />
            )
        }
        return (
            <Avatar
                sx={{ bgcolor: blue[300], ...avatarStyles, marginRight: 0 }}
            >
                {name.slice(0, 1)}
            </Avatar>
        )
    }

    const renderAvatar = () => {
        let variant = "body2";
        return (
            <Grid item xs={12}>
                <CardHeader
                    avatar={getAvatarIcon()}
                    title={supplier.name}
                    titleTypographyProps={{ variant, marginLeft: "-10px" }}
                    style={{ paddingBottom: isMobile ? "0px" : "10px", padding: "0px", }}
                    className={classes.supplier_row}
                    onClick={goToSupplierProfile}
                    sx={{ width: "minContent" }}
                />
            </Grid>
        )
    }

    const renderCategories = () => {
        const icon = <Class />;
        let content = "";
        if (!!categories?.length && categories?.length>2)
            content = categories.slice(0, 2)?.map(({ name }) => name)
                .join(", ").concat("...");
        else
            content = categories?.map(({ name }) => name)
                .join(", ");
        return renderLine(icon, content);
    }

    return  (
        // <Paper elevation={1}>
            <Grid container padding={1} spacing={1} justifyContent="center">
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                        Προμηθευτής
                    </Typography>
                </Grid>
                <Divider sx={{ width: 1, marginTop: 0, marginBottom: 0 }} />
                <Grid item xs={12}>
                    { renderAvatar() }
                </Grid>
                { renderLocation() }
                { renderCategories() }
            </Grid>
        // </Paper>
    );
}

export default Supplier;
