import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkLogged } from "../redux/slices/authSlice";
import CustomerSkeleton from "./CustomerSkeleton";
import { getFavourites } from "../redux/slices/favouritesSlice";
import DrawerLeft from "../components/Help/Drawer";
import { retrieve } from "../redux/slices/cartSlice";

export const Help = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLogged());
    dispatch(getFavourites());
    dispatch(retrieve());
  }, [dispatch]);

  return (
    <CustomerSkeleton
      headerProps={{
        // showSuggestions: true,
        homePage: true,
      }}
    >
      <DrawerLeft />
    </CustomerSkeleton>
  );
};
