import { Typography, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { getTopProductsCall } from '../../../api/products';
import MyCarousel from '../../General/MyCarousel';
import Spinner from '../../General/Spinner';
import Product from "./Product";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../redux/slices/authSlice';

function TopProducts({ kind="global" }) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const { isSupplier } = useSelector(selectAuth);

    useEffect(() => {
        setLoading(true);
        getTopProductsCall({ kind })
        .then(response => {
            console.log(response.data);
            setProducts(response.data);
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
        })
    }, [])

    const renderTitle = () => {
        if (kind==="global") {
            return "Κορυφαία προϊόντα"
        }
        return "Aγοράζετε συχνά";
    }

    if (loading) {
        return (
            <Spinner />
        )
    }
    if (products?.length) {
        return (
            <Grid container padding={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="black">
                        { renderTitle() }
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MyCarousel items={
                        products.map((product) =>
                            <Product product={product} isAdmin={isSupplier} />
                        )
                    } />
                </Grid>
            </Grid>
        )
    }
    return null;
}

export default TopProducts;