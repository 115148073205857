import { Close } from "@mui/icons-material";
import { Badge, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

const CartHeader = ({ entities, closeDialog, height }) => {
  return (
    <Grid container alignItems="center" height={height || "auto"}>
      <Grid item xs={!!closeDialog ? 10 : 12}>
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          width={1}
          spacing={1}
          padding={1}
          paddingBottom={0}
        >
          <Grid item>
            <Typography variant="h6">Το καλάθι σας</Typography>
          </Grid>
          <Grid item>
            <Badge badgeContent={entities?.length || 0} color="primary">
              <AddShoppingCartIcon sx={{ marginTop: 1 }} />
            </Badge>
          </Grid>
        </Grid>
      </Grid>
      {!!closeDialog && (
        <Grid item xs={2}>
          <Grid container justifyContent={"flex-end"}>
            <IconButton onClick={closeDialog}>
              <Close sx={{ fontSize: "30px"}} />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CartHeader;
