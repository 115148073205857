import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

/*
    cart: {
        product_id : {
            product,
            quantity: x,
            cost: y,
        }
    }

    and I will just do add, subtract, remove
*/

const initialState = {
    tuples: {},
    status: "idle",
    showing: false,
    expiration: {},
    // submitted_tuples: {},
}

const submit = createAsyncThunk(
    "cart/validate",
    async (cart) => {
        console.log("validating cart: ", cart);
    }
)

const save = (state) => {
    const now = moment.utc();
    // const expiration = now.add(1, 'hours').format("YYYY-MM-DD hh:mm:ss");
    const expiration_date = now.add(1, 'hours');
    const expiration = {
        year: expiration_date.year(),
        month: expiration_date.month(),
        day: expiration_date.date(),
        hour: expiration_date.hour(),
        minute: expiration_date.minute(),
    };
    console.log({ expiration })
    console.log({ now: moment.utc().format("YYYY-MM-DD hh:mm:ss") })
    const cart = { tuples: state.tuples, expiration };
    state.expiration = expiration;
    // state.tuples = tuples;
    // state.cart = cart;
    localStorage.setItem("cart", JSON.stringify(cart));
}

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        show: (state) => {
            state.showing = true;
        },
        hide: (state) => {
            state.showing = false;
        },
        retrieve: (state) => {
            // console.clear();
            console.log("retrieving cart...");
            const cartStr = localStorage.getItem("cart");
            let cart = {};
            if (cartStr) {
                try {
                    cart = JSON.parse(cartStr);
                    // console.log({ cart });
                }
                catch (err) {
                    console.log({ cartStr });
                    console.log("Cart str could not be parsed to JSON...");
                    state.tuples = {};
                    return;
                }
            }
            let tuples = cart.tuples;
            try {
                if (Object.keys(tuples || {}).length) {
                    console.log("tuples before removing submitted:", tuples)
                    const notSubmittedTuples = Object.entries(tuples).filter(([, { submitted }]) => !submitted);
                    tuples = Object.fromEntries(notSubmittedTuples);
                    console.log("tuples after removing submitted", tuples)
                }
            }
            catch (err) {
                ;
            }
            const expiration = cart.expiration;
            const now = {
                year: moment.utc().year(),
                month: moment.utc().month(),
                day: moment.utc().date(),
                hour: moment.utc().hour(),
                minute: moment.utc().minute(),
            };
            console.log({ m_exp: moment(expiration) })
            console.log({ m_now: moment(now) })
            if (moment(expiration) < moment(now)) {
                console.log("expired");
                localStorage.removeItem("cart");
                return;
            }
            else {
                console.log("not expired");
            }
            console.log({ tuples });
            state.tuples = tuples;
            state.expiration = expiration;
        },
        interactive_add: (state, action) => {
            const { product_id } = action.payload;
            console.log(action.payload);
            if (state.tuples[product_id]) {
                const { product } = state.tuples[product_id];
                const { package_quantity, price, max_quantity, offer, hyper_min_quantity, hyper_price } = product;
                // const package_cost = package_quantity * price;
                console.log("product already in the cart");
                const new_quantity = state.tuples[product_id].quantity + package_quantity
                if (new_quantity > max_quantity) {
                    // setSnackMessage({
                    //     text: `Η μέγιστη δυνατή παραγγελία για το προϊόν είναι ${max_quantity} μονάδες`,
                    // });
                    return;
                }
                state.tuples[product_id].quantity = new_quantity;
                let cost_ = state.tuples[product_id].quantity * price;
                let costCase_ = "normal";
                if (offer) {
                    const { price: offer_price } = offer;
                    if (!offer.is_expired) {
                        cost_ = new_quantity * offer_price;
                        costCase_ = "offer";
                    }
                }
                else if (hyper_price) {
                    if (new_quantity>=hyper_min_quantity) {
                        cost_ = new_quantity * hyper_price;
                        costCase_ = "hyper";
                    }
                }
                state.tuples[product_id].cost = cost_;
                state.tuples[product_id].costCase = costCase_;
                save(state);
            }
            else {
                console.log("wrong reducer my friend, try the add_product");
            }
        },
        interactive_subtract: (state, action) => {
            const { product_id } = action.payload;
            const { product } = state.tuples[product_id];
            const { package_quantity, price, min_quantity, offer, hyper_min_quantity, hyper_price } = product;
            // const package_cost = package_quantity * price;

            if (state.tuples[product_id]) {
                console.log("product already in the cart");
                if (!state.tuples[product_id].quantity) return;
                const new_quantity = state.tuples[product_id].quantity - package_quantity;
                if (new_quantity<min_quantity) {
                    // delete state.tuples[product_id];
                    // save(state);
                    return;
                }
                state.tuples[product_id].quantity = new_quantity;
                const quantity = state.tuples[product_id].quantity;
                let cost_ = state.tuples[product_id].quantity * price;
                let costCase_ = "normal";
                if (offer) {
                    const { price: offer_price } = offer;
                    if (!offer.is_expired) {
                        cost_ = quantity * offer_price;
                        costCase_ = "offer";
                    }
                }
                else if (hyper_price) {
                    if (quantity>=hyper_min_quantity) {
                        cost_ = quantity * hyper_price;
                        costCase_ = "hyper";
                    }
                }
                if (cost_ < 0) {
                    state.tuples[product_id].cost = 0;
                }
                else {
                    state.tuples[product_id].cost = cost_;
                    state.tuples[product_id].costCase = costCase_;
                }
                save(state);
            }
            else {
                console.log("wrong reducer my friend, try the add_product");
            }
        },
        update_product: (state, action) => {
            const { product_id, quantity, cost, costCase } = action.payload;
            if (state.tuples[product_id]) {
                console.log("product already in the cart");
                if (quantity==0) {
                    delete state.tuples[product_id];
                    save(state);
                    return;
                }
                state.tuples[product_id].quantity = quantity;
                state.tuples[product_id].cost = cost;
                state.tuples[product_id].costCase = costCase;
                save(state);
            }
            else {
                console.log("wrong reducer my friend, try the add_product");
            }
        },
        add_new_product: (state, action) => {
            console.log("adding product to the cart");
            const { product, quantity, cost, costCase } = action.payload;
            const { category, sub_category, description, volume, weight, volume_metric, weight_metric, ...otherProductInfo } = product;
            const new_tuple = {
                product: otherProductInfo,
                quantity,
                cost,
                costCase,
            }
            if (!state.tuples) state.tuples = {};
            state.tuples[product.id] = new_tuple;
            save(state);
        },
        remove_product: (state, action) => {
            const { product_id } = action.payload;
            delete state.tuples[product_id];
            save(state);
        },
        add_to_submitted: (state, action) => {
            console.log(action.payload);
            const { product_id } = action.payload;
            console.log("adding ", product_id, " to submitted");
            state.tuples[product_id].submitted = true;
            // state.submitted_ids = state.submitted_ids.concat(product_id);
            // delete state.tuples[product_id];
            save(state);
        },
        clear_submitted: (state) => {
            const tuples_copy = { ...state.tuples };
            Object.values(state.tuples || {}).forEach(tuple => {
                const { submitted, product } = tuple;
                if (submitted) {
                    const { id } = product;
                    delete tuples_copy[id];
                }
            })
            state.tuples = tuples_copy;
            save(state);
        }
    },
});

export const {
    interactive_add,
    interactive_subtract,
    update_product,
    add_new_product,
    remove_product,
    retrieve,
    show,
    hide,
    add_to_submitted,
    clear_submitted,
} = cartSlice.actions;

export const selectCart = (state) => state.cart;

export default cartSlice.reducer;