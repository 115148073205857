import { Alert, Snackbar } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackMessage, selectSnackMessage } from '../../redux/slices/snackMessageSlice';
import { SlideTransition } from "./SlideTransition";

function SnackbarMessage() {
    const dispatch = useDispatch();
    const { text, severity, vertical, duration } = useSelector(selectSnackMessage);

    const close = () => dispatch(hideSnackMessage());


    return (
        <Snackbar
            onClose={close}
            autoHideDuration={duration}
            open={Boolean(text) && text!==""}
            anchorOrigin={{ vertical, horizontal: "center" }}
            TransitionComponent={SlideTransition}
        >
            <Alert onClose={close} severity={severity}>
                {text}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarMessage;