import React, { useEffect, useState } from "react";
import { Clear, Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import MyTextFieldWithoutGrid from "../../../MyTextFieldWithoutGrid";
import { useDispatch } from "react-redux";
import { currencies } from "../../../../data/data";
import { getFieldErrors } from "../../../../helpers/getFieldErrors";
import { MinOrderValidationSchema } from "./MinOrderValidationSchema";
import {
  ClearMinOrderCall,
  UpdateMinOrderCall,
} from "../../../../api/suppliers";
import { setSnackMessage } from "../../../../redux/slices/snackMessageSlice";

function MinOrderForm({ supplier_store, refresh, disabled }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deletionLoading, setDeletionLoading] = useState(false);

  if (!supplier_store) {
    return null;
  }

  const { id, min_order_cost, min_order_cost_currency } = supplier_store || {};

  const initialValues = {
    min_order_cost: min_order_cost || "",
    min_order_cost_currency: min_order_cost_currency || "",
  };

  const clear = () => {
    setDeletionLoading(true);
    ClearMinOrderCall(id)
      .then((response) => {
        console.log(response.data);
        dispatch(
          setSnackMessage({
            text: "Η ελάχιστη παραγγελία μηδενίστηκε με επιτυχία",
            severity: "info",
          })
        );
        setDeletionLoading(false);
        refresh();
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackMessage({
            text: "Συγγνώμη, δεν μπορέσαμε να μηδενίσουμε την ελάχιστη παραγγελία σας",
            severity: "warning",
          })
        );
        setDeletionLoading(false);
      });
  };

  const submit = (values) => {
    console.log(values);
    setLoading(true);
    UpdateMinOrderCall(id, values)
      .then((response) => {
        console.log(response);
        dispatch(
          setSnackMessage({
            text: "Η ελάχιστη παραγγελία ενημερώθηκε με επιτυχία",
            severity: "info",
          })
        );
        setLoading(false);
        refresh();
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackMessage({
            text: "Συγγνώμη, δεν μπορέσαμε να ενημερώσουμε την ελάχιστη παραγγελία σας",
            severity: "warning",
          })
        );
        setLoading(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={MinOrderValidationSchema}
      enableReinitialize
    >
      {({ errors, values, touched }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Ελάχιστη παραγγελία</Typography>
            </Grid>
            <Grid item sm={4} xs={6}>
              <MyTextFieldWithoutGrid
                name="min_order_cost"
                label="Ελάχιστο κόστος (χωρίς ΦΠΑ)"
                id="min_order_cost"
                type="number"
                disabled={disabled}
                {...getFieldErrors("min_order_cost", errors, touched)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Νόμισμα</InputLabel>
                <Field
                  as={Select}
                  name="min_order_cost_currency"
                  label="Νόμισμα"
                  id="min_order_cost_currency"
                  disabled={disabled}
                  {...getFieldErrors(
                    "min_order_cost_currency",
                    errors,
                    touched
                  )}
                >
                  {currencies?.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            <LoadingButton
              onClick={clear}
              loading={deletionLoading}
              color="error"
              variant="outlined"
              endIcon={<Clear />}
              disabled={disabled}
            >
              Μηδενισμός
            </LoadingButton>
            <LoadingButton
              type="submit"
              loading={loading}
              variant="contained"
              endIcon={<Send />}
              disabled={disabled}
            >
              Ενημερωση
            </LoadingButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default MinOrderForm;
