import axios from "axios";
import config from "./config";
import { buildAuthHeader, getRequest } from "./helpers";
axios.defaults.baseURL = config.apiUrl;

export const getAllCarts = async ({ start, end, supplier }) => {
    const requestUrl = "/superuser/carts";
    const params = {
        start,
        end,
        ...(supplier && { supplier }),
    };
    return getRequest({ requestUrl, params })
}

export const getOneCart = (id) => {
    const requestUrl = `/carts/${id}`;
    return getRequest({ requestUrl });
}

export const countAllCarts = async ({ supplier }) => {
    console.log("counting all carts");
    const requestUrl = `/superuser/carts/count`;
    const params = {
        ...(supplier && { supplier }),
    };
    return getRequest({ requestUrl, params })
}

export const cartCancel = (id) => {
    const requestUrl = `/superuser/carts/${id}/cancel`;
    const headers = buildAuthHeader();
    return axios.put(requestUrl, {}, { headers });
}

export const deleteCartTupleCall = (id) => {
    const requestUrl = `superuser/carttuples/${id}`;
    const headers = buildAuthHeader();
    return axios.delete(requestUrl, { headers });
}

export const updateCartTupleCall = ({ id, quantity }) => {
    const requestUrl = `superuser/carttuples/${id}`;
    const headers = buildAuthHeader();
    return axios.put(requestUrl, { quantity }, { headers });
}