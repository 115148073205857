import React from "react";
import { Grid } from "@mui/material";
import { useIsMobile } from "../hooks/useIsMobile";

function WidthLimiter({ children, marginTop, fullHeight, forBanner }) {
  const { isMobile } = useIsMobile();
  return (
    <Grid
      container
      justifyContent={"center"}
      marginTop={marginTop || 0}
      paddingLeft={ (isMobile && !forBanner) ? 1 : 0}
      paddingRight={(isMobile && !forBanner) ? 1 : 0}
      {...fullHeight && { height: "100%" }}
    >
      <Grid
        item
        maxWidth={1400}
        /*minHeight={500}*/ xl={10}
        lg={forBanner ? 12 : 11.8}
        xs={12}
        width="100%"
      >
        {children}
      </Grid>
    </Grid>
  );
}

export default WidthLimiter;
