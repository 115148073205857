import axios from "axios";
import { deleteCookie, getCookie, setCookie } from "../../helpers/cookies";
import config from "../config";
axios.defaults.baseURL = config.apiUrl;

class AuthService {

    parseFormDataFromJson = (jsonData, type="register") => {
        const bodyFormData = new FormData();
        for (const [key, value] of Object.entries(jsonData)) {
            bodyFormData.append(key, value);
        }
        if (type==="login") {
            bodyFormData.append("username", jsonData["email"]);
        }
        return bodyFormData;
    }

    formDataHeaders = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };

    translations = {
        "user with this email already exists.": "Αυτό το email χρησιμοποιείται ήδη",
        "We could not create your account, make sure your tax number is correct": "Δεν μπορέσαμε να δημιουργήσουμε τον λογαριασμό σας, βεβαιωθείτε ότι το ΑΦΜ που εισάγατε είναι το σωστό",
        "email": "Άκυρο email",
        "supplier store with this tax no already exists.": "Παρακαλώ εισάγετε το έγκυρο ΑΦΜ σας",
    }

    handleLoginRegisterSuccessResponse = (data, type) => {
        let user = {};
        let token = "";
        let isSupplier = false;
        if (type==="login" || type==="register") {
            token = data["access"];
            return { token }
        }
        else if (type==="logged") {
            user = data;
            isSupplier = data.is_supplier || false;
            return { user, isSupplier };
        }
    }

    handleLoginErrorResponse = (data) => {
        const msg = "Δεν βρήκαμε λογαριασμό με αυτά τα δεδομένα πρόσβασης";
        return msg;
    }

    handleRegisterErrorResponse = (data) => {
        console.log(data)
        const messages = []
        if (typeof(data)==="string") {
            if (this.translations[data]) {
                return this.translations[data];
            }
        }
        else {
            for (const [field, errors] of Object.entries(data)) {
                console.log("field:", field);
                console.log("errors:", errors);
                errors.forEach(error => {
                    if (this.translations[error]) {
                        messages.push(this.translations[error])
                    }
                })
            }
            if (messages.length) {
                return messages;
            }
            return "Άκυρα δεδομένα για δημιοργία λογαριασμού";
        }
    }

    loginRegister = async ({ type, jsonData, rememberMe }) => {
        let handleErrorResponse = this.handleLoginErrorResponse;
        if (type==="register") {
            handleErrorResponse = this.handleRegisterErrorResponse;
        }
        const bodyFormData = this.parseFormDataFromJson(jsonData, type);
        console.log(bodyFormData);
        const requestUrl = type==="login" ? `/login` : '/users';
        return axios.post(requestUrl, bodyFormData, this.formDataHeaders)
            .then(response => {
                console.log(type, ": ", response.data);
                const { user, token, isSupplier } = this.handleLoginRegisterSuccessResponse(response.data, type);
                // localStorage.setItem("token", token);
                if (rememberMe) {
                    setCookie("market-session-jwt-token", token, 60);
                }
                else {
                    setCookie("market-session-jwt-token", token);
                }
                this.setSession(token);
                console.log({ user, token, isSupplier });
                // return user object to play with redux...
                return { status: "success", user, token, isSupplier };
            })
            .catch(err => {
                console.log(err.response.data);
                const msg = handleErrorResponse(err.response.data, type);
                return { status: "error", msg };
            })
    }

    login = async ({ jsonData, rememberMe }) => {
        return this.loginRegister({ type: "login", jsonData, rememberMe });
    }

    register = async ({ jsonData, rememberMe }) => {
        return this.loginRegister({ type: "register", jsonData, rememberMe });
    }

    logged = async () => {
        // const token = localStorage.getItem("token");
        const token = getCookie("market-session-jwt-token");
        const msg = "Δεν έχετε συνδεθεί";
        if (!token) {
            return { status: "error", msg };
        }
        const requestUrl = "/logged";
        const authHeader = {
            headers: {
                "Authorization": "Bearer "+ token,
            }
        };
        return axios.get(requestUrl, authHeader)
            .then(response => {
                const { user, isSupplier } = this.handleLoginRegisterSuccessResponse(response.data, "logged");
                console.log("logged returning user: ", user);
                return { status: "success", user, isSupplier, token };
            })
            .catch(error => {
                console.log(error.response.data);
                return { status: "error", msg };
            })
    }

    logout = () => {
        // localStorage.removeItem("token");
        deleteCookie("market-session-jwt-token");
        return { status: "success" };
    }

    setSession = (token) => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
}

export default new AuthService();