import { AppBar, Box, Grid, Toolbar } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import WidthLimiter from "../../../pages/WidthLimiter";
import { useIsMobile } from "../../../hooks/useIsMobile";
import logoWithText from "../../../images/MarketOn_Logo_final.png";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[100]
  },
}));

export const Footer = () => {
  const classes = useStyles();
  const { isMobile } = useIsMobile();

  const Link = ({ children, helpPage, page, fontSize }) => {
    return (
      <Box sx={{ display: { xs: "flex", md: "flex", padding: 10 } }}>
        <a
          style={{
            color: "black",
            textDecoration: "none",
            ...(!!fontSize && { fontSize }),
          }}
          href={!!helpPage ? `/help?page=${helpPage}` : page}
        >
          {children}
        </a>
      </Box>
    );
  };

  return (
    <AppBar
      position="relative"
      sx={{
        top: "auto",
        bottom: 0,
        minHeight: 200,
        backgroundColor: "inherit",
      }}
      className={classes.container}
    >
      <Toolbar sx={{ ...(!isMobile && { paddingLeft: "0px !important", paddingRight: "0px !important" }) }}>
        <WidthLimiter>
          <Grid container spacing={1} >
            <Grid item md={4} xs={12}>
              <Link page="/" fontSize={30}>
                <img src={logoWithText} alt="MarketOn" width={150} />
              </Link>
              {/* <Link helpPage="vision">Όραμα εταιρείας</Link> */}
            </Grid>
            <Grid item md={4} xs={12}>
              <Link helpPage="howto">Πώς λειτουργεί</Link>
              <Link helpPage="suppliers">Συχνές ερωτήσεις για προμηθευτές</Link>
              <Link helpPage="customers">
                Συχνές ερωτήσεις για επιχειρήσεις
              </Link>
            </Grid>
            <Grid item md={4} xs={12}>
              <Link helpPage="privacy">Πολιτική απορρήτου</Link>
              <Link helpPage="terms">Όροι χρήσης</Link>
              <Link helpPage="cookies-policy">Πολιτική cookies</Link>
              <Link helpPage="contact">Επικοινωνία</Link>
            </Grid>
          </Grid>
        </WidthLimiter>
      </Toolbar>
    </AppBar>
  );
};
