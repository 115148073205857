import { LoadingButton } from '@mui/lab'
import { Alert, Chip, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { queriesKeys } from '../../api/queriesKeys'
import { cartCancel, deleteCartTupleCall, getOneCart } from '../../api/superuser'
import { setSnackMessage } from '../../redux/slices/snackMessageSlice'
import OneOrder from '../Admin/Supplier/Orders/OneOrder'
import MyDivider from '../General/MyDivider'
import Spinner from '../General/Spinner'
import { SimpleSearchBar } from './SimpleSearchBar'

const OneOrderEdit = ({ id }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [cancelLoading, setCancelLoading] = useState(false);
    
    const { data: order, isLoading: orderLoading } = useQuery(
        [queriesKeys['getOneCart'], id],
        () => getOneCart(id), {
            enabled: !!id,
            refetchOnWindowFocus: false,
        }
    );

    const showUpdatedSuccessMsg = () => {
        dispatch(setSnackMessage({
            text: "Updated successfully",
            severity: "info",
        }));
    }

    const showUpdatedErrorMsg = () => {
        dispatch(setSnackMessage({
            text: "Sorry, we could not update the order",
            severity: "error",
        }));
    }

    const cancelOrder = () => {
        console.log("cancelling...");
        setCancelLoading(true);
        cartCancel(id)
        .then(response => {
            console.log(response.data);
            showUpdatedSuccessMsg();
            queryClient.invalidateQueries(queriesKeys['getOneCart']);
            setCancelLoading(false);
        })
        .catch(err => {
            console.log(err);
            showUpdatedErrorMsg();
            setCancelLoading(false);
        })
    }

    const deleteCartTuple = (id) => {
        deleteCartTupleCall(id)
        .then(response => {
            console.log(response.data);
            showUpdatedSuccessMsg();
            queryClient.invalidateQueries(queriesKeys['getOneCart']);
        })
        .catch(err => {
            console.log(err);
            showUpdatedErrorMsg();
        })
    }

    const search = (values) => {
        navigate(`/super/orders/search?key=${values.key}`)
    }

    const renderAction = () => {
        if (!order && !orderLoading) return null;
        console.log({ order })
        const { is_cancelled } = order || {};
        let text = "Cancel order";
        let color = "error";
        if (is_cancelled) {
            text = "Undo cancellation";
            color = "warning"
        }
        return (
            <LoadingButton loading={cancelLoading} /*disabled={isRefetching}*/ onClick={cancelOrder} variant="contained" color={color}>
                { text }
            </LoadingButton>
        )
    }
    
    let content = null;
    if (orderLoading) {
        content = <Spinner />;
    }
    else if (!order) {
        content = ( 
            <Alert severity="info">
                {id && 'Order not found.'}
                {!id && 'Specify an order id'}
            </Alert>
        )
    }
    else {
        const { delivered, seen } = order;
        const color = delivered ? "success" : "primary";
        const text = delivered ? "Delivered" : (seen ? "Seen" : "Pending");
        content = (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    Status: <Chip color={color} label={text} />
                </Grid>
                <Grid item xs={12}>
                    <OneOrder
                        order={order}
                        customerView={false}
                        isSuperUser={true}
                        fullPage={true}
                        initExpanded={true}
                        deleteCartTuple={deleteCartTuple}
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <SimpleSearchBar
                    search={search}
                    initialValue={id}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant="h5">
                            Order #{id}
                        </Typography>
                    </Grid>
                    <Grid item>
                        { renderAction() }
                    </Grid>
                </Grid>
            </Grid>
            <MyDivider />
            <Grid item xs={12}>
                { content }
            </Grid>
        </Grid>
    )
}

export default OneOrderEdit;