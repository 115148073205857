import { alpha, Avatar, CardHeader, useTheme } from "@mui/material";
import React from "react";

const MyAvatar = ({ logo, alt, name, color, sx, title, action, onClick, component }) => {
  const theme = useTheme();
  let avatar = null;
  if (logo) {
    avatar = (
      <Avatar
        src={logo}
        alt={alt || "avatar"}
        sx={{
          height: 25,
          width: 25,
          ...sx,
        }}
      />
    );
  } else {
    avatar = (
      <Avatar
        sx={{
          bgcolor: alpha(theme.palette.primary.main, 0.2),
          height: 25,
          width: 25,
          ...sx,
        }}
      >
        {name}
      </Avatar>
    );
  }
  return (
    <CardHeader
      component={component}
      variant="contained"
      color="inherit"
      // color={alpha(theme.palette.primary.main, 0.2)}
      sx={{ padding: 1, textTransform: "none" }}
      avatar={avatar}
      title={title}
      titleTypographyProps={{ textAlign: "left" }}
      action={action}
      onClick={onClick}
    />
  );
};

export default MyAvatar;
