import React from "react";
import { TextField } from "@mui/material";
import { Field } from "formik";

function MyTextField({name, label, id, type, error, helperText, startAdornment, endAdornment, placeholder, multiline, minRows, disabled, onKeyDown, step, ...props }) {

    const renderField = () => {
        return (
            <Field
                as={TextField}
                name={name}
                label={label}
                id={id}
                variant="outlined"
                type={type}
                error={error}
                helperText={helperText || " "}
                InputProps={{
                    startAdornment,
                    endAdornment,
                    ...(onKeyDown && { onKeyDown }),
                    ...(step && { step }),
                    ...(placeholder && { placeholder }),
                }}
                InputLabelProps={{
                    ...(placeholder && { shrink: true }),
                }}
                fullWidth
                multiline={multiline===true}
                minRows={minRows || 1}
                disabled={disabled}
                { ...props }
            />
        )
    }

    

    return renderField();
}

export default MyTextField;