import { Button, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Scrollable from "../../../General/Scrollable";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { GeneralDialog } from "../../../General/GeneralDialog";
import { DynamicScrollFab } from "../../../General/DynamicScrollFab";
import { useDistanceToBottom } from "../../../../hooks/useDistanceToBottom";
import { zIndexes } from "../../../../zIndexes";
import { CatalogContent } from "./CatalogContent";
import { useSearchParams } from "react-router-dom";
import { useSetSearchParams } from "../../../../hooks/useSetSearchParams";
import { scrollToHeader } from "../../../../helpers/scrollToHeader";
import MyDivider from "../../../General/MyDivider";

function Catalog2({ isAdmin, width, catalog, isCatalogLoading }) {
  const { isMobile } = useIsMobile();
  const scrollAreaHeight = useDistanceToBottom();
  const [showing, setShowing] = useState(!isMobile);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { addSearchParams } = useSetSearchParams();

  useEffect(() => {
    setShowing(!isMobile);
  }, [isMobile]);

  const chooseSubCat = (sub_cat_id, cat_id) => {
    addSearchParams({
      category: cat_id,
      sub_category: sub_cat_id,
    });
    scrollToHeader();
  };

  useEffect(() => {
    const cat_id = searchParams.get("category");
    const sub_cat_id = searchParams.get("sub_category");
    setCategoryId(cat_id);
    setSubCategoryId(sub_cat_id);
  }, [searchParams.get("category"), searchParams.get("sub_category")]);

  const renderButton = () => {
    if (isMobile) {
      return (
        <div
          style={{
            position: "fixed",
            right: 4,
            bottom: "8px",
            zIndex: zIndexes.catalogFAB,
          }}
        >
          <DynamicScrollFab
            text="Κατάλογος"
            icon={<FilterAltIcon sx={{ padding: 1 }} />}
            scrollLimit={300}
            onClick={() => setShowing(!showing)}
          />
        </div>
      );
    }
    return null;
  };

  const closeDialog = () => {
    setShowing(false);
  };

  const renderPaper = () => {
    if (showing) {
      if (isMobile) {
        return (
          <GeneralDialog
            title="Κατάλογος"
            content={
              <CatalogContent
                catalog={catalog}
                isCatalogLoading={isCatalogLoading}
                selectedCategoryId={categoryId}
                selectedSubCategoryId={subCategoryId}
                chooseSubCat={chooseSubCat}
              />
            }
            onClose={closeDialog}
            actions={<Button onClick={closeDialog}>OK</Button>}
            fullWidth
          />
        );
      }
      return (
        <Paper
          elevation={1}
          sx={{
            position: "sticky",
            top: 100,
            width: width || "100%",
            // backgroundColor: "inherit",
          }}
        >
          <Typography variant="h6" padding={2} paddingBottom={1}>
            Κατάλογος
          </Typography>
          <MyDivider />
          <div style={{ marginTop: "-8px" }} />
          <Scrollable maxHeight={scrollAreaHeight - 158 - 24} height="100%">
            <CatalogContent
              catalog={catalog}
              isCatalogLoading={isCatalogLoading}
              selectedCategoryId={categoryId}
              selectedSubCategoryId={subCategoryId}
              chooseSubCat={chooseSubCat}
            />
          </Scrollable>
        </Paper>
      );
    }
    return null;
  };

  return (
    <>
      {renderButton()}
      {renderPaper()}
    </>
  );
}

export default Catalog2;
