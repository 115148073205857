import {
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Avatar,
  CardMedia,
  CardActions,
  Stack,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Alert,
  Card,
  CardContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ClassIcon from "@mui/icons-material/Class";
import {
  add_new_product,
  selectCart,
} from "../../../../redux/slices/cartSlice";
import { setSnackMessage } from "../../../../redux/slices/snackMessageSlice";
import Supplier from "./Supplier";
import Product from "./Product";
import { formatCurrency, formatPrice } from "../../../../helpers/price";
import QuantityButtons from "../../../General/QuantityButtons";
import {
  clear_product,
  selectOneProduct,
} from "../../../../redux/slices/oneProductSlice";
import { getOneProductCall } from "../../../../api/products";
import Spinner from "../../../General/Spinner";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { Close } from "@mui/icons-material";
import { useFavourites } from "../../../../hooks/useFavourites";
import { LikeButton } from "../../../General/LikeButton";
import placeholderImage from "../../../../images/product_placeholder_image.png";
import { useLocalQuantity } from "../../../../hooks/useLocalQuantity";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "inherit",
  },
  card: {
    height: "100%",
    overflowY: "scroll",
  },
  supplier_row: {
    justifyContent: "center",
    textAlign: "left",
    "&:hover": {
      cursor: "pointer",
    },
  },
  productImage: {
    borderRadius: "7px",
    height: "auto",
    width: "100%",
    maxWidth: "320px",
  },
  alert: {
    padding: "0px 8px",
    // fontSize: "14px",
  },
}));

function ProductPopUp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { product } = useSelector(selectOneProduct);
  const { isMobile } = useIsMobile();
  const { tuples } = useSelector(selectCart);
  const { id, isAdmin } = useSelector(selectOneProduct);

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    liked,
    loading: likedLoading,
    toggleLike,
  } = useFavourites({
    kind: "products",
    id: product?.id,
  });

  let tuple = null;
  let quantity = 0;
  if (product) {
    console.log({ tuples });
    tuple = tuples ? tuples[product.id] : null;
  }
  if (tuple) {
    quantity = tuple.quantity;
  }

  const {
    localQuantity,
    localCost,
    tooltipOpened,
    add,
    subtract,
    update,
  } = useLocalQuantity({
    globalQuantity: quantity,
    tuple,
    product,
  })

  const [showingDescriptionModal, setShowingDescriptionModal] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getOneProductCall(id)
        .then((response) => {
          console.log(response.data);
          setProduct(response.data);
          // setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false);
        });
    }
  }, [id]);

  const close = () => {
    console.log("closing");
    // setShowingDescriptionModal(false);
    dispatch(clear_product());
    // dispatch(set_product(null));
  };
  // const openDescription = () => {
  //     setShowingDescriptionModal(true);
  // }
  const closeDescription = () => {
    setShowingDescriptionModal(false);
  };

  // useEffect(() => {
  //     if (product?.price) {
  //         setCost(Math.round(quantity*product.price*100)/100);
  //     }
  // }, [quantity, product?.price])

  if (!id || !product) {
    if (loading) {
      return (
        <Dialog
          open
          onClose={close}
          fullWidth={true}
          maxWidth={isMobile ? "100%" : "lg"}
        >
          <DialogContent sx={{ padding: "0px !important", height: "60vh" }}>
            <Grid
              container
              height={"60vh"}
              alignItems="center"
              alignContent={"center"}
            >
              <Spinner />
            </Grid>
          </DialogContent>
        </Dialog>
      );
    }
    return null;
  }

  const {
    name,
    description,
    image,
    sub_category,
    category,
    supplier,
    is_available,
    price,
    price_currency,
    package_quantity,
    min_quantity,
    max_quantity,
    offer,
    hyper_price,
    hyper_min_quantity,
  } = product;

  const closeMe = () => {
    dispatch(clear_product());
  };

  const renderImage = () => {
      if (!isMobile) {
        return (
          <CardMedia sx={{ aspectRatio: "4/3" }}>
            <img src={image || placeholderImage} alt="" className={classes.productImage} />
          </CardMedia>
        );
      } else {
        return (
          <CardMedia component="img" sx={{ aspectRatio: "4/3", maxWidth: "400px", margin: "auto" }} image={image || placeholderImage} alt={name} />
        );
      }
  };

  const renderDescriptionModal = () => {
    if (showingDescriptionModal) {
      return (
        <Dialog open onClose={closeDescription}>
          <DialogTitle>{name}</DialogTitle>
          <DialogContent>{description}</DialogContent>
          <DialogActions>
            <Button onClick={closeDescription}>ΚΛΕΙΣΙΜΟ</Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

  const renderDescription = () => {
    if (description) {
      return (
        <Grid container className={classes.container} marginTop={2}>
          <Grid item xs={12}>
            <Typography
              variant={"body1"}
              align={isMobile ? "center" : "flex-start"}
            >
              Περιγραφή
            </Typography>
          </Grid>
          <Divider
            style={{
              width: "100%",
              marginTop: isMobile ? 0 : 5,
              marginBottom: isMobile ? 0 : 5,
            }}
          />
          <Grid item xs={12}>
            <Typography variant={"body2"} whiteSpace="pre-wrap">
              {description}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const renderHyperMessage = () => {
    if (hyper_price) {
      let message = "Η έκπτωση είναι σε ισχύ";
      let severity = "success";
      let width = 275;
      const diff = hyper_min_quantity - localQuantity;
      if (diff > 0) {
        message = `Υπολείπονται ${diff} μονάδες για να αξιοποιήσετε την έκπτωση`;
        severity = "info";
        width = 475;
      }
      return (
        <Alert
          severity={severity}
          className={classes.alert}
          sx={{ width, maxWidth: "87vw", margin: "auto" }}
        >
          {message}
        </Alert>
      );
    }
    return null;
  };

  const renderCart = () => {
    return (
      <Grid container className={classes.cart_container} rowSpacing={1}>
        <Grid item xs={12} style={{ marginBottom: "5px" }}>
          <Typography variant={"body1"} align={isMobile ? "center" : "auto"}>
            Καλάθι
          </Typography>
        </Grid>
        <Divider style={{ width: "100%", marginTop: 0, marginBottom: 1 }} />
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent={isMobile ? "center" : "auto"}
          >
            <Grid item>
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item>
                  <QuantityButtons
                    onAdd={add}
                    onSubtract={subtract}
                    quantity={localQuantity}
                    size={"large"}
                  />
                </Grid>
                <Grid item>
                  <Typography variant={"h6"}>
                    {formatPrice(localCost)}
                    {formatCurrency(price_currency)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Tooltip
                title="Ενημέρωση καλαθιού"
                arrow
                placement="top"
                open={tooltipOpened}
              >
                <Button
                  onClick={update}
                  size="small"
                  endIcon={<AddShoppingCartIcon />}
                  variant="contained"
                >
                  Προσθηκη
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={isMobile ? "center" : "flex-start"}>
            <Grid item>{renderHyperMessage()}</Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderHeader = () => {
    let titleVariant = "h6";
    if (!isMobile) titleVariant = "h5";
    let subTitleVariant = "caption";
    if (!isMobile) subTitleVariant = "subtitle2";
    let iconSize = "20px";
    if (!isMobile) iconSize = "25px";
    return (
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={10}>
          <Stack justifyContent={"center"}>
            <Grid container justifyContent="center">
              <Typography variant={titleVariant} align="center">
                {name}
              </Typography>
            </Grid>
            <Grid container justifyContent={"center"} alignItems="center">
              <ClassIcon style={{ fontSize: iconSize }} />
              <Typography variant={subTitleVariant}>
                {sub_category?.name || category?.name}
              </Typography>
              <LikeButton
                liked={liked}
                loading={likedLoading}
                onClick={toggleLike}
              />
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent="flex-end">
            <IconButton onClick={close}>
              <Close sx={{ fontSize: "30px"}} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDialog = () => {
    console.log("rendering...");
    console.log({ isAdmin });

    if (!isMobile) {
      return (
        <Dialog
          open
          onClose={close}
          fullWidth={true}
          maxWidth={isMobile ? "100%" : "lg"}
        >
          <DialogContent sx={{ padding: "0px !important" }}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container rowSpacing={1}>
                  <Grid item md={4}>
                    {renderImage()}
                  </Grid>
                  <Grid item md={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {renderHeader()}
                      </Grid>
                      <Grid item md={6} height={1}>
                        <Supplier supplier={supplier} closeMe={closeMe} />
                      </Grid>
                      <Grid item md={6} height={1}>
                        <Product product={product} />
                      </Grid>
                    </Grid>
                  </Grid>
                  {!isAdmin && is_available && (
                    <Grid item xs={12}>
                      {renderCart()}
                    </Grid>
                  )}
                </Grid>
                {renderDescription()}
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="flex-end">
              <Button onClick={close}>ΚΛΕΙΣΙΜΟ</Button>
            </Grid>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <Dialog
        open
        onClose={close}
        fullWidth={isMobile}
        maxWidth="xl"
        sx={{ margin: 0 }}
      >
        <DialogContent sx={{ padding: "0px !important" }}>
          <Card className={classes.card}>
            {renderImage()}
            <CardContent sx={{ padding: "0px 4px" }}>
              {renderHeader()}
              <Grid
                container
                spacing={isMobile ? 0 : 2}
                marginTop={1}
                marginBottom={1}
              >
                <Grid item sm={6} xs={5}>
                  <Supplier supplier={supplier} closeMe={closeMe} />
                </Grid>
                <Grid item sm={6} xs={7}>
                  <Product product={product} />
                </Grid>
              </Grid>
              {/* <Divider style={{ marginBottom: 3 }} /> */}
              {!isAdmin && is_available && renderCart()}
              {renderDescription()}
            </CardContent>
            <CardActions>
              <Grid container justifyContent="flex-end">
                <Button onClick={close}>ΚΛΕΙΣΙΜΟ</Button>
              </Grid>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      {renderDialog()}
      {renderDescriptionModal()}
    </>
  );
}

export default ProductPopUp;
