import React from "react";
import { GeneralDialog } from "../../../General/GeneralDialog";
import { Send } from "@mui/icons-material";
import { Form, Formik } from "formik";
import MyTextField from "../../../MyTextField";
import { getFieldErrors } from "../../../../helpers/getFieldErrors";
import { LoadingButton } from "@mui/lab";
import { RequestSamplesFormValidationSchema } from "./RequestSamplesFormValidationSchema";
import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { requestSupplierSamples } from "../../../../api/suppliers";
import { useDispatch, useSelector } from "react-redux";
import { setSnackMessage } from "../../../../redux/slices/snackMessageSlice";
import { selectAuth } from "../../../../redux/slices/authSlice";
import { NavLink } from "react-router-dom";

export const RequestSamplesDialog = ({ isOpen, handleClose, supplierId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const handleSubmit = (values) => {
    console.log(values);
    const { text } = values;
    console.log("submitting:", text);
    setIsLoading(true);
    return requestSupplierSamples({
      supplierId,
      text,
    })
      .then((resp) => {
        console.log(resp);
        dispatch(
          setSnackMessage({
            text: "Το email με την εκδήλωση ενδιαφέροντός σας στάλθηκε στον προμηθευτή",
            severity: "success",
          })
        );
        setIsLoading(false);
        handleClose();
      })
      .catch((err) => {
        dispatch(
          setSnackMessage({
            text: "Συγγνώμη, δεν μπορέσαμε να στείλουμε το email με την εκδήλωση ενδιαφέροντός σας στον προμηθευτή",
            severity: "error",
          })
        );
        setIsLoading(false);
      });
  };
  
  const renderContent = () => {
    console.log(auth);
    if (!auth.logged || auth.isSupplier) {
      return (
        <Stack rowGap={3} alignItems={"center"}>
          <Typography variant="body1">
            Συγγνώμη, πρέπει να έχετε λογαριασμό καταστήματος για να μπορέσετε να ζητήσετε δείγματα από τον προμηθευτή
          </Typography>
          <NavLink to="/customers/register">Δημιουργία Λογιαριασμού</NavLink>
        </Stack>
      )
    }
    return (
      <Formik
      onSubmit={handleSubmit}
      initialValues={{ text: "" }}
      validationSchema={RequestSamplesFormValidationSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <Stack rowGap={3}>
            <Typography variant="body1">
              Θα στείλουμε email στον προμηθευτή εκ μέρους σας, ώστε να
              επικοινωνήσει μαζί σας ο ίδιος, στα στοιχεία επικοινωνίας
              που μας έχετε διαθέσει.
            </Typography>
            <MyTextField
              name="text"
              label="Το μήνυμά σας"
              id="text"
              typ="text"
              multiline
              rows={4}
              fullLiner
              {...getFieldErrors("text", errors, touched)}
            />
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              endIcon={<Send />}
              sx={{ textTransform: "none" }}
            >
              Αποστολή
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
    )
  }

  if (isOpen) {
    return (
      <GeneralDialog
        title="Ζητήστε δείγμα από τον προμηθευτή"
        content={
          renderContent()
        }
        onClose={handleClose}
      />
    );
  }
  return null;
};
