import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Form } from "formik";
import { Formik } from "formik";
import MyTextField from "../../../MyTextField";
import { makeStyles } from "@mui/styles";
import { CustomerStoreValidationSchema, SupplierStoreValidationSchema } from "./StoreValidationSchema";
import { Send } from "@mui/icons-material";
import Spinner from "../../../General/Spinner";
import { updateOneSupplierCall } from "../../../../api/suppliers";
import { updateOneCustomerCall } from "../../../../api/customer";
import { setSnackMessage } from "../../../../redux/slices/snackMessageSlice";
import AvatarEditor from "react-avatar-editor";
import MyDivider from "../../../General/MyDivider";
import { useIsMobile } from "../../../../hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
    myLink: {
        fontSize: "1.1em",
    },
    container: {
        // textAlign: "center",
        // justifyContent: "center",
    },
    buttonContainer: {
        [theme.breakpoints.down('md')]: {
            justifyContent: "center",
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: "flex-end",
        },
        margin: "40px 0px",
    },
  })
);

function EditStoreForm({
    user,
    isSupplier,
    refresh,
    disabled = false,
}) {
    const { isMobile } = useIsMobile();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [store, setStore] = useState(null);
    const [type, setType] = useState("customer");
    const [loading, setLoading] = useState(false);

    const [logoSrc, setLogoSrc] = useState(null);
    const editorRef = useRef(null);

    useEffect(() => {
        if (isSupplier) {
            const { supplier_store } = user;
            const supplier_store_copy = { ...supplier_store };
            supplier_store_copy["phone_number"] = supplier_store_copy["phone_number"].replace("+30", "");
            delete supplier_store_copy["categories"];
            delete supplier_store_copy["sub_categories"];
            setStore(supplier_store_copy);
            setType("supplier");
            console.log({ supplier_store_copy });
        }
        else {
            const { customer_store } = user;
            const customer_store_copy = { ...customer_store };
            customer_store_copy["phone_number"] = customer_store_copy["phone_number"].replace("+30", "");
            setStore({ ...customer_store_copy });
            setType("customer");
            console.log({ customer_store: user.customer_store });
        }
    }, [isSupplier])

    useEffect(() => {
        if (store && store["logo"]) {
            setLogoSrc(store["logo"]);
        }
    }, [store])

    const parseErrorMessage = (err) => {
        let msg = "Συγγνώμη, δεν μπορέσαμε να αποθηκεύσουμε τις αλλαγές σας"
        if (err?.response?.status===400) {
            const response = err.response.data;
            if (response === "Invalid tax number")
                msg = "Η πλατφόρμα εξυπηρετεί μόνο επιχειρήσεις. Παρακαλώ εισάγετε ένα έγκυρο εταιρικό ΑΦΜ και δοκιμάστε ξανά.";
            else if (response && typeof(response)==='object' && Object.keys(response).includes("tax_no")) {
                msg = "Παρακαλώ εισάγετε το έγκυρο ΑΦΜ σας";
            }
        }
        return msg;    
    }

    const submitBody = (method, id, data) => {
        setLoading(true);
        console.log("SUBMITTING::")
        console.log({ data });
        method(id, data)
            .then(response => {
                console.log(response.data);
                dispatch(setSnackMessage({
                    text: "Οι αλλαγές σας αποθηκεύτηκαν",
                    severity: "success",
                }));
                setTimeout(() => {
                    refresh();
                },1000)
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                dispatch(setSnackMessage({
                    text: parseErrorMessage(err),
                    severity: "warning",
                }));
                setLoading(false);
            })
    }

    const submit = (values) => {
        const { id } = store;
        const data = { ...values };

        if (!values["store_email"]) {
            delete data["store_email"];
        }
        console.log({data});
        let method = updateOneSupplierCall;
        if (type==="customer") {
            method = updateOneCustomerCall;
        }
        if (!values["logo"] || typeof(values["logo"])==="string") {
            delete data["logo"];
            submitBody(method, id, data);
        }
        else {
            const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
            fetch(canvas)
                .then(res => res.blob())
                .then(blob => {
                    if (data["logo"]) {
                        // setResultPreview(window.URL.createObjectURL(blob));
                        const name = data["logo"].name;
                        const file = new File([blob], name);
                        data["logo"] = file;
                    }
                    submitBody(method, id, data);
                });
        }
    }

    const updateCompanyLogo = (event, setFieldValue) => {
        const files = event.currentTarget.files;
        if (files?.length) {
            setFieldValue("logo", files[0]);
            setLogoSrc(files[0]);
        }
        else {
            setFieldValue("logo", files[0]);
            setLogoSrc("");
        }
    }

    const getFieldErrors = (name, errors, touched) => {
        if (Object.keys(errors).length) {
            const error = touched[name] && errors[name]?.length>0;
            const helperText = error ? errors[name] : " ";
            return { error, helperText }    
        }
        return { error: false, helperText: " " }
    }

    if(store) {
        return (
            <Formik
                initialValues={store}
                validationSchema={type==="supplier" ? SupplierStoreValidationSchema : CustomerStoreValidationSchema}
                onSubmit={submit}
                enableReinitialize
            >
                {({touched, errors, setFieldValue, setFieldTouched, setFieldError, ...props}) => (
                    <div className={classes.container}>
                        <Form>
                            <Grid container spacing={2} rowSpacing={2} marginTop={1} className={classes.container}>
                                <MyTextField 
                                    name="name"
                                    label="Επωνυμία Εταιρείας"
                                    id="company_name"
                                    type="text"
                                    {...getFieldErrors("name", errors, touched)}
                                    disabled={disabled}
                                    />
                                <MyTextField 
                                    name="address"
                                    label="Διεύθυνση"
                                    id="address"
                                    type="text"
                                    {...getFieldErrors("address", errors, touched)}
                                    disabled={disabled}
                                />
                                <MyTextField 
                                    name="city"
                                    label="Πόλη"
                                    id="city"
                                    type="text"
                                    {...getFieldErrors("city", errors, touched)}
                                    disabled={disabled}
                                />
                                <MyTextField 
                                    name="state"
                                    label="Νομός"
                                    id="state"
                                    type="text"
                                    {...getFieldErrors("state", errors, touched)}
                                    disabled={disabled}
                                />
                                <MyTextField 
                                    name="tax_no"
                                    label="ΑΦΜ" 
                                    id="tax_no" 
                                    type="text"
                                    {...getFieldErrors("tax_no", errors, touched)}
                                    disabled={disabled || type === "customer"}
                                />
                                <MyTextField 
                                    name="post_code"
                                    label="Ταχυδρομικός Κώδικας"
                                    id="post_code"
                                    type="text"
                                    {...getFieldErrors("post_code", errors, touched)}
                                    disabled={disabled || type === "customer"}
                                />
                                <MyTextField 
                                    name="tax_authority"
                                    label="ΔΟΥ"
                                    id="taxauth"
                                    type="text"
                                    {...getFieldErrors("tax_authority", errors, touched)}
                                    disabled={disabled || type === "customer"}
                                />
                                <MyTextField 
                                    name="phone_number"
                                    label="Τηλέφωνο Επικοινωνίας"
                                    id="phone_number"
                                    type="tel"
                                    {...getFieldErrors("phone_number", errors, touched)}
                                    disabled={disabled}
                                    startAdornment={
                                        <Typography
                                          variant="body1"
                                          sx={{ color: "text.secondary", marginRight: 1 }}
                                        >
                                          +30
                                        </Typography>
                                    } 
                                />
                                
                                {/* {type==="supplier" &&
                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel htmlFor="logo">Εικόνα επιχείρησης</FormLabel>
                                            </Grid>
                                            <Box width="100%"/>
                                            <Grid item xs={12}>
                                                <Input
                                                    fullWidth
                                                    name="logo"
                                                    id="logo"
                                                    type="file"
                                                    variant="outlined"
                                                    inputProps={{ accept: "image/png, image/jpeg" }}
                                                    onChange={(event) => updateCompanyLogo(event, setFieldValue)}
                                                />
                                            </Grid>
                                            {logoSrc &&
                                                <Grid item xs={12}>
                                                    <AvatarEditor
                                                        image={logoSrc}
                                                        ref={editorRef}
                                                        width={400}
                                                        height={300}
                                                        border={50}
                                                        color={[255, 255, 255, 0.6]} // RGBA
                                                        scale={1}
                                                        rotate={0}
                                                        // onImageChange={triggerCrop}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>                            
                                } */}
                                {type==="supplier" &&
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent={'space-between'}>
                                                <Grid item>
                                                    <Typography variant="subtitle1">
                                                        Εικόνα επιχείρησης
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        component="label"
                                                        startIcon={<PhotoCameraIcon />}
                                                        size="small"
                                                        disabled={disabled}
                                                        >
                                                        Ενημερωση
                                                        <input
                                                            type="file"
                                                            name="image"
                                                            accept="image/png, image/jpeg"
                                                            hidden
                                                            onChange={(event) => updateCompanyLogo(event, setFieldValue)}
                                                            disabled={disabled}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <MyDivider marginBottom={1} />
                                        {logoSrc &&
                                                <Grid item xs={12} marginTop={-5}>
                                                    <AvatarEditor
                                                        style={{ ...(isMobile && { marginLeft: "-25px" }) }}
                                                        image={logoSrc}
                                                        ref={editorRef}
                                                        width={isMobile ? 300 : 400}
                                                        height={isMobile ? 225 : 300}
                                                        border={50}
                                                        color={[255, 255, 255, 0.6]} // RGBA
                                                        scale={1}
                                                        rotate={0}
                                                        // onImageChange={triggerCrop}
                                                    />
                                                </Grid>
                                        }
                                        {!logoSrc &&
                                            <Typography variant="subtitle2">
                                                Δεν έχετε ανεβάσει ακόμα εικόνα επιχείρησης
                                            </Typography>
                                        }
                                    </>
                                }
                            </Grid>
                            <Grid className={classes.buttonContainer} container>
                                <LoadingButton
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    loading={loading}
                                    fullWidth={isMobile}
                                    startIcon={<Send />}
                                    disabled={disabled}
                                >
                                    Ενημερωση
                                </LoadingButton>
                            </Grid>
                        </Form>
                    </div>
                )}

            </Formik>
        )
    }
    return <Spinner />;
}

export default EditStoreForm;