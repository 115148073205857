import React from 'react'
import { GeneralDialog } from '../../../General/GeneralDialog';
import OfferForm from './OfferForm';

function OfferFormDialog({ hideMe, product }) {

    return (
        <GeneralDialog
            title="Προσθήκη προσφοράς"
            content={<OfferForm product={product} hideMe={hideMe} />}
            onClose={hideMe}
            maxWidth="md"
        />
    )
}

export default OfferFormDialog;