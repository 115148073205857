import axios from "axios";
import config from "./config";
import { buildAuthHeader, getRequest, parseFormDataFromJson } from "./helpers";

axios.defaults.baseURL = config.apiUrl;

// unused
export const getProductsBySubCategoriesCall = (subcategories) => {
    const requestUrl = `/products`;
    const params = {
        subcategories: subcategories.join(",",),
    };
    return axios.get(requestUrl, { params });
}

export const getSupplierCatalog = (id) => {
    const requestUrl = `/suppliers/${id}/catalog`;
    return axios.get(requestUrl);
}

export const getSupplierCatalog2 = (id) => {
    const requestUrl = `/suppliers/${id}/catalog`;
    return getRequest({ requestUrl, params: {} });
}

export const getSupplierProductsFiltered = (supplier_id, params) => {
    const requestUrl = `/suppliers/${supplier_id}/catalog`;
    return getRequest({
        requestUrl,
        params: {
            include_products: 1,
            ...params,
        }
    });
}

export const getProductsCall = ({type, categories, start, end }) => {    
    const requestUrl = "/products";
    const params = { start, end };
    if (type==="all") {
        return axios.get(requestUrl, { params });
    }
    console.log(categories);
    params.categories = categories;
    return axios.get(requestUrl, { params });
}

export const createProductCall = (id="dummy", body_json) => {
    const requestUrl = `/products`;
    const body_form = parseFormDataFromJson(body_json);
    const headers = buildAuthHeader();
    return axios.post(requestUrl, body_form, { headers });
}

export const putProductCall = (id, body_json) => {
    const requestUrl = `/products/${id}`;
    const body_form = parseFormDataFromJson(body_json);
    const headers = buildAuthHeader();
    return axios.put(requestUrl, body_form, { headers })
}

export const deleteProductCall = (id) => {
    const requestUrl = `/products/${id}`;
    const headers = buildAuthHeader();
    return axios.delete(requestUrl, { headers });
}

export const addOfferCall = (body_json) => {
    const requestUrl = `/offers`;
    const headers = buildAuthHeader();
    const body_form = parseFormDataFromJson(body_json);
    return axios.post(requestUrl, body_form, { headers });
}

export const updateOfferCall = (id, body_json) => {
    const requestUrl = `/offers/${id}`;
    const headers = buildAuthHeader();
    const body_form = parseFormDataFromJson(body_json);
    return axios.put(requestUrl, body_form, { headers });
}

export const deleteOfferCall = (id) => {
    const requestUrl = `/offers/${id}`;
    const headers = buildAuthHeader();
    return axios.delete(requestUrl, { headers });
}

export const getTopProductsCall = ({ kind="global" }) => {
    const headers = buildAuthHeader();
    let requestUrl = `/suggestions/personal?kind=products`;
    if (kind==="global") requestUrl = `/suggestions?kind=products`;
    return axios.get(requestUrl, { headers });
}

export const getFavProductsCall = () => {
    const headers = buildAuthHeader();
    const requestUrl = `/products/favs`;
    return axios.get(requestUrl, { headers });
}

export const getOneProductCall = (id) => {
    const requestUrl = `/products/${id}`;
    return axios.get(requestUrl);
}

export const updateFavProductsCall = (type, product_id) => {
    const requestUrl = `/products/favs/${product_id}`;
    const headers = buildAuthHeader();

    if (type==="add") {
        return axios.post(requestUrl, {}, { headers });
    }
    return axios.delete(requestUrl, { headers });
}

export const updateProductAvailabilityCall = (product_id, is_available) => {
    const requestUrl = `/products/${product_id}/availability`;
    const headers = buildAuthHeader();
    if (is_available) {
        return axios.post(requestUrl, {}, { headers });
    }
    return axios.delete(requestUrl, { headers });
}