import React from "react";
import { useSearchParams } from "react-router-dom";
import { SuperUserOneSupplier } from "./SuperUserOneSupplier";
import { SuppliersTable } from "./SuppliersTable";

export const SuppliersSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  if (id) return <SuperUserOneSupplier id={id} />;
  return <SuppliersTable />;
};
