import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { queriesKeys } from "../api/queriesKeys";
import { getAllCustomersCall } from "../api/customer";
import { getOneSupplierTaxNumberOffers } from "../api/suppliers";

export const useOneSupplierTaxNumberOffersData = (supplier_id) => {

  const queryClient = useQueryClient();
  const refresh = () => queryClient.invalidateQueries(queriesKeys["getOneSupplierTaxNumberOffers"]);

  const { data: taxNumberOffers, isLoading: areTaxNumberOffersLoading } =
    useQuery(
      [queriesKeys["getOneSupplierTaxNumberOffers"], supplier_id],
      () => getOneSupplierTaxNumberOffers(supplier_id),
      {
        enabled: !!supplier_id,
      }
    );

  const { data: customers, isLoading: areCustomersLoading } = useQuery(
    [queriesKeys["getCustomers"]],
    () => getAllCustomersCall()
  );

  const [taxNumbers, setTaxNumbers] = React.useState([]);


  useEffect(() => {
    if (customers) {
      setTaxNumbers([...new Set(customers.map((customer) => customer.tax_no))]);
    }
  }, [customers]);

  return {
    taxNumberOffers,
    taxNumbers,
    isLoading: areTaxNumberOffersLoading || areCustomersLoading,
    refresh,
  };
};
