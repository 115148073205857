import { Extension, Payment } from '@mui/icons-material';
import {  Divider, Grid, Typography, Chip, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react'
import ScaleIcon from '@mui/icons-material/Scale';
import { formatCurrency, formatPrice } from '../../../../helpers/price';
import { useIsMobile } from '../../../../hooks/useIsMobile';

function Product({ product, closeMe }) {
    const { isMobile } = useIsMobile();
    const [discount, setDiscount] = useState(0);

    useEffect(() => {
        if (product && product.offer && !product.offer.is_expired) {
            const offer_price = offer.price;
            let rate = (offer_price-price)/price;
            rate = Math.round((Math.round(rate*100)/100)*100) * -1;
            setDiscount(`Έκπτωση ${rate}%`);
        }
    }, [product])

    if (!product) return null;

    console.log({ product });

    const {
        is_available, 
        price,
        price_currency, 
        package_quantity,
        min_quantity, 
        max_quantity,
        offer,
        hyper_price,
        hyper_min_quantity,
        metric_rate,
    } = product;

    const renderLine = (icon, content) => {
        return (
            <Grid container alignContent={'center'} alignItems='center' justifyContent={"center"}>
                <Grid item xs={1}>
                    {icon}
                </Grid>
                <Grid item xs={11} paddingLeft={isMobile ? 2 : 1}>
                    <Grid container>
                        {content}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const render_availability = () => {
        let severity = "warning";
        let text = "Μη διαθέσιμο"
        if (is_available) {
            severity = "success";
            text = "Διαθέσιμο";
        }

        return (
            <Chip label={text} color={severity} variant="contained" size="small" />
        )
    }

    const renderOffer = () => {
        return (
            <Chip label={discount} color={'success'} variant="contained" size="small" />
        )
    }

    const renderPackage = () => {
        let content = "Πώληση ανά μονάδα"
        if (package_quantity>1) {
            content = `Συσκευασία: ${package_quantity}/τεμ`
        }
        const icon = <Extension />;
        return renderLine(icon, <Typography variant="body2" sx={{ marginLeft: -0.5 }}>{content}</Typography>);
    }

    const renderOnePrice = ({ price: price_, strike=false, showCurrency=true, big=false, xs="auto" }) => {
        return (
            <Grid item xs={xs}>
                <Typography variant={big ? "h6" : "body2"} color={strike ? "default" : "info.main"} paddingLeft={1} sx={{ textDecoration: strike ? "line-through" : "auto", paddingLeft: 0 }}>
                    {formatPrice(price_)}
                    {showCurrency &&
                        formatCurrency(price_currency)
                    }
                </Typography>
            </Grid>
        )

    }

    const renderPriceVal = () => {
        if (offer && !offer.is_expired) {
            const { price: offer_price} = offer;
            return (
                <Grid container spacing={1} alignContent="center" alignItems={"center"} justifyContent={isMobile ? "flex-end" : "center"}>
                    { renderOnePrice({ price, strike: true, big: true }) }
                    { renderOnePrice({ price: offer_price, big: true, }) }
                </Grid>
            )
        }
        return (
            <Grid container width={1} spacing={1} alignContent="center" alignItems={"center"} justifyContent={isMobile ? "flex-end" : "center"}>
                {renderOnePrice({ price, strike: false, big: true })}
            </Grid>
        )
    }

    const renderPrice = () => {
        const icon = <Payment />;
        const has_offer = offer && !offer.is_expired;
        if (!hyper_price) {
            return (
                <Grid container alignItems="center">
                    <Grid item>
                        <Grid container alignContent={'center'} alignItems='center' justifyContent={"center"}>
                            <Grid item xs={1}>
                                {icon}
                            </Grid>
                            <Grid item xs={11} paddingLeft={2}>
                                <Typography variant={"body2"} style={{ paddingLeft: "5px" }}>
                                    Τιμή τεμ.:
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        { renderPriceVal() }
                    </Grid>
                </Grid>
            )
        }
        let upper_limit_message = max_quantity!==1000000 ? `${hyper_min_quantity}-${max_quantity}` : `${hyper_min_quantity}+`
        return (
            <Grid container justifyContent="center" marginTop={1}>
                <Grid item xs={12}>
                    <Grid container alignItems={"center"} justifyContent="center" spacing={1}>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item sm={6} xs={6}>
                                    <Grid container alignItems="center" justifyContent={"center"}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" align="center">
                                                {min_quantity}-{hyper_min_quantity} τεμ.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            { renderOnePrice({ price, strike: false, showCurrency: true, big: true }) }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <Grid container alignItems={"center"} justifyContent={"center"}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" align="center">
                                                {upper_limit_message} τεμ.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            { renderOnePrice({ price: hyper_price, strike: false, showCurrency: true, big: true }) }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderMetricRate = () => {
        if (!metric_rate) return null;
        const { rate, metric } = metric_rate;
        const icon = <ScaleIcon />;
        const content = (
            <Grid item marginLeft={-0.5}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography variant="body2" sx={{ paddingLeft: 0 }}>
                            Τιμή {metric==="lt" ? "Λίτρου" : "Κιλού"}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Typography variant="body2" sx={{ paddingLeft: 0 }}>
                                {formatPrice(rate)}
                                {formatCurrency(price_currency)}
                            </Typography>
                            <Typography variant="body2" sx={{ paddingLeft: 0.2 }}>
                                /
                            </Typography>
                            <Typography variant="body2" sx={{ paddingLeft: 0.2 }}>
                                {metric==="lt" ? "L" : metric}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
        return (
            renderLine(icon, content)
        )
    }

    const renderChips = () => {
        const has_offer = offer && !offer.is_expired;
        if (isMobile) {
            return (
                <Grid container justifyContent={"center"}>
                        <Grid item xs={6}>
                            <Grid container justifyContent={"center"}>
                                { render_availability() }
                            </Grid>
                        </Grid>
                        {has_offer &&
                            <Grid item xs={6}>
                                <Grid container justifyContent={"center"}>
                                    { renderOffer() }
                                </Grid>
                            </Grid>
                        }
                </Grid>
            )
        }
        return (
            <Grid container spacing={1} justifyContent={hyper_price ? "center" : "auto"}>
                <Grid item>
                    { render_availability() }
                </Grid>
                {has_offer &&
                    <Grid item>
                        { renderOffer() }
                    </Grid>
                }
            </Grid>
        )
    }


    return (
        // <Paper elevation={1}>
            <Stack justifyContent="center">
                <Typography sx={{ paddingTop: 1 }} variant="body1" align="center">
                    Προϊόν
                </Typography>
                <Divider sx={{ width: 1, marginBottom: 1 }} />
                <Stack spacing={1}>
                    { renderChips() }
                    { renderPackage() }
                    { renderMetricRate() }
                    { renderPrice() }
                </Stack>
            </Stack>
        // </Paper>
    )
}

export default Product;