import React, { useEffect } from 'react'
import Products from "../../../Profile/Supplier/Products/Products";
import { makeStyles } from "@mui/styles";
import { getOneSupplier, selectOneSupplier } from '../../../../redux/slices/oneSupplierSlice';
import { Alert, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../General/Spinner';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import Catalog2 from '../../../Profile/Supplier/Catalog/Catalog2';
import { queriesKeys } from '../../../../api/queriesKeys';
import { getSupplierCatalog2 } from '../../../../api/products';
import { useQuery } from 'react-query';

function ProductsArea({ setProduct, supplier_store }) {
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();

    useEffect(() => {
        dispatch(getOneSupplier(supplier_store.id));
        // dispatch(getOneSupplierOffers(supplier_store.id))
    }, [])

    const { data: supplier, status } = useSelector(selectOneSupplier);

    const { data: catalog, isLoading: isCatalogLoading } = useQuery(
        [queriesKeys['getOneSupplierCatalog'], supplier?.id],
        () => getSupplierCatalog2(supplier?.id), {
          enabled: !!supplier?.id,
          refetchOnWindowFocus: false,
        }
      )

    if (status==="loading") {
        return (
            <Spinner />
        )
    }

    if (supplier?.has_products) {
        if (isMobile) {
            return (
                <>
                    <Catalog2 isAdmin catalog={catalog} isCatalogLoading={isCatalogLoading} width={200} />
                    <Grid container>
                        <Grid item xs={12}>
                            <Products isAdmin setProduct={setProduct}  />
                        </Grid>
                    </Grid>
                    {/* { renderMessage() } */}
                </>

            )
        }
        return (
            <Grid container position="relative" spacing={1}>
                <Grid item xl={2} lg={2.5} md={3} minWidth={250} marginTop={-1}>
                    <Catalog2 isAdmin catalog={catalog} isCatalogLoading={isCatalogLoading} />
                </Grid>
                <Grid item xs>
                    <Products isAdmin setProduct={setProduct} />
                </Grid>
            </Grid>
        )
    }
    return (
        <Grid container justifyContent="center">
            <Grid item>
                <Alert severity="info">
                    Δεν έχετε δημοσιοποίησει ακόμα τα προϊόντα σας
                </Alert>
            </Grid>
            {/* { renderMessage() } */}
        </Grid>
    )

}

export default ProductsArea;