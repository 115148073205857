import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    title: "",
    message: "",
    onConfirm: null,
}

export const confirmationDialogSlice = createSlice({
    name: "confirmationDialog",
    initialState,
    reducers: {
        setConfirmationDialog: (state, action) => {
            const { title, message, onConfirm } = action.payload;
            state.title = title;
            state.message = message;
            state.onConfirm = onConfirm; 
        },
        closeConfirmationDialog: (state) => {
            state.title = "";
        }
    }
});

export const { setConfirmationDialog, closeConfirmationDialog } = confirmationDialogSlice.actions;

export const selectConfirmationDialog = (state) => state.confirmationDialog;

export default confirmationDialogSlice.reducer;