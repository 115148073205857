import {
  Alert,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { queriesKeys } from "../../api/queriesKeys";
import { getOneSupplierCall2 } from "../../api/suppliers";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Description } from "../Admin/Supplier/Description";
import ProductsTabBody from "../Admin/Supplier/Products/ProductsTabBody";
import StoreFormContainer from "../Admin/Supplier/Store/StoreFormContainer";
import Spinner from "../General/Spinner";
import TabPanel from "../General/TabPanel";
import { SmallSupplierInfo } from "../Profile/Supplier/SmallSupplierInfo";
import CartsTable from "./CartsTable";
import { OneSupplierTaxNumberOffers } from "../Admin/Supplier/TaxNoOffers/OneSupplierTaxNumberOffers";
import { HighlightsForm } from "../Admin/Supplier/HighlightsForm";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const SuperUserOneSupplier = ({ id }) => {
  const { isMobile } = useIsMobile();
  const queryClient = useQueryClient();
  const {
    data: supplier_store,
    isLoading,
    isError,
  } = useQuery(
    [queriesKeys["getOneSupplier"], id],
    () => getOneSupplierCall2(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );
  const [tab, setTab] = useState(0);

  console.log(supplier_store);

  const refresh = () =>
    queryClient.invalidateQueries([queriesKeys["getOneSupplier"]]);

  if (isLoading) return <Spinner />;
  if (isError) return <Alert severity="error">Supplier not found.</Alert>;
  if (!supplier_store) return null;
  return (
    <Stack spacing={2} justifyContent="center" sx={{ width: 1 }}>
      <SmallSupplierInfo supplier={supplier_store} taxNumberOffer={null} />
      <Tabs value={tab} aria-label="basic tabs example" centered>
        <Tab
          label={"Παραγγελίες"}
          sx={{
            textTransform: "none",
            fontSize: isMobile ? "medium" : "large",
          }}
          onClick={() => setTab(0)}
          {...a11yProps(0)}
        />
        <Tab
          label={"Προϊόντα"}
          sx={{
            textTransform: "none",
            fontSize: isMobile ? "medium" : "large",
          }}
          onClick={() => setTab(1)}
          {...a11yProps(1)}
        />
        <Tab
          label={"Στοιχεία προμηθευτή"}
          sx={{
            textTransform: "none",
            fontSize: isMobile ? "medium" : "large",
          }}
          onClick={() => setTab(2)}
          {...a11yProps(2)}
        />
        <Tab
          label={"Περιγραφή"}
          sx={{
            textTransform: "none",
            fontSize: isMobile ? "medium" : "large",
          }}
          onClick={() => setTab(3)}
          {...a11yProps(3)}
        />
        <Tab
          label={"Χαρακτηριστικά"}
          sx={{
            textTransform: "none",
            fontSize: isMobile ? "medium" : "large",
          }}
          onClick={() => setTab(4)}
          {...a11yProps(4)}
        />
        <Tab
          label={"Εκπτώσεις ΑΦΜ"}
          sx={{
            textTransform: "none",
            fontSize: isMobile ? "medium" : "large",
          }}
          onClick={() => setTab(5)}
          {...a11yProps(5)}
        />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <CartsTable supplier={id} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ProductsTabBody supplier_store={supplier_store} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <StoreFormContainer supplier_store={supplier_store} refresh={refresh} />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Description
          isSuperUser={true}
          supplier_store={supplier_store}
          refresh={refresh}
        />
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <HighlightsForm
          isSuperUser={true}
          supplier_store={supplier_store}
          refresh={refresh}
        />
      </TabPanel>
      <TabPanel value={tab} index={5}>
          <OneSupplierTaxNumberOffers
            supplier_store={supplier_store}
            refresh={refresh}
          />
      </TabPanel>
    </Stack>
  );
};
