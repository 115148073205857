import { Card, Box, Button, CardMedia, Typography, Avatar, Grid } from '@mui/material'
import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    buttonCard: {
        "&:hover": {
            bgcolor: blue[200],
        },
        position: 'relative !important',
        textTransform: "none !important",
        padding: "0 !important",
        backgroundColor: `${blue[200]} !important`,
        width: "100% !important",
    },
    linearGradient: {
        background: "linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent)",
    }
  })
);

function CardOverlay({ image, title, subtitle, href, onClick }) {
    const classes = useStyles();

    const renderImage = () => {
        if (image) {
            return (
                <CardMedia
                    component="img"
                    height="175"
                    image={ image }
                    width={1}
                />
            )
        }
        return (
            <CardMedia
                height="175"
                width={"100%"}
            >
                <Avatar sx={{ height: 175, width: "inherit", bgcolor: blue[200], borderRadius: "0px" }} >
                    {title}
                </Avatar>
            </CardMedia>
        )
    }

    const onClickHandle = () => {
        if (onClick) {
            onClick();
            return;
        }
        // navigate(href);
        window.location.href = href;
    }

    return (
        <Card draggable={false}>
            <Button
                draggable={false}
                component={Box}
                onClick={onClickHandle}
                className={classes.buttonCard}
            >
                { renderImage() }
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        // bgcolor: 'rgba(0, 0, 0, 0.54)',
                        color: 'white',
                        alignItems: "flex-end"
                    }}
                    className={classes.linearGradient}
                >
                    <Grid container padding={1} height={1} alignItems="flex-end">
                        <Grid item xs={12}>
                            <Typography variant="body1">{title}</Typography>
                        </Grid>
                        {subtitle &&
                            <Grid item xs={12}>
                                <Typography variant="body2">{subtitle}</Typography>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Button>
        </Card>
    )
}

export default CardOverlay;