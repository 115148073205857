import React from "react";
import Header from "../components/Home/Header/Header";
import GlobalComponents from "../components/General/GlobalComponents";
import WidthLimiter from "./WidthLimiter";
import { Footer } from "../components/Home/Footer/Footer";
import { useIsMobile } from "../hooks/useIsMobile";
import TopNavbar from "../components/Home/Header/TopNavbar";
import { SecondarySearchBar } from "../components/Home/Header/SecondarySearchBar";
import { zIndexes } from "../zIndexes";

function CustomerSkeleton({
  children,
  headerProps,
  primaryNavbarProps,
  secondaryNavbarProps,
}) {
  const { isMobile } = useIsMobile();
  return (
    <>
      <div style={{ paddingBottom: "150px", position: "relative" }}>
        <TopNavbar {...primaryNavbarProps} />
        <div style={{ marginTop: "0px" }} />
        <Header headerProps={headerProps} />
        <div style={{ marginTop: "-65px" }} />

        <TopNavbar secondary {...secondaryNavbarProps} />

        {isMobile && <SecondarySearchBar />}

        <WidthLimiter marginTop={isMobile ? 2 : 3}>{children}</WidthLimiter>

        <GlobalComponents />
      </div>
      <Footer />
    </>
  );
}

export default CustomerSkeleton;
