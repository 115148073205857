import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategoriesCall } from "../../api/general";
import { productsPaginationStep } from "../../helpers/paginationSteps";

const initialState = {
  all: [],
  all_ids: [],
  selected_id: null,
  selected_nane: null,
  all_selected: true,
  status: "idle",
  keyword: null,
  products_pagination_start: 1,
  products_pagination_end: productsPaginationStep,
};

export const getCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    try {
      const response = await getCategoriesCall();
      return response.data;
    } catch (err) {
      return [];
    }
  }
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    // add: (state, action) => {
    //     const id = parseInt(action.payload);
    //     if (state.all_selected) {
    //         state.selected_ids = [id]
    //     }
    //     else {
    //         state.selected_ids = state.selected_ids.concat(id);
    //     }
    //     state.all_selected = false;
    // },
    // remove: (state, action) => {
    //     const id = parseInt(action.payload);
    //     const index = state.selected_ids.indexOf(id);
    //     state.selected_ids = state.selected_ids.slice(0, index).concat(state.selected_ids.slice(index+1, state.selected_ids.length));
    //     state.all_selected = false;
    // },
    select: (state, action) => {
      const { id, name } = action.payload;
      console.log({ id, name });
      state.selected_id = id;
      state.selected_name = name;
      state.products_pagination_start = 1;
      state.products_pagination_end = productsPaginationStep;
      state.all_selected = false;
    },
    clear: (state) => {
      state.selected_id = null;
      state.selected_name = null;
      state.products_pagination_start = 1;
      state.products_pagination_end = productsPaginationStep;
      state.all_selected = true;
    },
    go_to_products_next_page: (state) => {
      state.products_pagination_start += productsPaginationStep;
      state.products_pagination_end += productsPaginationStep;
    },
    set_keyword: (state, action) => {
      const key = action.payload;
      console.log("setting global keyword:", key);
      state.keyword = key;
    },
    clear_keyword: (state) => {
      state.keyword = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.all = action.payload;
        state.all_ids = action.payload.map((cat) => cat.id);
      });
  },
});

export const {
  select,
  clear,
  set_keyword,
  clear_keyword,
  go_to_products_next_page,
} = categoriesSlice.actions;

export const selectCategories = (state) => state.categories;

export default categoriesSlice.reducer;
