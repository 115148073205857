import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { formatCurrency, formatPrice } from "../../helpers/price";
import {
  interactive_add,
  interactive_subtract,
  remove_product,
} from "../../redux/slices/cartSlice";
import { setSnackMessage } from "../../redux/slices/snackMessageSlice";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import QuantityButtons from "../General/QuantityButtons";
import { setConfirmationDialog } from "../../redux/slices/confirmationDialogSlice";

const CartProduct = ({ product, showDeletionModal }) => {
  const dispatch = useDispatch();
  if (!product) return null;

  const { product_info, quantity, cost, costCase, normal_cost } = product;

  if (!product_info) return null;

  const { id, name, price_currency } = product_info;

  const add = () => {
    const { product_info, quantity } = product;
    const { id, max_quantity, package_quantity } = product_info;
    const new_quantity = quantity + package_quantity;
    if (new_quantity > max_quantity) {
      dispatch(
        setSnackMessage({
          text: `Η μέγιστη δυνατή παραγγελία για το προϊόν είναι ${max_quantity} μονάδες`,
        })
      );
      return;
    } else {
      const payload = {
        product_id: id,
      };
      dispatch(interactive_add(payload));
    }
  };

  const subtract = () => {
    const { product_info, quantity } = product;
    const { id, min_quantity, package_quantity } = product_info;
    const new_quantity = quantity - package_quantity;
    if (new_quantity < min_quantity) {
      dispatch(
        setSnackMessage({
          text: `Η ελάχιστη δυνατή παραγγελία για το προϊόν είναι ${min_quantity} μονάδες`,
        })
      );
      return;
    } else {
      const payload = {
        product_id: id,
      };
      dispatch(interactive_subtract(payload));
    }
  };

  const renderPrice = ({ cost, normal_cost, costCase, price_currency }) => {
    if (costCase === "normal") {
      return (
        <Typography variant="body1">
          {formatPrice(cost)}
          {formatCurrency(price_currency)}
        </Typography>
      );
    } else {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="body1" color={"info.main"}>
              {formatPrice(cost)}
              {formatCurrency(price_currency)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" sx={{ textDecoration: "line-through" }}>
              {formatPrice(normal_cost)}
              {formatCurrency(price_currency)}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const removeProduct = () => {
      dispatch(remove_product({ product_id: id }));
  }
  const preDeleteProduct = () => {
      dispatch(setConfirmationDialog({
          title: "Είστε σίγουροι ότι θέλετε να διαγράψετε το προϊόν από το καλάθι σας;",
          onConfirm: removeProduct,
      }))
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={10}>
            <Typography
              variant="caption"
              style={{ fontWeight: "bold", lineHeight: "1px" }}
            >
              {name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent={"flex-end"}>
              <IconButton
                onClick={preDeleteProduct}
                sx={{ padding: "4px" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent={"space-between"}
          alignContent="center"
          alignItems={"center"}
        >
          <Grid item paddingTop={0.5} xs={5}>
            <QuantityButtons
              onAdd={() => add(product)}
              onSubtract={() => subtract(product)}
              quantity={quantity}
              size={"small"}
            />
          </Grid>
          <Grid item xs={7}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                {renderPrice({ cost, normal_cost, costCase, price_currency })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CartProduct;
