import { Alert, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getSupplierProductsFiltered } from "../../../../api/products";
import { queriesKeys } from "../../../../api/queriesKeys";
import { scrollToHeader } from "../../../../helpers/scrollToHeader";
import { selectCart } from "../../../../redux/slices/cartSlice";
import { selectOneSupplier } from "../../../../redux/slices/oneSupplierSlice";
import Spinner from "../../../General/Spinner";
import ProductRow from "../Products/ProductRow";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import Scrollable from "../../../General/Scrollable";
import { RequestSamples } from "../../Supplier/RequestSamples/RequestSamples";

function Products({ isAdmin, setProduct }) {
  const { isMobile } = useIsMobile();
  const { data: supplier } = useSelector(selectOneSupplier);
  const { tuples } = useSelector(selectCart);

  const [searchParams, setSearchParams] = useSearchParams();
  const [queryConfig, setQueryConfig] = useState(null);

  const { data, isLoading, isError, isFetching } = useQuery(
    [queriesKeys["getOneSupplierProductsFiltered"], supplier?.id, queryConfig],
    () => getSupplierProductsFiltered(supplier?.id, queryConfig),
    {
      enabled: !!supplier?.id,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const {
    data: offers,
    isLoading: offersLoading,
    isError: offersError,
    isFetching: offersFetching,
  } = useQuery(
    [queriesKeys["getOneSupplierOffers"], supplier?.id],
    () =>
      getSupplierProductsFiltered(supplier?.id, {
        per_page: -1,
        category: "offers",
      }),
    {
      enabled: !!supplier?.id,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const setPage = (page) => {
    setQueryConfig({
      ...queryConfig,
      page,
    });
    scrollToHeader();
  };

  useEffect(() => {
    const category = searchParams.get("category");
    const sub_category = searchParams.get("sub_category");
    const page = searchParams.get("pagi_page") || 1;
    const per_page = 30;
    setQueryConfig({
      category,
      sub_category,
      page,
      per_page,
    });
  }, [searchParams]);

  const renderCategoriesHeader = () => {
    if (isLoading) return null;
    if (data?.sub_category) {
      const { name } = data.sub_category;
      return <Typography variant="h6" paddingLeft={1}>{name}</Typography>;
    }
    if (data?.category) {
      const { name } = data.category;
      return <Typography variant="h6" paddingLeft={1}>{name}</Typography>;
    }
    return <Typography variant="h6" paddingLeft={1}>Όλα τα προϊόντα</Typography>;
  };

  const renderOffers = () => {
    if (!!queryConfig?.category || !!queryConfig?.sub_category)
      return null;
    if (offersLoading) {
      return null;
    }
    if (isError) {
      return null;
    }
    if (offers?.data?.length) {
      return (
        <Stack spacing={1} paddingBottom={2}>
          <Typography variant="h6" paddingLeft={1}>Προσφορές</Typography>
          <Scrollable maxHeight={400}>
            <Grid container>
              {offers?.data?.map((product) => (
                <Grid item xs={12} margin={0.2}>
                  <ProductRow
                    isAdmin={isAdmin}
                    setProduct={setProduct}
                    product={product}
                    key={product.id}
                    tuple={tuples?.[product.id]}
                    supplierId={supplier.id}
                    // background="inherit"
                  />
                </Grid>
              ))}
            </Grid>
          </Scrollable>
        </Stack>
      );
    }
    return null;
  };
  const renderProductsList = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (isError) {
      return (
        <Alert severity="error">Ο κατάλογος του προμηθευτή δεν βρέθηκε</Alert>
      );
    }
    if (isAdmin && !data?.data?.length) {
      return (
        <Alert severity="info">
          Δεν έχετε δημοσιοποίησει ακόμα προϊόντα σε αυτήν την (υπό)κατηγορία
        </Alert>
      );
    }
    return (
      <Grid container>
        {data?.data?.map((product) => (
          <Grid item xs={12} margin={0.2}>
            <ProductRow
              isAdmin={isAdmin}
              setProduct={setProduct}
              product={product}
              key={product.id}
              tuple={tuples?.[product.id]}
              supplierId={supplier.id}
              // background="inherit"
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const getPaginButtonForPage = (i) => (
    <Grid item key={i}>
      <Button
        onClick={() => setPage(i)}
        variant={data?.page == i ? "contained" : "outlined"}
        sx={{ width: isMobile ? "50px" : "64px", minWidth: 0 }}
      >
        {i}
      </Button>
    </Grid>
  );

  const renderPaginationOptions = () => {
    if ((isLoading && !isFetching) || isError) return null;
    if (!isLoading && data?.num_pages <= 1) return null;
    const lim = isMobile ? 5 : 10;
    const neighbourhoodStep = 1;
    const buttons = [];
    if (data?.num_pages <= lim) {
      for (let i = 1; i <= data?.num_pages; i++) {
        buttons.push(getPaginButtonForPage(i));
      }
    } else {
      // in the beginning area
      if (data?.page <= 2) {
        for (let i = 1; i <= 3; i++) {
          buttons.push(getPaginButtonForPage(i));
        }
        buttons.push(
          <Grid item key={"last_dots"}>
            <Typography variant="h6">...</Typography>
          </Grid>
        );
        buttons.push(getPaginButtonForPage(data?.num_pages));
      }
      // somewhere in the middle area
      if (2 < data?.page && data?.page <= data?.num_pages - 2) {
        buttons.push(getPaginButtonForPage(1));
        buttons.push(
          <Grid item key={"first_dots"}>
            <Typography variant="body1">...</Typography>
          </Grid>
        );
        for (
          let i = data?.page - neighbourhoodStep;
          i <= data?.page + neighbourhoodStep;
          i++
        ) {
          buttons.push(getPaginButtonForPage(i));
        }
        buttons.push(
          <Grid item key={"last_dots"}>
            <Typography variant="body1">...</Typography>
          </Grid>
        );
        buttons.push(getPaginButtonForPage(data?.num_pages));
      }

      // in the end area
      if (data?.page >= data?.num_pages - 1) {
        buttons.push(getPaginButtonForPage(1));
        buttons.push(
          <Grid item key={"first_dots"}>
            <Typography variant="h6">...</Typography>
          </Grid>
        );
        for (let i = data?.page - 1; i <= data?.num_pages; i++) {
          buttons.push(getPaginButtonForPage(i));
        }
      }
    }
    return (
      <Stack spacing={1} paddingTop={2}>
        <Grid
          container
          width={1}
          spacing={1}
          alignItems="center"
          justifyContent="center"
          flexWrap={"nowrap"}
        >
          {buttons}
        </Grid>
        {buttons.length > 2 && (
          <Grid
            container
            width={1}
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              key={"previous"}
              width={150}
              sx={{ paddingLeft: "0px !important" }}
            >
              <Button
                onClick={() => setPage(queryConfig.page - 1)}
                variant={"outlined"}
                disabled={data?.page === 1 || isFetching}
                sx={{ textTransform: "none", width: 1 }}
                size="large"
              >
                Προηγούμενο
              </Button>
            </Grid>
            <Grid item key={"next"} width={150}>
              <Button
                onClick={() => setPage(queryConfig.page + 1)}
                variant={"outlined"}
                disabled={data?.page === data?.num_pages || isFetching}
                sx={{ textTransform: "none", width: 1 }}
                size="large"
              >
                Επόμενο
              </Button>
            </Grid>
          </Grid>
        )}
      </Stack>
    );
  };

  return (
    <Stack spacing={1} paddingBottom={3}>
      {renderOffers()}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
        {renderCategoriesHeader()}
        </Grid>
        <Grid item>
          <RequestSamples supplierId={supplier?.id} />
        </Grid>
      </Grid>
      {renderProductsList()}
      {renderPaginationOptions()}
    </Stack>
  );
}

export default Products;
