import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useFavourites } from "../../../hooks/useFavourites";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { blue } from "@mui/material/colors";
import { makeStyles, useTheme } from "@mui/styles";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { truncateString } from "../../../helpers/stringToLength";

const useStyles = makeStyles((theme) => ({
  supplier: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export const SmallSupplierInfo = ({ supplier, taxNumberOffer }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { isMobile } = useIsMobile();
  const height = isMobile ? 150 : 250;
  const { id, name, categories, logo } = supplier;
  const {
    liked,
    loading: likedLoading,
    toggleLike,
  } = useFavourites({
    kind: "suppliers",
    id: id,
  });
  const getAvatar = () => {
    if (logo) {
      return (
        <Avatar
          variant="rounded"
          sx={{ height: "auto", width: "auto" }}
          imgProps={{ sx: { height, aspectRatio: "4/3" } }}
          src={logo}
          alt="profile"
        />
      );
    }
    return (
      <Avatar
        variant="rounded"
        sx={{
          bgcolor: blue[300],
          height,
          width: "auto",
          aspectRatio: "4/3",
        }}
      >
        {name.slice(0, 1)}
      </Avatar>
    );
  };

  const renderLikeButton = () => {
    if (likedLoading) {
      return <LoadingButton loading />;
    }
    return (
      <IconButton onClick={toggleLike} sx={{ padding: 2 }}>
        {liked && <FavoriteIcon color="primary" />}
        {!liked && <FavoriteBorderIcon />}
      </IconButton>
    );
  };

  const renderSubHeader = () => {
    const categoriesText = categories?.map((cat) => cat.name).join(", ");
    const taxNumberOfferText = !!taxNumberOffer?.offer_percentage
      ? `Aποκλειστική έκπτωση ${(
          taxNumberOffer?.offer_percentage * 100
        ).toFixed(2)}% ${isMobile ? "" : "στο σύνολο κάθε παραγγελίας"}`
      : "";

    return (
      <div>
        <Typography variant="body2">
          {isMobile
            ? !!taxNumberOfferText
              ? ""
              : truncateString(categoriesText, 30)
            : categoriesText}
        </Typography>
        <Typography
          variant={isMobile ? "caption" : "body1"}
          fontWeight="bold"
          color={theme.palette.success.main}
        >
          {taxNumberOfferText}
        </Typography>
      </div>
    );
  };

  return (
    <Paper
      component={Card}
      className={classes.supplier}
      onClick={() => {
        window.location.href = `/suppliers/${id}`;
      }}
    >
      <CardHeader
        avatar={getAvatar()}
        // action={renderLikeButton()}
        title={name}
        subheader={renderSubHeader()}
        sx={{ padding: 0, height, paddingRight: 4 }}
      />
    </Paper>
  );
};
