import * as Yup from 'yup';
import { max_msg, min_msg, store_name_max, store_name_min } from '../../helpers/generalValidation';

const email_regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const required_msg = "Υποχρεωτικό πεδίο";
const digitsOnly = (value) => /^\d+$/.test(value);


const basicRegisterShape = {
    full_name: Yup.string()
        .required(required_msg),
    email: Yup.string()
        .matches(email_regexp, {
            message: 'Μη έγκυρη διεύθυνση email',
            excludeEmptyStrings: true,
        })
        .required(required_msg),
    password: Yup.string()
        .required(required_msg)
        .min(6, "Πρέπει να περιέχει τουλάχιστον 6 χαρακτήρες"),
    confirmation: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Δεν συμφωνεί με τον αρχικό κωδικό')
        .required(required_msg),
    name: Yup.string()
        .required(required_msg)
        .min(store_name_min, min_msg(store_name_min))
        .max(store_name_max, max_msg(store_name_max)),
    address: Yup.string()
        .required(required_msg),
    city: Yup.string()
        .required(required_msg),
    tax_no: Yup.string()
        .required(required_msg)
        .test('Digits only', 'Το πεδίο επιτρέπεται να έχει μόνο αριθμούς', digitsOnly)
        .length(9, "Πρέπει να αποτελείται από 9 φηψία"),
    state: Yup.string()
        .required(required_msg),
    post_code: Yup.string()
        .required(required_msg)
        .test('Digits only', 'Το πεδίο επιτρέπεται να έχει μόνο αριθμούς', digitsOnly)
        .length(5, "Πρέπει να αποτελείται από 5 φηψία"),
    phone_number: Yup.string()
        .required(required_msg)
        .test('Digits only', 'Το πεδίο επιτρέπεται να έχει μόνο αριθμούς', digitsOnly)
        .min(10, "Πρέπει να περιέχει τουλάχιστον 10 ψηφία"),
}
export const LoginValidationSchema = Yup.object().shape({
    email: Yup.string()
        .matches(email_regexp, {
            message: 'Μη έγκυρη διεύθυνση email',
            excludeEmptyStrings: true,
        })
        .required(required_msg),
    password: Yup.string()
        .required(required_msg),
})

export const SupplierRegisterValidationSchema = Yup.object().shape({
    ...basicRegisterShape,
    tax_authority: Yup.string()
        .required(required_msg),
})

export const CustomerRegisterValidationSchema = Yup.object().shape({
    ...basicRegisterShape,
})

