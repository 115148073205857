import * as Yup from 'yup';

const required_msg = "Υποχρεωτικό πεδίο";

const positive_msg = `Δεν μπορεί να έχει αρνητική τιμή`;

export const MinOrderValidationSchema = Yup.object().shape({
    min_order_cost: Yup.number()
        .required(required_msg)
        .min(0, positive_msg),
    min_order_cost_currency: Yup.string()
        .required(required_msg),
})