import React, { useEffect, useState } from 'react'
import { useIsMobile } from './useIsMobile';

export const useDistanceToBottom = () => {
    const [distance, setDistance] = useState("200");
    const { isMobile } = useIsMobile();

    useEffect(() => {
      // const headerEnd = document.getElementById("header-end");
      // console.log({ headerEnd });
      // if (headerEnd) {
        // const dist = window.innerHeight - headerEnd.offsetTop;
        const dist = window.innerHeight;
        console.log({ dist });
        if (dist) {
            if (isMobile) setDistance(dist);
            else setDistance(dist);
            // else setDistance(dist+150);
        }
      // }
      // return 500;
    }, [isMobile])

    return distance;
}