import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export const RouterScrollToTop = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const scrollToTop = (top=0) => {
    window.scrollTo({
      top,
      left: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  // useEffect(() => {
  //   if (pathname === "/help") {
  //     scrollToTop(50);
  //   }
  // }, [searchParams]);

  return null;
};
