import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { getFieldErrors } from '../../../../helpers/getFieldErrors';
import MyTextField from '../../../MyTextField';
import MyTextFieldWithoutGrid from '../../../MyTextFieldWithoutGrid';
import { OfferValidationSchema } from './ProductValidationSchema';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker"
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { Send } from '@mui/icons-material';
import { addOfferCall, deleteOfferCall, updateOfferCall } from '../../../../api/products';
import { setSnackMessage } from '../../../../redux/slices/snackMessageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../../../redux/slices/authSlice';
import { setConfirmationDialog } from '../../../../redux/slices/confirmationDialogSlice';
import { formatCurrency } from '../../../../helpers/price';
import { useRefreshCatalog } from '../../../../hooks/useRefreshCatalog';

function OfferForm({ product, hideMe }) {
    console.log({ product });
    const dispatch = useDispatch();
    const { refreshCatalog } = useRefreshCatalog();
    const [addittionLoading, setAddittionLoading] = useState(false);
    const [deletionLoading, setDeletionLoading] = useState(false);
    const { user } = useSelector(selectAuth);

    const initialValues = {
        product_name: product.name,
        product_price: product.price,
        product_price_currency: formatCurrency(product.price_currency),
        package_quantity: product.package_quantity,
        product_min_quantity: product.min_quantity,
        product_max_quantity: product.max_quantity,
        price: product.offer?.price || ( product?.price  || ""),
        min_quantity: product.offer?.min_quantity || product.min_quantity,
        has_expiration_date: true,
        expiration_date: product.offer?.expiration_date || moment().add(20, 'days'),
    }

    useEffect(() => {
        if (product && product.package_quantity>0) {
            updateSteps(product.package_quantity);
        }
    }, [[product]])

    const updateSteps = (package_quantity_) => {
        const package_quantity = parseInt(package_quantity_);
        console.log("Updating steps to", typeof package_quantity, package_quantity);
        if (package_quantity>0) {
            const min_quantity_input = document.getElementById("min_quantity");
            if (min_quantity_input) {
                min_quantity_input.step = package_quantity;
            }
            console.log(min_quantity_input);
        }
    }

    const preDelete = () => {
        dispatch(setConfirmationDialog({
            title: "Είστε σίγουρος ότι θέλετε να διαγράψετε την προσφορά;",
            message: "Αυτή η πράξη είναι μη αντιστρέψιμη",
            onConfirm: deleteOffer,
        }));
    }

    const deleteOffer = () => {
        setDeletionLoading(true);
        deleteOfferCall(product.offer?.id)
        .then(response => {
            setDeletionLoading(false);
            console.log(response.data);
            setSnackMessage({
                text: "Η προσφορά αφαιρέθηκε με επιτυχία",
                severity: "info",
            });
            hideMe();
            setTimeout(() => {
                refreshCatalog();
                // dispatch(getOneSupplierOffers(user?.supplier_store?.id));
            }, 500);
        })
        .catch(err => {
            setDeletionLoading(false);
            console.log(err);
            setSnackMessage({
                text: "Συγγνώμη, δεν μπορέσαμε να διαγράψουμε την προσφορά σας",
                severity: "warning",
            });
        })
    }

    const parseErrorMessage = (error) => {
        let text = "Συγγνώμη, δεν μπορέσαμε να αποθηκεύσουμε την προσφορά σας";
        if (error.response.status===400) {
            if (error.response.data==="A product cannot have a hyperquantity price and an offer at the same time") {
                text = "Δεν μπορείτε να ανεβάσετε προσφορά σε προϊόν που έχει τιμή υπερχονδρικής";
            }
            if (error.response.data==="Offer's price cannot be higher than the original one") {
                text = "Η τιμή της προσφοράς πρέπει να είναι μικρότερη από την αρχική τιμή πώλησης";
            }
        }
        return text;
    }

    const submit = (values) => {
        const data = { ...values };
        delete data["product_max_quantity"];
        delete data["product_min_quantity"];
        delete data["product_price_currency"];
        delete data["product_name"];
        delete data["product_price"];
        delete data["package_quantity"];
        data["price_currency"] = product.price_currency;
        data["has_expiration_date"] = true;
        data["expiration_date"] = moment(data["expiration_date"]).format("YYYY-MM-DD");
        data["product"] = product.id;

        // decided to remove the min quantity from the offer...
        data["min_quantity"] = 0;
        
        console.log(data);
        setAddittionLoading(true);
        if (!product.offer) {
            addOfferCall(data)
            .then(response => {
                setAddittionLoading(false);
                console.log(response.data);
                dispatch(setSnackMessage({
                    text: "Η προσφορά σας προστέθηκε με επιτυχία",
                    severity: "success",
                }));
                hideMe();
                setTimeout(() => {
                    refreshCatalog();
                    // dispatch(getOneSupplierOffers(user?.supplier_store?.id));
                }, 500);
            })
            .catch(err => {
                console.log(err);
                setAddittionLoading(false);
                dispatch(setSnackMessage({
                    text: parseErrorMessage(err),
                    severity: "warning",
                }));
            })
        }
        else {
            const { offer } = product;
            updateOfferCall(offer.id, data)
            .then(response => {
                console.log(response.data);
                setAddittionLoading(false);
                dispatch(setSnackMessage({
                    text: "Η προσφορά σας ενημερώθηκε με επιτυχία",
                    severity: "success",
                }));
                hideMe();
                setTimeout(() => {
                    refreshCatalog();
                    // dispatch(getOneSupplierOffers(user?.supplier_store?.id));
                }, 500);
            })
            .catch(err => {
                console.log(err);
                setAddittionLoading(false);
                dispatch(setSnackMessage({
                    text: parseErrorMessage(err),
                    severity: "warning",
                }));
            })
        }
    }
    
    const { offer } = product;
    if (offer) {
        console.log("has offer")
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={submit}
                    validationSchema={OfferValidationSchema}
                >
                    { ({ errors, touched, values, setFieldValue, setFieldError }) => (
                        <Form>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} marginBottom={1}>
                                            <Typography variant="h6">
                                                Στοιχεία Προϊόντος
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <MyTextField
                                                    name="product_name"
                                                    label="Όνομα"
                                                    id="product_name"
                                                    type="text"
                                                    disabled
                                                />
                                                <MyTextField
                                                    name="product_price"
                                                    label="Αρχική τιμή ανά μονάδα"
                                                    id="product_price"
                                                    type="number"
                                                    disabled
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            {formatCurrency(product.price_currency)}
                                                        </InputAdornment>
                                                    }        
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <MyTextFieldWithoutGrid
                                                        name="package_quantity"
                                                        label="Μονάδες ανά πακέτο"
                                                        id="package_quantity"
                                                        type="number"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <MyTextFieldWithoutGrid
                                                        name="product_min_quantity"
                                                        label="Ελάχιστος αριθμός μονάδων"
                                                        id="product_min_quantity"
                                                        type="number"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <MyTextFieldWithoutGrid
                                                        name="product_max_quantity"
                                                        label="Μέγιστος αριθμός μονάδων"
                                                        id="product_max_quantity"
                                                        type="number"
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} marginBottom={1}>
                                            <Typography variant="h6">
                                                Στοιχεία προσφοράς
                                            </Typography>
                                        </Grid>
                                        <MyTextField
                                            name="price"
                                            label="Τιμή ανά μονάδα"
                                            id="price"
                                            type="number"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {formatCurrency(product.price_currency)}
                                                </InputAdornment>
                                            }
                                            {...getFieldErrors("price", errors, touched)} 
                                        />
                                        {/* <MyTextField
                                            name="min_quantity"
                                            label={`Ελάχιστος αριθμός μονάδων για ισχύ προσφροράς`}
                                            id="min_quantity"
                                            type="number"
                                            onKeyDown={(event) => {if (event.keyCode!==38 && event.keyCode!==40) event.preventDefault();}}
                                            {...getFieldErrors("min_quantity", errors, touched)}
                                        /> */}

                                        <Grid item>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Ισχύς προσφοράς έως και"
                                                    value={values["expiration_date"]}
                                                    openTo="day"
                                                    inputFormat="dd/MM/yyyy"
                                                    maxDate={moment().add(1, 'M').toDate()}
                                                    minDate={moment().toDate()}
                                                    onChange={(date) => {
                                                        console.log(date);
                                                        console.log(moment(date).format("DD/MM/YYYY"));
                                                        setFieldValue("expiration_date", date);
                                                    }}
                                                    {...getFieldErrors("expiration_date", errors, touched)} 
                                                    renderInput={(params) =>
                                                        <TextField {...params} />
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent={product?.offer ? "space-between" : "flex-end"}>
                                        {product?.offer &&
                                            <Grid item>
                                                <LoadingButton onClick={preDelete} loading={deletionLoading} variant="outlined" color="error">
                                                    Διαγραφη προσφορας
                                                </LoadingButton>
                                            </Grid>                                        
                                        }
                                        <Grid item>
                                            <LoadingButton type="submit" loading={addittionLoading} variant="contained" endIcon={<Send />}>
                                                {product?.offer ? "Ενημερωση προσφορας" : "Προσθηκη"}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>


            </Grid>
        </Grid>
    )
}

export default OfferForm;