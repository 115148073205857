import { Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSupplierDescription } from "../../../api/suppliers";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { checkLogged, selectAuth } from "../../../redux/slices/authSlice";
import { setSnackMessage } from "../../../redux/slices/snackMessageSlice";

export const Description = ({ supplier_store, refresh, disabled = false }) => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const { id, description } = supplier_store;

  const [text, setText] = useState(description);
  const [loading, setLoading] = useState(false);

  const submit = () => {
    setLoading(true);
    updateSupplierDescription({ id, description: text })
      .then((response) => {
        console.log(response);
        dispatch(
          setSnackMessage({
            text: "Η περιγραφή σας ενημερώθηκε επιτυχώς",
            severity: "info",
          })
        );
        setLoading(false);
        refresh();
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackMessage({
            text: "Συγγνώμη, δεν μπορέσαμε να ενημερώσουμε την περιγραφή σας",
            severity: "warning",
          })
        );
        setLoading(false);
      });
  };

  return (
    <Stack spacing={3} width={1}>
      <Typography variant="h5">Η περιγραφή μου</Typography>
      <TextField
        label="Περιγραφή"
        name="description"
        value={text}
        onChange={(e) => setText(e.target.value)}
        multiline
        minRows={isMobile ? 8 : 10}
        disabled={disabled}
      />
      <Grid container justifyContent="flex-end">
        <LoadingButton
          loading={loading}
          variant="contained"
          startIcon={<Send />}
          onClick={submit}
          disabled={disabled}
        >
          Υποβολη
        </LoadingButton>
      </Grid>
    </Stack>
  );
};
