import * as Yup from "yup";

export const TaxNumberOffersFormValidationSchema = Yup.object().shape({
  taxNumberOffers: Yup.array().of(
    Yup.object().shape({
      tax_no: Yup.string().required("Required"),
      offer_percentage: Yup.number()
        .required("Required")
        .min(0, "Δεν επιτρέπεται να είναι αρνητικός αριθμός")
        .max(100, "Δεν μπορεί να είναι μεγαλύτερο του 100%"),
    })
  ),
});
