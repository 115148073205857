import React from "react";
import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TopSuppliers from "../../Suggestions/Suppliers/TopSuppliers";
import TopProducts from "../../Suggestions/Products/TopProducts";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../redux/slices/authSlice";
import { NavLink } from "react-router-dom";
import { headerHeight } from "../../../helpers/heights";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { BreadCrumb } from "../../General/BreadCrumb";
import WidthLimiter from "../../../pages/WidthLimiter";
import { zIndexes } from "../../../zIndexes";

import desktop_xl from "../../../images/marketon-sm.jpg";

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundColor: "#ebf5f7 !important",
    height: "100%",
    borderRadius: "4px",
    backgroundImage: `url(${desktop_xl})`,
  },
  withoutBannerContainer: {
    height: "100%",
    backgroundColor: "rgba(243, 247, 252, 0.946)",
  },
  container: {
    width: "inherit",
    position: "relative",
    backgroundColor: "rgba(243, 247, 252, 0.946)",
  },
  logo: {
    position: "fixed",
    top: "0px",
  },
  menu: {
    position: "fixed",
    right: "0px",
    top: "0px",
  },
  moto: {
    fontFamily: '"Segoe UI"',
    color: "#363636",
  },
  motoContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: "0px 32px",
    },
  },
}));

function Header({ headerProps }) {
  const classes = useStyles();
  const { user, logged, isSupplier } = useSelector(selectAuth);
  const { isMobile } = useIsMobile();
  // const elem = useRef(null);
  console.log({ personal: logged && !isSupplier });
  return (
    <Paper
      className={classes.container}
      sx={{
        height: headerHeight(headerProps?.homePage, isMobile),
        zIndex: zIndexes.header,
        // minHeight: "25vh",
        // maxHeight: "60vh",
      }}
      elevation={0}
    >
      <Grid
        container
        justifyContent={"center"}
        height={1}
        alignItems="flex-end"
      >
        <WidthLimiter fullHeight forBanner>
          <Grid container height={1} alignItems="flex-end" className={headerProps?.homePage ? classes.bannerContainer : classes.withoutBannerContainer}>           
            
            {logged &&
              !isSupplier &&
              !user?.customer_store?.availability_days_times?.length && (
                <Grid id="timeline-not-submitted" item xs={12} sx={{ width: "100%" }}>
                  <Grid container padding={1} width={1} justifyContent="flex-end">
                    <Alert severity="info">
                      Δεν έχετε δηλώσει το ωράριο λειτουργίας της επιχείρησής σας,
                      δηλώστε το <NavLink to="/account?page=times">εδώ</NavLink>
                    </Alert>
                  </Grid>
                </Grid>
              )
            }

            {!isMobile &&
              <Grid item sx={{ position: "absolute", bottom: 0 }}>
                <BreadCrumb />
              </Grid>
            }

          </Grid>
        </WidthLimiter>
      </Grid>
      {/* DO NOT REMOVE, USED FOR VARIOUS CALCULATIONS */}
      <div id="header-end" />
    </Paper>
  );
}

export default Header;
