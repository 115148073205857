import React from 'react'
import OneOrderEdit from '../components/SuperUser/OneOrderEdit'
import { SuperUserLayout } from '../components/SuperUser/SuperUserLayout'
import { useSearchParams } from 'react-router-dom'

export const OneOrderEditPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <SuperUserLayout>
            <OneOrderEdit id={searchParams.get("key")} />
        </SuperUserLayout>
    )
}