import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    isAdmin: false,
}

export const oneProductSlice = createSlice({
    name: "oneProduct",
    initialState,
    reducers: {
        set_product: (state, action) => {
            const { id, isAdmin } = action.payload;
            state.id = id;
            state.isAdmin = isAdmin;
            console.log("opening product:", id);
        },
        clear_product: (state) => {
            state.id = null;
            state.isAdmin = false;
        }
    },
});

export const { set_product, clear_product } = oneProductSlice.actions;

export const selectOneProduct = (state) => state.oneProduct;

export default oneProductSlice.reducer;