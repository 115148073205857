import { Grid, Alert, TableContainer, TableHead, Table, TableRow, TableCell, TableBody, Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { daysText } from '../../helpers/translateDays';

function AvailabilityDaysTimes({ availability_details, availability_summary, isSupplier=false }) {
    const [details, setDetails] = useState({});

    useEffect(() => {
        // console.log("Building details")
        const details_ = {};
        availability_details.forEach(({ day, ...rest}) => {
            // console.log({ day, rest });
            details_[day] = { day, ...rest };
        });
        setDetails(details_);
    }, [availability_details])

    if (!availability_details) {
        let msg = "Ο προμηθευτής δεν έχει δηλώσει τις περιοχές και τα ωράρια στα οποία εξυπηρετεί";
        if (isSupplier) {
            msg = "Η επιχείρηση δεν έχει δηλώσει τα ωράρια λειτουργίας της"
        }
        return (
            <Alert severity="info">
                {msg}
            </Alert>
        )
    }

    const renderAreaOrTime = ({ area, start_time, end_time, all_day }) => {
        if (isSupplier) {
            if (all_day) {
                return (
                    "Όλη την ημέρα"
                )
            }
            if (start_time && end_time) {
                return (`${start_time.slice(0, -3)}-${end_time.slice(0, -3)}`);
            }
            return null;
        }
        return area;
    }
    
    const renderLines = () => {
        // console.log({ details });
        return (
            [0,1,2,3,4,5,6].map((day_index) => {
                if (details[day_index]) {
                    // console.log(`Day ${day_index} has declared availability`)
                    const { area, day, start_time, end_time, all_day } = details[day_index];
                    return (
                        <TableRow
                            key={day_index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {daysText[day]}
                            </TableCell>
                            <TableCell align="right">{renderAreaOrTime({ area, start_time, end_time, all_day })}</TableCell>
                        </TableRow>
                    )
                }
                return (
                    <TableRow
                        key={day_index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {daysText[day_index]}
                        </TableCell>
                        <TableCell align="right">-</TableCell>
                    </TableRow>
                )
            })
        )
    }

    let content = (
        <TableContainer component={Box}>
            <Table size="small" sx={{ minWidth: 100 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                        {/* Ημέρα */}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                        {/* {isSupplier ? "Ωράριο" : "Περιοχές"} */}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { renderLines() }
            </TableBody>
            </Table>
        </TableContainer>
    )

    return (
        <Grid container>
            <Grid item xs={12}>
                { content }
            </Grid>
        </Grid>
    )
}

export default AvailabilityDaysTimes;