import { Alert, Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import { Field, FieldArray, Form, Formik } from 'formik'
import React from 'react'
import { getFieldErrorsForFieldArray } from '../../../helpers/getFieldErrors';
import { Clear } from '@mui/icons-material';
import { updateSupplierHighlights } from '../../../api/suppliers';
import { setSnackMessage } from '../../../redux/slices/snackMessageSlice';
import { useDispatch } from 'react-redux';

export const HighlightsForm = ({ isSuperUser, supplier_store, refresh }) => {
    const dispatch = useDispatch();
    const { id, highlights } = supplier_store;

    const handleSubmit = (values) => {
        const body = values.highlights.map((value) => value.text);
        console.log(body);
        updateSupplierHighlights({ id, highlights: body })
            .then((resp) => {
                console.log(resp.data);
                dispatch(setSnackMessage({
                    text: "Τα χαρακτηριστικά αποθηκεύτηκαν επιτυχώς",
                    severity: "success",
                }));
                refresh();
            })
            .catch((err) => {
                console.log(err);
                setSnackMessage({
                    text: "Συγγνώμη, δεν μπορέσαμε να αποθηκεύσουμε τα χαρακτηριστικά",
                    severity: "error",
                });
            })
    };

    const disabled = !isSuperUser;

    return (
        <Stack spacing={2} justifyContent={"center"} width={1}>
            <Typography variant="h6">
                Χαρακτηριστικά
            </Typography>
            <Formik
                enableReinitialize
                initialValues={{ highlights }}
                onSubmit={handleSubmit}
            >
                {({ values, touched, errors }) => (
                    <Form>
                        <FieldArray name="highlights">
                            {({ push, remove }) => (
                                <Stack spacing={1}>
                                    {!values.highlights.length && (
                                        <Alert severity="info">
                                            Δεν έχετε ανεβάσει ακόμα τα χαρακτηριστικά για τον προμηθευτή
                                        </Alert>
                                    )}
                                    {values.highlights.map((highlight, index) => (
                                        <Grid container spacing={1} key={index} justifyContent={"space-between"}>
                                            <Grid item md={6}>
                                                <Field
                                                    as={TextField}
                                                    label="Κείμενο"
                                                    name={`highlights.${index}.text`}
                                                    fullWidth
                                                    variant="outlined"
                                                    {...getFieldErrorsForFieldArray(
                                                        "highlights",
                                                        index,
                                                        "text",
                                                        errors,
                                                        touched
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item md={2}>
                                                <IconButton
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => remove(index)}
                                                    disabled={disabled}
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )
                                    )}
                                    <Grid container justifyContent={"space-between"} marginTop={2}>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => push({ text: "" })}
                                                disabled={disabled}>
                                                Προσθηκη
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                color="primary"
                                                type="submit"
                                                disabled={disabled}
                                            >
                                                Αποθηκευση
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            )}
                        </FieldArray>
                    </Form>
                )
                }
            </Formik>
        </Stack>
    )
}