import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getSupplierCatalog } from "../../api/products";
import { getOneSupplierCall, getOneSupplierOrderStatsCall, getSupplierOffersCall } from "../../api/suppliers"

const initialState = {
    data: null,
    status : "idle",
    orders_stats: [],
    orders_stats_status: "idle",
}

export const getOneSupplier = createAsyncThunk(
    'supplier/fetch',
    async (id) => {
        try {
            const response = await getOneSupplierCall(id);
            return response.data;
        }
        catch(err) {
            return null;
        }
    }
)

export const getSupplierOrderStats = createAsyncThunk(
    'supplierOrdersStats/fetch',
    async () => {
        try {
            const response = await getOneSupplierOrderStatsCall();
            console.log({ orders_stats: response.data });
            return response.data;
        }
        catch (err) {
            return [];
        }
    }
)

export const oneSupplierSlice = createSlice({
    name: "oneSupplier",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getOneSupplier.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getOneSupplier.fulfilled, (state, action) => {
                state.status = "idle";
                const {sub_categories, ...supplier} = action.payload;
                state.data = supplier;
            })
            .addCase(getSupplierOrderStats.pending, (state) => {
                state.orders_stats_status = "loading";
            })
            .addCase(getSupplierOrderStats.fulfilled, (state, action) => {
                state.orders_stats_status = "idle";
                state.orders_stats = action.payload;
            })
    }
});

export const { clear_catalog }  = oneSupplierSlice.actions;

export const selectOneSupplier = (state) => state.oneSupplier;

export default oneSupplierSlice.reducer;