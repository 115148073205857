import { Typography } from "@mui/material";
import React from "react";
import { formatCurrency, formatPrice } from "../../helpers/price";

export const Price = ({
  price,
  currency,
  strike = false,
  fontSize = "medium",
}) => {
  return (
    <Typography
      fontWeight={strike ? "normal" : "bold"}
      fontSize={fontSize}
      sx={{
        paddingLeft: 3,
        paddingTop: "4px",
        textDecoration: strike ? "line-through" : "auto",
      }}
    >
      {formatPrice(price)}
      {formatCurrency(currency)}
    </Typography>
  );
};
