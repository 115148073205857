import React from "react";
import { Grid, TextField } from "@mui/material";
import { Field } from "formik";

function MyTextField({name, label, id, type, error, helperText, startAdornment, endAdornment, fullLiner, multiline, registerForm, rows, ...props }) {

    const renderField = () => {
        return (
            <Field
                as={TextField}
                name={name}
                label={label}
                id={id}
                variant="outlined"
                type={type}
                error={error}
                helperText={helperText || " "}
                InputProps={{
                    startAdornment,
                    endAdornment,
                    ...props,
                }}
                fullWidth
                rows={rows || 1}
                multiline={multiline===true}
            />
        )
    }

    if (fullLiner) {
        return (
            <Grid item xs={12}>
                { renderField() }
            </Grid>
        )
    }
    else if (registerForm) {
        return (
            <Grid item sm={6} xs={12}>
                { renderField() }
            </Grid>            
        )
    }
    return (
        <Grid item lg={4} md={6} sm={6} xs={12}>
            { renderField() }
        </Grid>
    )
}

export default MyTextField;