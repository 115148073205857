import { Alert, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getQuestionsCall } from "../../api/helpPage";
import Spinner from "../General/Spinner";
import { OneQuestion } from "./OneQuestion";

export const CommonQuestions = ({ for_suppliers = false }) => {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getQuestionsCall(!!for_suppliers)
      .then((response) => {
        console.log(response.data);
        setQuestions(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setQuestions([]);
        setIsLoading(false);
      });
  }, [for_suppliers]);

  if (isLoading) {
    return <Spinner />;
  }

  if (questions?.length) {
    return (
      <Stack spacing={2}>
        {questions.map((question) => (
          <OneQuestion key={question?.id} {...question} />
        ))}
      </Stack>
    );
  }

  return (
    <Alert severity="info">
      Συγγνώμη, προς το παρόν δεν υπάρχουν συχνές ερωτήσεις.
    </Alert>
  );
};
