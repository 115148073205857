import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material'
import React from 'react'

export const OneQuestion = ({ question, answer }) => {
    const theme = useTheme();
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: "lightblue" }}>
                {question}
            </AccordionSummary>
            <AccordionDetails sx={{ whiteSpace: "pre-wrap" }}>
                {answer}
            </AccordionDetails>
        </Accordion>
    )
}