import { Button, Chip, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { queriesKeys } from "../../api/queriesKeys";
import { cartCancel, countAllCarts, getAllCarts } from "../../api/superuser";
import { formatPrice } from "../../helpers/price";
import Spinner from "../General/Spinner";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSnackMessage } from "../../redux/slices/snackMessageSlice";
import { LoadingButton } from "@mui/lab";

const step = 10;

const CartsTable = ({ supplier }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [cancelLoading, setCancelLoading] = useState(false);

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: step,
  });

  const { data: rowCount, isLoading: rowCountLoading } = useQuery(
    [queriesKeys["countCarts"], supplier],
    () => countAllCarts({ supplier }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data, isLoading, isRefetching } = useQuery(
    [queriesKeys["superuserCarts"], rowsState.page, supplier],
    () =>
      getAllCarts({
        start: rowsState.page * step + 1,
        end: (rowsState.page + 1) * step,
        supplier,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getStatus = ({ is_cancelled, delivered, seen }) => {
    let status = "Pending";
    if (is_cancelled) {
      status = "Cancelled";
    }
    if (delivered) {
      status = "Delivered";
    }
    return status;
  };

  const getStatusButton = (status) => {
    let color = "primary";
    let variant = "outlined";
    if (status === "Cancelled") {
      color = "error";
      variant = "contained";
    }
    if (status === "Delivered") {
      color = "success";
      variant = "contained";
    }
    return <Chip variant={variant} color={color} label={status} />;
  };

  const createData = ({
    id,
    customer: {
      id: customer_id,
      name: customer_name,
      owner_email: customer_email,
    },
    supplier: {
      id: supplier_id,
      name: supplier_name,
      owner_email: supplier_email,
    },
    datetime,
    estimated_delivery_date,
    is_cancelled,
    products_number,
    cost_without_vat,
    cost_with_vat,
    delivered,
    seen,
  }) => {
    return {
      id,
      customer: `${customer_name}`,
      supplier: `${supplier_name}`,
      datetime: moment(datetime).format("DD/MM/YYYY"),
      cost_without_vat: formatPrice(cost_without_vat),
      cost_with_vat: formatPrice(cost_with_vat),
      delivery_date: estimated_delivery_date
        ? moment(estimated_delivery_date).format("DD/MM/YYYY")
        : "-",
      status: getStatus({ is_cancelled, delivered, seen }),
      // products_number,
    };
  };

  useEffect(() => {
    console.log(data?.map(({ id }) => id));
  }, [data]);

  if (rowCountLoading) {
    return <Spinner />;
  }

  const cancelOrder = (id) => {
    setCancelLoading(true);
    cartCancel(id)
      .then((response) => {
        console.log(response.data);
        dispatch(
          setSnackMessage({
            text: "Updated successfully",
            severity: "info",
          })
        );
        queryClient.invalidateQueries(queriesKeys["superuserCarts"]);
        setCancelLoading(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackMessage({
            text: "Sorry, we could not update the order",
            severity: "error",
          })
        );
        setCancelLoading(false);
      });
  };

  const renderEditButton = (values) => {
    const id = values?.id;
    return (
      <Button
        variant="contained"
        onClick={() => navigate(`/super/orders/search?key=${id}`)}
      >
        Edit
      </Button>
    );
  };

  const renderCancelButton = (values) => {
    const { id, status } = values?.row || {};
    console.log({ id, status });
    const is_cancelled = status === "Cancelled";
    return (
      <LoadingButton
        sx={{ width: 100 }}
        loading={cancelLoading}
        variant="outlined"
        color={is_cancelled ? "warning" : "error"}
        onClick={() => cancelOrder(id)}
        disabled={status === "Delivered"}
      >
        {is_cancelled ? "Undo" : "Cancel"}
      </LoadingButton>
    );
  };

  const my_sort = (a, b) => {
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  };

  const sortDates = (a, b) => {
    const d1 = moment(a);
    const d2 = moment(b);
    console.log({ d1, d2 });
    const res = my_sort(d1, d2);
    console.log({ res });
    return res;
  };

  const sortFloats = (a, b) => {
    const x1 = parseFloat(a);
    const x2 = parseFloat(b);
    return my_sort(x1, x2);
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 100,
      sortable: true,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 1,
      sortable: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 1,
      sortable: true,
    },
    {
      field: "datetime",
      headerName: "Datetime",
      width: 150,
      sortable: true,
      sortComparator: sortDates,
    },
    {
      field: "cost_without_vat",
      headerName: "Cost without vat",
      width: 100,
      sortable: true,
      sortComparator: sortFloats,
    },
    {
      field: "cost_with_vat",
      headerName: "Cost with vat",
      width: 100,
      sortable: true,
      sortComparator: sortFloats,
    },
    {
      field: "delivery_date",
      headerName: "Delivery date",
      width: 100,
      sortable: true,
      sortComparator: sortDates,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      sortable: true,
      renderCell: (cellValues) => getStatusButton(cellValues?.value),
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 100,
      renderCell: (cellValues) => renderEditButton(cellValues),
    },
    {
      field: "cancel",
      headerName: "Cancel",
      sortable: false,
      width: 200,
      renderCell: (cellValues) => renderCancelButton(cellValues),
    },
    // {
    //     field: 'products_number',
    //     headerName: 'Products number',
    //     width: 100,
    //     sortable: true,
    // },
  ];

  return (
    <Grid container spacing={1} justifyContent={"center"}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Typography variant="h6">Orders</Typography>
          <Button
            variant="contained"
            onClick={() =>
              queryClient.invalidateQueries(queriesKeys["superuserCarts"])
            }
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          autoHeight
          rows={data?.map((datum) => createData(datum)) || []}
          {...rowsState}
          rowCount={rowCount || 0}
          loading={isLoading || isRefetching}
          columns={columns}
          getRowId={(row) => row.id}
          pagination
          paginationMode={"server"}
          onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        />
      </Grid>
    </Grid>
  );
};

export default CartsTable;
