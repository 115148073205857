import React, { useEffect, useState } from "react";
import {
  Avatar,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Button,
  Badge,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { set_product } from "../../../../redux/slices/oneProductSlice";
import { add_new_product } from "../../../../redux/slices/cartSlice";
// import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { setSnackMessage } from "../../../../redux/slices/snackMessageSlice";
import { AddOutlined } from "@mui/icons-material";
import { formatCurrency, formatPrice } from "../../../../helpers/price";
import QuantityButtons from "../../../General/QuantityButtons";
import { updateProductAvailabilityCall } from "../../../../api/products";
import { selectAuth } from "../../../../redux/slices/authSlice";
import { LazyImage } from "../../../General/LazyImage";
import { useQueryClient } from "react-query";
import { queriesKeys } from "../../../../api/queriesKeys";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import placeholderImage from "../../../../images/product_placeholder_image.png";
import { useLocalQuantity } from "../../../../hooks/useLocalQuantity";

function ProductRow({
  product,
  small,
  isAdmin,
  setProduct,
  tuple,
  background,
  initQuantity = 0,
}) {
  // const classes = useStyles();
  const queryClient = useQueryClient();
  const { isMobile } = useIsMobile();
  const {
    id,
    image,
    price,
    price_currency,
    name,
    category,
    is_available,
    offer,
    hyper_price,
  } = product;

  const [discount, setDiscount] = useState(0);
  const dispatch = useDispatch();

  let quantity = tuple?.quantity || 0;

  const {
    localQuantity,
    localCost,
    tooltipOpened,
    add,
    subtract,
    update,
  } = useLocalQuantity({
    initQuantity,
    globalQuantity: quantity,
    tuple,
    product,
  });

  useEffect(() => {
    if (offer && !offer.is_expired) {
      const offer_price = offer.price;
      let rate = (offer_price - price) / price;
      rate = Math.round((Math.round(rate * 100) / 100) * 100);
      setDiscount(`${rate}%`);
    }
  }, [offer]);

  const updateProductAvailability = (newAvailability) => {
    updateProductAvailabilityCall(id, newAvailability)
      .then((response) => {
        dispatch(
          setSnackMessage({
            text: "Ενημερώθηκε επιτυχώς",
            severity: "info",
          })
        );
        queryClient.invalidateQueries(
          queriesKeys["getOneSupplierProductsFiltered"]
        );
        // dispatch(getOneSupplierOffers(user?.supplier_store?.id));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackMessage({
            text: "Συγγνώμη, δεν μπορέσαμε να ενημερώσουμε την διαθεσιμότητα",
            severity: "error",
          })
        );
      });
  };

  const getAvatar = () => {
    if (image) {
      return (
        <Avatar
          variant="square"
          alt="product"
          sx={{
            width: 100,
            bgcolor: "white",
            height: "100%",
          }}
          {...(isMobile && { onClick: showPopUp })}
        >
          <LazyImage src={image} product width="100%" aspectRatio="4/3" />
        </Avatar>
      );
    }
    return (
      <Avatar
        variant="square"
        alt="product"
        sx={{
          width: 100,
          height: "100%",
          aspectRatio: "4/3",
        }}
        {...(isMobile && { onClick: showPopUp })}
        src={placeholderImage}
      />
    );
  };

  const openEditForm = (event) => {
    event.stopPropagation();
    setProduct(product, "update");
  };

  const openDeletionPopUp = (event) => {
    event.stopPropagation();
    setProduct(product, "delete");
  };

  const openOfferForm = (event) => {
    event.stopPropagation();
    setProduct(product, "offer");
  };

  const showPopUp = () => {
    dispatch(
      set_product({
        id,
        isAdmin,
      })
    );
  };

  const renderActions = () => {
    if (isAdmin) {
      return (
        <Grid
          container
          alignItems={"center"}
          justifyContent="flex-end"
          spacing={1}
        >
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={is_available}
                    onChange={(e) =>
                      updateProductAvailability(e.target.checked)
                    }
                  />
                }
                label={<Typography variant="body1">Διαθέσιμο</Typography>}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <Tooltip
              placement="top"
              arrow
              title={
                hyper_price
                  ? "Δεν μπορείτε να προσθέσετε προσφορά σε προϊόν που έχει τιμή υπερχονδρικής"
                  : ""
              }
            >
              <span>
                <Button
                  onClick={openOfferForm}
                  size="small"
                  variant="contained"
                  endIcon={<AddOutlined />}
                  disabled={hyper_price}
                >
                  Προσφορα
                </Button>
              </span>
            </Tooltip>
          </Grid>
          <Grid>
            <Tooltip arrow title="Επεξεργασία">
              <IconButton onClick={openEditForm} size="small">
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid>
            <Tooltip arrow title="Διαγραφή">
              <IconButton onClick={openDeletionPopUp} size="small">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    }
    if (!is_available) {
      return (
        <Grid
          container
          spacing={1}
          alignContent="center"
          justifyContent={"flex-end"}
          paddingRight={3}
        >
          <Typography variant="body1" color="error">
            Μη διαθέσιμο
          </Typography>
        </Grid>
      );
    }
    return (
      <Grid
        container
        alignContent="center"
        alignItems="center"
        spacing={1}
        justifyContent="flex-end"
      >
        <Grid item lg={7} md={6} sm={5} xs={6}>
          <Grid container>
            <Grid item lg={7} md={6} sm={4} xs={5}>
              {localCost > 0 && (
                <Typography variant="body1" color="info.main">
                  {formatPrice(localCost)}
                  {formatCurrency(price_currency)}
                </Typography>
              )}
            </Grid>
            <Grid item lg={5} md={6} sm={8} xs={7}>
              <QuantityButtons
                onAdd={add}
                onSubtract={subtract}
                quantity={localQuantity}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={5} md={6} sm={3} xs={6}>
          <Grid container justifyContent="flex-end" paddingRight={2}>
            <Tooltip
              title="Ενημέρωση καλαθιού"
              arrow
              placement="top"
              open={tooltipOpened}
            >
              <Button
                onClick={update}
                size="small"
                variant="contained"
                aria-label="add to shopping cart"
                endIcon={<AddShoppingCartIcon />}
              >
                Προσθηκη
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderOnePrice = (price_, strike = false, showCurrency = true) => {
    return (
      <Grid item>
        <Typography
          variant="body1"
          color={strike ? "default" : "info.main"}
          paddingLeft={1}
          sx={{
            textDecoration: strike ? "line-through" : "auto",
            paddingLeft: 0,
          }}
        >
          {formatPrice(price_)}
          {showCurrency && formatCurrency(price_currency)}
        </Typography>
      </Grid>
    );
  };

  const renderPrice = () => {
    if (offer && !offer.is_expired) {
      const { price: offer_price } = offer;
      return (
        <Grid container spacing={1}>
          {renderOnePrice(price, true)}
          {renderOnePrice(offer_price)}
        </Grid>
      );
    } else if (hyper_price) {
      return (
        <Grid container spacing={0.3}>
          {renderOnePrice(hyper_price, false, false)}
          <Grid item>
            <Typography variant="body2" color={"info.main"}>
              -
            </Typography>
          </Grid>
          {renderOnePrice(price)}
        </Grid>
      );
    } else {
      return <Grid container>{renderOnePrice(price)}</Grid>;
    }
  };

  const renderSubHeader = () => {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography variant="body2">{category.name}</Typography>
        </Grid>
        <Grid item>{renderPrice()}</Grid>
        <Grid item>
          <Typography variant="caption">+ΦΠΑ</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderPaper = () => {
    return (
      <Paper
        elevation={0}
        sx={{ width: 1, ...(background && { backgroundColor: background }) }}
      >
        <Grid container alignItems="center">
          <Grid item lg={6} md={5} sm={12} xs={12}>
            <CardHeader
              avatar={getAvatar()}
              title={
                isMobile ? (
                  <Button
                    onClick={showPopUp}
                    color="inherit"
                    sx={{
                      padding: 0,
                      paddingLeft: 0,
                      textTransform: "none",
                      textAlign: "left",
                    }}
                  >
                    {name}
                  </Button>
                ) : (
                  name
                )
              }
              subheader={renderSubHeader()}
              {...(!isMobile && { onClick: showPopUp })}
              sx={{
                paddingBottom: 0,
                paddingTop: 0,
                "&:hover": { ...(!isMobile && { cursor: "pointer" }) },
                "& .MuiCardHeader-avatar": {
                  height: "100%",
                  paddingTop: 1,
                  paddingBottom: 1,
                  "&:hover": { ...(isMobile && { cursor: "pointer" }) },
                },
                "& .MuiCardHeader-content": { paddingTop: 2, marginBottom: 2 },
              }}
            />
          </Grid>
          <Grid item lg={6} md={7} sm={12} xs={12} {...(isMobile && { paddingTop: 2 })}>
            {renderActions()}
          </Grid>
        </Grid>
      </Paper>
    );
  };
  if (offer && !offer.is_expired) {
    return (
      <Badge
        sx={{ width: 1 }}
        badgeContent={discount}
        color="success"
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
      >
        {renderPaper()}
      </Badge>
    );
  }
  return renderPaper();
}

export default React.memo(ProductRow);
