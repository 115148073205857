import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'

const keys = {
    "tax_no": "ΑΦΜ",
    "name": "Όνομα",
    "address": "Διεύθυνση",
    "city": "Πόλη",
    "state": "Νομός",
    "post_code": "Ταχυδρομικός κώδικας",
    "tax_authority": "ΔΟΥ",
    "phone_number": "Αριθμός τηλεφώνου",
}

function StoreInfoTable({ store }) {
    if (!store) return null;

    const renderLine = ({key, value}) => {
        return (
            <TableRow
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {keys[key]}
                </TableCell>
                <TableCell align="right">{value}</TableCell>
            </TableRow>
        )
    }
    const renderLines = () => {
        return (
            Object.entries(store)
                .filter(([key, value]) => {
                    return (
                        key!=="id" &&
                        key!=="owner" &&
                        key!=="categories" &&
                        key!=="sub_categories" &&
                        key!=="image" &&
                        key!=="logo" &&
                        key!=="fans" &&
                        key!=="min_order_cost" &&
                        key!=="min_order_cost_currency" &&
                        key!=="verified" &&
                        (value || value==0) &&
                        typeof value !== "object"
                    )
                })
                .map(([key, value]) => {
                    return (
                        renderLine({key, value})
                    )
                })
        )
    }
    return (
        <TableContainer sx={{ opacity: 1 }} component={Paper} elevation={0}>
            <Table size="small" aria-label="simple table">
                <TableHead>
                    <TableCell sx={{ fontWeight: "bold" }}>
                        {/* Στοιχείο */}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align={"right"}>
                        {/* Τιμή */}
                    </TableCell>
                </TableHead>
                <TableBody>
                    { renderLines() }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default StoreInfoTable;