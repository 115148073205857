import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFavProductsCall } from "../api/products";
import { updateFavSuppliersCall } from "../api/suppliers";
import { selectAuth } from "../redux/slices/authSlice";
import {
  getFavourites,
  selectFavourites,
} from "../redux/slices/favouritesSlice";
import { setSnackMessage } from "../redux/slices/snackMessageSlice";

export const useFavourites = ({
  kind = "suppliers",
  id,
  refreshOnFavsUpdate = true,
}) => {
  const dispatch = useDispatch();
  const { logged, isSupplier } = useSelector(selectAuth);
  const { fav_suppliers, fav_products, status } =
    useSelector(selectFavourites);

  const liked = useMemo(() => {
    if (kind === "suppliers") {
      return fav_suppliers.includes(id);
    }
    return fav_products.includes(id);
  }, [kind, id, fav_suppliers, fav_products]);

  const refresh = () => dispatch(getFavourites());

  const toggleLike = (event) => {
    event.stopPropagation();
    if (!logged || isSupplier) {
      dispatch(
        setSnackMessage({
          text: "Πρέπει να έχετε λογαριασμό επιχείρησης για να αποθηκεύετε τα αγαπημένα σας",
          severity: "error",
        })
      );
    } else {
      let type = "add";
      let severity = "success";
      let text = "Προστέθηκε στα αγαπημένα";
      if (liked) {
        type = "remove";
        severity = "info";
        text = "Αφαιρέθηκε από τα αγαπημένα";
      }
      console.log(type, id);
      let method = updateFavSuppliersCall;
      if (kind !== "suppliers") {
        method = updateFavProductsCall;
      }
      method(type, id)
        .then((response) => {
          console.log(response.data);
          dispatch(
            setSnackMessage({
              text,
              severity,
            })
          );
          refresh();
        })
        .catch((err) => {
          let msg = `Συγγνώμη, δεν μπορέσαμε να ενημερώσουμε τα αγαπημένα σας`;
          if (
            err?.response?.data ===
            "You must be a customer to add a supplier to your favourites"
          ) {
            msg =
              "Πρέπει να έχετε λογαριασμό επιχείρησης για να αποθηκεύετε τα αγαπημένα σας";
          }
          dispatch(
            setSnackMessage({
              text: msg,
              severity: "error",
            })
          );
        });
    }
  };

  return {
    liked,
    // loading: status==="loading",
    loading: false,
    toggleLike,
  };
};
